import styled from 'styled-components';

const PaymentMethodWrapper = styled.div`

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2{
font-size: 24px;
font-weight: 700;
line-height: 32px;
text-align: left;
color:#190F0F;
margin:0;

  }
}
section{
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 24px;
  margin-top: 43px;
  padding: 24px;
  background-color: #fff;
  border:1px solid #ECEFF1;
  border-radius: 8px 8px 0px 0px;
  align-items: baseline;
  justify-content: space-between;
  height: calc(100vh - 160px);
  overflow: auto;
  .boxes-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}
`;

export default PaymentMethodWrapper;
