import React, {
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { FaStripe } from 'react-icons/fa';
import {
  isEmpty,
  sortBy
} from 'lodash';

import Input from '../../../components/input';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Select from '../../../components/select';

import {
  CreateCustomer,
  SetStripeState,
  UpdateCard
} from '../../../redux/slices/stripe';
import { GetCurrentUser } from '../../../redux/slices/user';

import { StripeState } from '../../../redux/types/stripe';

import { AppDispatch } from '../../../redux/store';

import PaymentMethodWrapper from './style';

import { COUNTRIES } from '../../../constants';

interface ConnectModalProps {
  open?: boolean;
  setPaymentModal?: (value: boolean) => void;
  setEditCardDetails?: (value: any) => void;
  editCardDetails?: any;
  setEditCard?: (value: string) => void;
  editCard?: string;
  updateCardId?: string;
}

interface CardValidationError {
  cvcError: string | undefined;
  dateError: string | undefined;
  numberError: string | undefined;
  cvcTouched: boolean;
  dateTouched: boolean;
  numberTouched: boolean;
}

interface AddressDataErrorProps {
  countryError: string | undefined;
  postalCodeError: string | undefined;
}

interface AddressDataProps {
  selectedCountry: string;
  postalCode: string;
}

interface CountriesDropdown {
  value: string | undefined;
  label: string | undefined;
}

const CardNumberOptions = {
  showIcon: true,
  style: {
    base: {
      color: '#494F51',
      fontWeight: 500,
      fontSize: '13px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#C0C0C0'
      }
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee'
    }
  },
  placeholder: '1234 1234 1234 1234' // Set the placeholder text here
};

const ExNumberOptions = {
  showIcon: true,
  placeholder: 'MM/YYY',
  fontSize: '13px',
  style: {
    base: {
      color: '#9094A0',
      fontWeight: 300,
      ':-webkit-autofill': {
      },
      '::placeholder': {
        color: '#9094A0'
      }
    }
  }
};

const ExpiryOptions = {
  showIcon: true,
  placeholder: '123',
  fontSize: '13px',
  style: {
    base: {
      color: '#9094A0',
      fontWeight: 300,
      ':-webkit-autofill': {
      },
      '::placeholder': {
        color: '#9094A0'
      }
    }
  }
};

const ConnectModal: React.FC<ConnectModalProps> = ({
  open,
  setPaymentModal,
  setEditCardDetails,
  editCard,
  editCardDetails,
  setEditCard,
  updateCardId
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();

  const {
    customerCreated,
    cardUpdated,
    loading
  } = useSelector((state: { stripe: StripeState }) => state.stripe) || {};

  const [resetStripeElements, setResetStripeElements] = useState<number>(0);
  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [cardValidationError, setCardValidationError] = useState<CardValidationError>({
    cvcError: '',
    dateError: '',
    numberError: '',
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false
  });
  const [addressError, setAddressError] = useState<AddressDataErrorProps>({
    countryError: '',
    postalCodeError: ''
  });
  const [addressData, setAddressData] = useState<AddressDataProps>({
    selectedCountry: '',
    postalCode: ''
  });
  const [allCountries, setAllCountries] = useState<CountriesDropdown[]>([{
    value: '',
    label: ''
  }]);

  const handleName = (value: string | undefined) => {
    const name = value;

    if (name) {
      setNameError('');
    }
    setCardHolderName(name || '');
  };

  const handleCardValidationError = (e: any) => {
    const {
      elementType,
      error,
      complete,
      empty
    } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;

    let cvcError = '';
    let dateError = '';
    let numberError = '';

    if (elementType === 'cardNumber') {
      if (!isEmpty(error)) {
        numberError = error.message;
        numberTouched = true;
      } else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError,
        numberTouched
      });
    } else if (elementType === 'cardCvc') {
      if (!isEmpty(error)) {
        cvcError = error.message;
        cvcTouched = true;
      } else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError,
        cvcTouched
      });
    } else if (elementType === 'cardExpiry') {
      if (!isEmpty(error)) {
        dateError = error.message;
        dateTouched = true;
      } else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError,
        dateTouched
      });
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const {
      cvcTouched,
      dateTouched,
      numberTouched
    } = cardValidationError;

    if (cardHolderName === '' || cvcTouched !== true || dateTouched !== true || numberTouched !== true) {
      let cardHolderNameError = '';
      let cvcError = '';
      let numberError = '';
      let dateError = '';

      if (cardHolderName === '') cardHolderNameError = 'Name is Required!';
      if (cardValidationError.cvcError === '' && cvcTouched === false) cvcError = 'Cvc is Required!';
      else cvcError = cardValidationError.cvcError || '';
      if (cardValidationError.dateError === '' && dateTouched === false) dateError = 'Date is Required!';
      else dateError = cardValidationError.dateError || '';
      if (cardValidationError.numberError === '' && numberTouched === false) numberError = 'Card Number is Required!';
      else numberError = cardValidationError.numberError || '';

      setNameError(cardHolderNameError);

      setCardValidationError({
        ...cardValidationError,
        cvcError,
        numberError,
        dateError
      });

      return;
    }

    const card = elements.getElement(CardNumberElement);
    if (card) {
      const { token } = await stripe.createToken(card);

      if (token) {
        if (editCard === 'add') {
          dispatch(CreateCustomer({
            token,
            cardHolderName: cardHolderName?.trim(),
            country: addressData.selectedCountry,
            postalCode: addressData.postalCode
          }));
        } else if (editCard === 'edit') {
          dispatch(UpdateCard({
            token,
            cardId: updateCardId,
            cardHolderName: cardHolderName?.trim(),
            country: addressData.selectedCountry,
            postalCode: addressData.postalCode
          }));
        }
      }
    }
  };

  const handleAddressError = (key: string, value: string) => {
    setAddressData({
      ...addressData,
      [key]: value
    });

    let errorObj = addressError;

    if (key === 'selectedCountry') {
      if (!value) {
        errorObj = {
          ...errorObj,
          countryError: 'Country cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          countryError: ''
        };
      }
    } else if (key === 'postalCode') {
      if (!value) {
        errorObj = {
          ...errorObj,
          postalCodeError: 'Postal Code cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          postalCodeError: ''
        };
      }
    }

    setAddressError(errorObj);
  };

  useEffect(() => {
    let countriesData = COUNTRIES?.map((country, index) => ({
      value: country.name,
      label: country.name
    }));

    countriesData = sortBy(countriesData, 'label');
    setAllCountries(countriesData);

    setAddressData({
      selectedCountry: '',
      postalCode: ''
    });
  }, []);

  useEffect(() => {
    setCardHolderName(editCardDetails?.name || '');
    setAddressData({
      selectedCountry: editCardDetails?.country || '',
      postalCode: editCardDetails?.postalCode || ''
    });
  }, [editCardDetails]);

  useEffect(() => {
    if (customerCreated) {
      dispatch(GetCurrentUser());
      setCardHolderName('');
      setPaymentModal?.(false);
      setAddressData({
        selectedCountry: '',
        postalCode: ''
      });
      setResetStripeElements(resetStripeElements + 1);
      dispatch(SetStripeState({ field: 'customerCreated', value: false }));
    } else if (cardUpdated) {
      dispatch(GetCurrentUser());
      setEditCard?.('add');
      setPaymentModal?.(false);
      setEditCardDetails?.({
        name: '',
        country: '',
        postalCode: ''
      });
      dispatch(SetStripeState({ field: 'cardUpdated', value: false }));
    }
  }, [customerCreated, cardUpdated]);

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => {
        setPaymentModal?.(false);
        setEditCard?.('add');
        setEditCardDetails?.({
          name: '',
          country: '',
          postalCode: ''
        });
      }}
      saveText={editCard === 'add' ? t('add_payment') : t('update_payment')}
      onSubmitClick={handleSubmit}
      open={open}
      width="490px"
    >
      <PaymentMethodWrapper>
        <div className="payment-cart-wrapper">
          {loading && <Loader />}
          <div>
            <div className="payment-form-ui">
              <div className="header-wrapper-ui">
                <h5>Payment Details</h5>
                <FaStripe />
              </div>
              <div className="strip-st-ui-wrapper strip-st-ui-wrapper-modal ">
                <div className="w-100">
                  <Input
                    className="custom-style"
                    label="Card holder name"
                    name="name"
                    value={cardHolderName}
                    placeholder="Enter"
                    onChange={(e: any) => handleName(e.target.value)}
                  />
                  <span className="error">
                    {nameError}
                  </span>
                </div>
                <div className="card-number-wrapper">
                  <label>Credit card here</label>
                  <div className="card-number-input">
                    <CardNumberElement
                      key={resetStripeElements}
                      options={CardNumberOptions}
                      className="card-number-stripe"
                      onChange={(e) => handleCardValidationError(e)}
                    />
                  </div>
                  <span className="error">
                    {cardValidationError.numberError}
                  </span>
                </div>
                <div className=" elements-wrapper d-flex">
                  <div className="card-number-expiry">
                    <label>Expiry</label>
                    <div className="card-expiry-wrapper">
                      <CardExpiryElement
                        key={resetStripeElements}
                        className="card-number-stripe"
                        options={ExNumberOptions}
                        onChange={(e) => handleCardValidationError(e)}
                      />
                    </div>
                    <span className="error">
                      {cardValidationError.dateError}
                    </span>
                  </div>
                  <div className="card-number-expiry">
                    <label>CVC</label>
                    <div className="card-number-stripe">
                      <CardCvcElement
                        key={resetStripeElements}
                        className="card-cvc-stripe"
                        options={ExpiryOptions}
                        onChange={(e) => handleCardValidationError(e)}
                      />
                    </div>
                    <span className="error">
                      {cardValidationError.cvcError}
                    </span>
                  </div>
                </div>
                <div className="elements-wrapper d-flex">
                  <div className="w-100">
                    <Select
                      defaultValue="United States"
                      options={allCountries}
                      placeholder="Please select an option"
                      label="Country"
                      value={addressData.selectedCountry}
                      onChange={(e) => handleAddressError('selectedCountry', e)}
                      showSearch
                    />
                    <span className="error">
                      {addressError.countryError}
                    </span>
                  </div>
                  <div className="w-100">
                    <Input
                      className="custom-style"
                      label="Postal code"
                      name="name"
                      value={addressData.postalCode}
                      placeholder="90210"
                      onChange={(e: any) => handleAddressError('postalCode', e.target.value)}
                    />
                    <span className="error">
                      {addressError.postalCodeError}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PaymentMethodWrapper>
    </Modal>
  );
};

export default ConnectModal;
