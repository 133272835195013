import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import BlockUnBlockModal from './modals/info';

import Table from '../../components/table';
import Pagination from '../../components/pagination';
import { EmployeeFilterComponent } from '../../components/filter-column-header';
import { EmployeeSearchComponent } from '../../components/search-column-header';
import ActionButtons from './action-buttons';
import Loader from '../../components/loader';

import { GetCustomers, SetUserState, UpdateCustomerStatus } from '../../redux/slices/user';

import { AppDispatch } from '../../redux/store';

import {
  Customer,
  CustomerFilters,
  SortBy,
  UserState
} from '../../redux/types/user';

import {
  ApplyValidation,
  DecryptCredentials,
  EncryptCredentials
} from '../../utils/helpers';

import {
  EMPLOYEE_STATUS,
  PAGE_SIZES,
  REGEX_PATTERNS,
  USER_STATUS
} from '../../constants';

const Customers: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const {
    customers,
    getCustomersSuccess,
    loading,
    totalCustomers,
    customerActionLoading,
    isCustomerEdited
  } = useSelector((state: { user: UserState }) => state.user);

  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState<number>(PAGE_SIZES[0]);
  const [unBlock, setUnBlock] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<string | null>('');
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [data, setData] = useState<Customer[]>([]);
  const [filters, setFilters] = useState<CustomerFilters>({
    searchByKeyWords: {
      name: '',
      email: '',
      status: '',
      signedUpAt: '',
      statusUpdatedAt: ''
    }
  });

  interface StatusCellRendererParams {
    value: string;
  }

  const getStatusCellRenderer = (params: StatusCellRendererParams) => {
    const { value } = params;

    let statusClass = '';

    switch (value) {
      case USER_STATUS.ACTIVE:
        statusClass = 'status-completed';
        break;
      case USER_STATUS.ARCHIVED:
        statusClass = 'status-archived';
        break;
      case USER_STATUS.INVITED:
        statusClass = 'status-planned';
        break;
      case USER_STATUS.BLOCKED:
        statusClass = 'status-blocked';
        break;
      default:
        statusClass = '';
    }

    return <span className={`status-cell ${statusClass}`}>{value}</span>;
  };

  const actionCellRenderer = (params: { data: Customer }) => {
    const { _id: employeeActionId } = params.data;
    return (
      <ActionButtons
        employeeId={employeeActionId}
        setUnBlock={setUnBlock}
        setBlock={setBlock}
        setEmployeeId={setEmployeeId}
      />
    );
  };

  const getAllEmployees = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;

    const isEmailAlreadyEncrypted = applyFilters?.searchByKeyWords?.email
    && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, applyFilters?.searchByKeyWords?.email);

    if (applyFilters?.searchByKeyWords?.email && !isEmailAlreadyEncrypted) {
      applyFilters.searchByKeyWords.email = EncryptCredentials(applyFilters?.searchByKeyWords?.email);
    }

    dispatch(GetCustomers({
      skip,
      limit,
      sortBy: sortValue || {},
      filters: applyFilters
    }));
  };

  const columnDefs = [{
    headerName: t('employee_name'),
    field: 'name',
    headerComponent: EmployeeSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'name',
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.name === 'asc'
        ? 'up'
        : sortValue?.name === 'desc'
          ? 'down'
          : null
    },
    minWidth: 220,
    flex: 1,
    pinned: 'left'
  }, {
    headerName: t('email'),
    field: 'email',
    headerComponent: EmployeeSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'email',
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.email === 'asc'
        ? 'up'
        : sortValue?.email === 'desc'
          ? 'down'
          : null
    },
    minWidth: 150,
    flex: 1
  }, {
    headerName: t('status'),
    field: 'status',
    cellRenderer: getStatusCellRenderer,
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'status',
      options: EMPLOYEE_STATUS,
      filters,
      setFilters,
      getAllEmployees,
      showArrow: sortValue?.status === 'asc'
        ? 'up'
        : sortValue?.status === 'desc'
          ? 'down'
          : null
    },
    minWidth: 110,
    flex: 1
  }, {
    headerName: t('sign_up_date'),
    field: 'signedUpAt',
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'signedUpAt',
      filters,
      setFilters,
      getAllEmployees,
      showFilter: false,
      showArrow: sortValue?.signedUpAt === 'asc'
        ? 'up'
        : sortValue?.signedUpAt === 'desc'
          ? 'down'
          : null
    },
    minWidth: 120,
    flex: 1
  }, {
    headerName: t('status_change_date'),
    field: 'statusUpdatedAt',
    headerComponent: EmployeeFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'statusUpdatedAt',
      filters,
      setFilters,
      getAllEmployees,
      showFilter: false,
      showArrow: sortValue?.statusUpdatedAt === 'asc'
        ? 'up'
        : sortValue?.statusUpdatedAt === 'desc'
          ? 'down'
          : null
    },
    minWidth: 100,
    flex: 1
  }, {
    headerName: t('actions'),
    field: 'actions',
    pinned: 'right',
    minWidth: 140,
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  const changeCustomerStatus = (status: string) => {
    if (!isEmpty(employeeId)) {
      dispatch(UpdateCustomerStatus({
        userId: employeeId,
        status
      }));
    }

    setEmployeeId(null);
  };

  useEffect(() => {
    if (isCustomerEdited) {
      setBlock(false);
      setUnBlock(false);
      dispatch(SetUserState({
        field: 'isCustomerEdited',
        value: false
      }));
    }
  }, [isCustomerEdited]);

  useEffect(() => {
    getAllEmployees({ applyFilters: filters });
  }, [pageLimit, pageNumber, sortValue]);

  useEffect(() => {
    if (customers?.length) {
      const rowData = customers?.map((employee) => ({
        ...employee,
        email: DecryptCredentials(employee.email),
        signedUpAt: new Date(employee.signedUpAt).toDateString(),
        statusUpdatedAt: new Date(employee.statusUpdatedAt).toDateString()
      }));

      setData(rowData);
    } else {
      setData([]);
    }
  }, [customers]);

  useEffect(() => {
    if (getCustomersSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetUserState({
        field: 'getCustomersSuccess',
        value: false
      }));
    }
  }, [getCustomersSuccess]);

  return (
    <>
      {loading && <Loader />}
      <>
        <div className="content-header">
          <h2 className="heading">{t('customers')}</h2>
        </div>
        <Table
          rowData={data}
          columnDefs={columnDefs}
          sortable
          resizable
          editable
          filter
          height="164px"
          rowHeight={36}
          headerHeight={40}
          className="custom-table"
          isDataRetrievalSuccessful={isDataRetrievalSuccessful}
        />
        <Pagination
          totalPages={totalCustomers}
          currentPage={pageNumber}
          onChange={onPageChange}
          pageSize={pageLimit}
          pageSizes={PAGE_SIZES}
        />
        <BlockUnBlockModal
          open={unBlock}
          onCancel={() => setUnBlock(false)}
          heading={t('unblock_customer_confirmation')}
          onConfirm={() => changeCustomerStatus(USER_STATUS.ACTIVE)}
          loading={customerActionLoading}
        />
        <BlockUnBlockModal
          open={block}
          onCancel={() => setBlock(false)}
          heading={t('block_customer_confirmation')}
          onConfirm={() => changeCustomerStatus(USER_STATUS.BLOCKED)}
          loading={customerActionLoading}
        />
      </>
    </>
  );
};

export default Customers;
