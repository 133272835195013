/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  camelCase,
  extend,
  isEmpty,
  startCase
} from 'lodash';
import moment from 'moment';
import {
  Row,
  Col,
  Space
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import Blocked from '../../../assets/icons/blocked.svg';
import Document from '../../../assets/icons/document-text.svg';
import TickCircle from '../../../assets/icons/tick-circle.svg';
import Download from '../../../assets/icons/download-icon.svg';
import Trash from '../../../assets/icons/trash-1.svg';

import Button from '../../../components/button';
import ErrorsModal from './check';
import Upload from '../../../components/upload';
import Input from '../../../components/input';
import Loader from '../../../components/loader';
import ModalUpload from '../../../components/modal';
import Progress from '../../../components/progress';
import QuickTutorial from '../../../components/modal/quick-tutorial';
import Select from '../../../components/select';
import CampaignsWrapper from '../style';

import {
  GetS3PreSignedUrl,
  SetOtherState,
  SetOtherNotifyState
} from '../../../redux/slices/other';

import {
  AddManualCampaign,
  ReadOneTimeCampaignFile,
  SetCampaignState,
  UpdateOneTimeCampaign
} from '../../../redux/slices/campaign';

import {
  GetAllTemplatesWithVariables,
  GetTemplateById
} from '../../../redux/slices/template';
import { CampaignState } from '../../../redux/types/campaign';
import { OtherState } from '../../../redux/types/other';
import { TemplateState } from '../../../redux/types/template';
import { UserState } from '../../../redux/types/user';
import type { AppDispatch } from '../../../redux/store';

import {
  CheckFileExtension,
  ConvertExcelIntoCsv,
  DownloadSampleFile,
  ExtractHeadersFromFile,
  ExtractRowsFromFile,
  FormatColumnName,
  GetArrayDifference,
  UploadDocumentOnS3,
  ValidateHeaders
} from '../../../utils/helpers';

import {
  ADD_CAMPAIGN_INITIAL_STATE,
  BUCKET_NAME_VALUES,
  FILES_EXTENSION_LIST,
  SAMPLE_FILE_NAMES,
  SHEETS_MANDATORY_COLUMNS
} from '../../../constants';

interface AddCampaign {
  name: string;
  templateId: string;
  fileKey: string;
}

interface HelperText {
  [key: string]: string | undefined;
}

interface ModalProps {
  onCancel: () => void;
  open?: boolean;
  uploadNickname?: boolean;
  title?: string;
  editCampaignId?: string;
  setEditCampaignId?: (value: string) => void;
  openEditOneTimeCampaign?: boolean;
  setOpenEditOneTimeCampaign?: (value: boolean) => void;
}

interface Options {
  label: string,
  value: string,
  variables: string[]
}

const Modal: React.FC<ModalProps> = ({
  onCancel,
  open,
  uploadNickname,
  title,
  editCampaignId,
  setEditCampaignId,
  openEditOneTimeCampaign,
  setOpenEditOneTimeCampaign
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    preSignedUrl,
    preSignedUrlLoading,
    fileUploadKey
  } = useSelector((state: { other: OtherState }) => state.other);

  const {
    getTemplateForEmployeeSuccess,
    getTemplateByIdSuccess,
    isUsed,
    templates,
    templateSheets,
    templatesWithVariables
  } = useSelector((state: { template: TemplateState }) => state.template);

  const {
    campaigns,
    isCampaignAdded,
    isCampaignUpdated,
    addCampaignLoading,
    currentCampaign
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [progress, setProgress] = useState<number>(0);
  const [attachment, setAttachment] = useState<any | undefined>(undefined);
  const [addCampaign, setAddCampaign] = useState<AddCampaign>(
    campaigns?.find((camp) => camp._id === editCampaignId) || ADD_CAMPAIGN_INITIAL_STATE
  );
  const [addCampaignHelperText, setAddCampaignHelperText] = useState<HelperText>({});
  const [videoPlayerModal, setVideoPlayerModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [videoPlayerModalLink, setVideoPlayerModalLink] = useState<string>('');
  const [htmlErrorTable, setHtmlErrorTable] = useState<string>('');
  const [options, setOptions] = useState<Options[]>([{
    label: '',
    value: '',
    variables: []
  }]);
  const [updateCampaignTrigger, setUpdateCampaignTrigger] = useState<boolean>(false);
  const [downloadSampleFileTrigger, setDownloadSampleFileTrigger] = useState<boolean>(false);
  const [sampleFileColumns, setSampleFileColumns] = useState<string[]>([]);
  const [currentTemplateVariables, setCurrentTemplateVariables] = useState<string[]>([]);

  const addManualCampaign = () => {
    dispatch(AddManualCampaign({ addCampaign }));
  };

  const noChangesMade = () => {
    if (currentCampaign?.fileKey === addCampaign?.fileKey
      && currentCampaign?.name === addCampaign?.name) {
      dispatch(SetOtherNotifyState({
        message: t('no_changes_made'),
        type: 'info'
      }));

      return true;
    }

    return false;
  };

  const updateOneTimeCampaign = () => {
    if (noChangesMade()) return;

    const updateParams = {};
    if (!isEmpty(addCampaign?.name)
      && currentCampaign?.name !== addCampaign?.name) {
      extend(updateParams, { name: addCampaign?.name });
    }

    if (!isEmpty(addCampaign?.fileKey)
      && currentCampaign?.fileKey !== addCampaign?.fileKey) {
      extend(updateParams, { fileKey: addCampaign?.fileKey });
    }

    dispatch(UpdateOneTimeCampaign({
      filterParams: {
        campaignId: currentCampaign?._id
      },
      updateParams
    }));
  };

  const editTemplateHandler = () => {
    const url = `/templates?templateId=${currentCampaign?.templateId}`;
    window.open(url, '_blank');
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e?.target;
    setAddCampaign({ ...addCampaign, name: value });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'name') errors[name] = `${startCase(name)} is required!`;
    else if (!isEmpty(value) && name === 'name') {
      errors[name] = '';
    }
    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const handleSelectChange = (value: string, name: string) => {
    setAddCampaign({ ...addCampaign, [name]: value, fileKey: '' });

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'templateId') errors[name] = t('template_is_required');
    else if (!isEmpty(value) && name === 'templateId') {
      errors[name] = '';
    }

    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const handleUpload = () => {
    const campaignErrors: Partial<Record<string, string>> = {};

    setUpdateCampaignTrigger(true);

    if (addCampaign.name === '') {
      campaignErrors.name = t('name_is_required');
    }
    if (addCampaign.templateId === '') {
      campaignErrors.templateId = t('template_is_required');
    }
    if (addCampaign.fileKey === '') {
      campaignErrors.fileKey = t('file_is_required');
    }

    const hasErrors = Object.keys(campaignErrors).length > 0;
    if (!hasErrors) {
      if (openEditOneTimeCampaign) {
        dispatch(GetTemplateById({
          templateId: currentCampaign?.templateId as string
        }));
      } else {
        addManualCampaign();
      }
    } else {
      setAddCampaignHelperText((prevHelperText: any) => ({
        ...prevHelperText,
        ...campaignErrors
      }));
    }
  };

  const handleChangeAttachment = async (info: any, uploadBucket: string) => {
    setProgress(0);
    setAddCampaign({ ...addCampaign, fileKey: '' });
    const { file } = info;
    if (!file?.status) {
      const { name } = file;
      const extension = name.split('.').pop();

      const notValidExtension = CheckFileExtension({
        extension: extension as string,
        extensionList: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? FILES_EXTENSION_LIST : [],
        attachmentType: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? 'files' : ''
      });

      if (notValidExtension) {
        dispatch(SetOtherNotifyState({
          message: notValidExtension,
          type: 'error'
        }));
        return;
      }

      let headers: string[] = [];
      let fileToPass;
      if (['csv', 'tsv'].includes(extension || '')) {
        fileToPass = file;
      } else if (['xls', 'xlsx'].includes(extension || '')) {
        fileToPass = await ConvertExcelIntoCsv(file);
      }

      headers = await ExtractHeadersFromFile(fileToPass);
      headers = headers?.map((header: string) => camelCase(header.replace('\r', '')));
      let pattern;

      if (openEditOneTimeCampaign) {
        pattern = templateSheets?.map((sheet) => sheet?.variables).flat() as string[];
        setCurrentTemplateVariables(
          templateSheets?.map((sheet) => sheet?.variables).flat() as string[]
        );
      } else {
        pattern = options?.find((item) => item?.value === addCampaign?.templateId)?.variables ?? [];
      }
      const concatenatedPattern = [...pattern, ...SHEETS_MANDATORY_COLUMNS];
      let formattedColumns = concatenatedPattern.map((column) => FormatColumnName(column));
      formattedColumns = formattedColumns.map((column) => camelCase(column));
      let extraHeaders = formattedColumns.filter((header: string) => !headers?.includes(camelCase(header)));

      extraHeaders = extraHeaders.map((header) => camelCase(header));
      extraHeaders = extraHeaders.filter((header: string) => ['mrMs', 'firstName', 'lastName'].includes(camelCase(header)));
      const isValidHeaders = ValidateHeaders([...extraHeaders, ...headers || []], formattedColumns);
      if (!isValidHeaders) {
        dispatch(SetOtherNotifyState({
          message: t('error_in_sheet_header_format'),
          type: 'error'
        }));
        return;
      }

      const rows = await ExtractRowsFromFile(fileToPass);

      const invalidColumns: {
        rowIndex: number,
        errorMessage: string
      }[] = [];
      for (let rowIndex = 0; rowIndex < rows.length; rowIndex += 1) {
        const row = rows[rowIndex];

        formattedColumns.forEach((column, columnIndex) => {
          const value = row[columnIndex] ? row[columnIndex].replace(/\r/g, '').trim() : '';

          if (value && value.length < 2) {
            invalidColumns.push({
              rowIndex: rowIndex + 1,
              errorMessage: `${startCase(column)} should have atleast 2 characters!`
            });
          }

          if (value && value.length > 25) {
            invalidColumns.push({
              rowIndex: rowIndex + 1,
              errorMessage: `${startCase(column)} cannot exceed 25 characters!`
            });
          }
        });
      }

      if (invalidColumns.length > 0) {
        const errorTableHtml = `
          <table style="width: 100%; border-collapse: collapse;">
            <thead>
              <tr>
                <th style="border-bottom: 1px solid #ddd; padding: 8px;">Row Number</th>
                <th style="border-bottom: 1px solid #ddd; padding: 8px;">Error Message</th>
              </tr>
            </thead>
            <tbody>
              ${invalidColumns.map((invalid) => `
                <tr>
                  <td style="border: 1px solid #ddd; padding: 8px; text-align: center;">${invalid.rowIndex}</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">${invalid.errorMessage}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        `;

        setHtmlErrorTable(errorTableHtml);
        setOpenErrorModal(true);

        return;
      }

      setAttachment(fileToPass);
      const fileName = name.split('.')[0];
      const formattedFileName = fileName.replace(/\s/g, '-');

      let { type } = fileToPass;
      let fileExtension = extension;

      if (['xls', 'xlsx'].includes(extension || '')) {
        type = 'text/csv';
        fileExtension = 'csv';
      }

      dispatch(GetS3PreSignedUrl({
        fileName: `${formattedFileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}.${extension}`,
        fileType: type || '',
        fileExtension: fileExtension || '',
        uploadBucket,
        id: ''
      }));
    }
  };

  const handleUploadAttachmentOnS3 = async () => {
    if (attachment) {
      try {
        await UploadDocumentOnS3({
          preSignedUrl,
          fileToUpload: attachment,
          onProgress: (prog: number) => {
            setProgress(Math.round(prog));
          }
        });
      } catch (error) {
        dispatch(SetOtherNotifyState({
          message: t('file_uploading_failed_on_S3'),
          type: 'error'
        }));
      }
    }
  };

  const resetValues = () => {
    setSampleFileColumns([]);
    setProgress(0);
    onCancel();
    setAddCampaign(ADD_CAMPAIGN_INITIAL_STATE);
    setAddCampaignHelperText({});
  };

  const handleDelete = () => {
    if (progress === 100
        || openEditOneTimeCampaign) {
      setAddCampaign({ ...addCampaign, fileKey: '' });
    }
  };

  const getAllTemplatesWithVariables = () => {
    dispatch(GetAllTemplatesWithVariables({
      userId: user?.role === 'customer' ? user?._id
        : currentCampaign?.userId
    }));
  };

  const handleVideoPlayer = () => {
    setVideoPlayerModal(true);

    if (user?.language === 'en') setVideoPlayerModalLink('https://www.youtube.com/watch?v=topBLaz4zgk');
    else if (user?.language === 'de') setVideoPlayerModalLink('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');
  };

  const downloadFile = (fileKey: string) => {
    const extension = fileKey.split('.').pop();
    if (['csv', 'tsv', 'xlsx', 'xls'].includes(extension || '')) {
      dispatch(ReadOneTimeCampaignFile({
        campaignName: addCampaign?.name,
        fileKey
      }));
    }
  };

  useEffect(() => {
    if (getTemplateForEmployeeSuccess) {
      setCurrentTemplateVariables(
        templateSheets?.map((sheet) => sheet?.variables).flat() as string[]
      );
    }
  }, [getTemplateForEmployeeSuccess]);

  useEffect(() => {
    if (getTemplateByIdSuccess && openEditOneTimeCampaign) {
      setAddCampaignHelperText({ ...addCampaignHelperText, fileKey: '' });

      const newVariables = templateSheets?.map((sheet) => sheet?.variables).flat() as string[];
      const diff = GetArrayDifference({
        arr1: currentTemplateVariables,
        arr2: newVariables
      });

      if (diff.length) {
        setAddCampaignHelperText({
          ...addCampaignHelperText,
          fileKey: t('template_variables_changed')
        });
      } else if (updateCampaignTrigger) {
        updateOneTimeCampaign();
        setUpdateCampaignTrigger(false);
      }

      if (openEditOneTimeCampaign
        && downloadSampleFileTrigger) {
        DownloadSampleFile({
          type: 'manual-campaign',
          columnsToDownload: templateSheets?.map((sheet) => sheet?.variables).flat() as string[],
          mandatoryColumns: SHEETS_MANDATORY_COLUMNS,
          sampleFileName: SAMPLE_FILE_NAMES.CAMPAIGN_SAMPLE_FILE_NAME
        });
        setDownloadSampleFileTrigger(false);
      }
    }
  }, [getTemplateByIdSuccess]);

  useEffect(() => {
    if (preSignedUrl !== '') {
      if (!isEmpty(addCampaign?.fileKey)) {
        dispatch(SetOtherNotifyState({
          message: t('file_replaced_message'),
          type: 'success'
        }));
      }
      if (!isEmpty(fileUploadKey)) {
        setAddCampaign({ ...addCampaign, fileKey: fileUploadKey });
        setAddCampaignHelperText({ ...addCampaignHelperText, fileKey: '' });
      }
      handleUploadAttachmentOnS3();
      dispatch(SetOtherState({
        field: 'preSignedUrl',
        value: ''
      }));
    }
  }, [preSignedUrl]);

  useEffect(() => {
    if (openEditOneTimeCampaign) {
      setAddCampaignHelperText({});
      setAddCampaign(campaigns?.find((camp) => camp._id === editCampaignId) || ADD_CAMPAIGN_INITIAL_STATE);
      dispatch(SetCampaignState({
        field: 'currentCampaign',
        value: campaigns?.find((camp) => camp._id === editCampaignId)
      }));
    } else if (open) {
      getAllTemplatesWithVariables();
    }
  }, [open, openEditOneTimeCampaign]);

  useEffect(() => {
    if (templatesWithVariables?.length) {
      const optionsData = templatesWithVariables?.map((item) => ({
        label: item?.name,
        value: item?._id,
        variables: item?.variables
      }));
      if (optionsData?.length) {
        setOptions(optionsData);
      }
    }
  }, [templatesWithVariables]);

  useEffect(() => {
    if (!isEmpty(addCampaign?.templateId)) {
      const findTemplate = templatesWithVariables?.find((item) => item?._id === addCampaign?.templateId);
      setSampleFileColumns(findTemplate?.variables || []);
    }
  }, [addCampaign?.templateId]);

  useEffect(() => {
    if (isCampaignAdded) {
      resetValues();
      dispatch(SetCampaignState({
        field: 'isCampaignAdded',
        value: false
      }));
      dispatch(SetCampaignState({
        field: 'campaigns',
        value: []
      }));
    }
  }, [isCampaignAdded]);

  useEffect(() => {
    if (isCampaignUpdated) {
      setEditCampaignId?.('');
      setOpenEditOneTimeCampaign?.(false);
      resetValues();
    }
  }, [isCampaignUpdated]);

  useEffect(() => {
    if (downloadSampleFileTrigger && openEditOneTimeCampaign) {
      dispatch(GetTemplateById({
        templateId: currentCampaign?.templateId as string
      }));
    }
  }, [downloadSampleFileTrigger]);

  const titleHtml = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <p
        style={{
          margin: 0,
          color: 'black',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '1.5',
          wordWrap: 'break-word'
        }}
      >
        {t('upload_one_time_campaign')}
      </p>
      {/* <Button
        type="default"
        onClick={() => handleVideoPlayer()}
        text={t('tutorial_link_text')}
      /> */}
    </div>
  );

  return (
    <>
      <QuickTutorial
        open={false}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
      <ModalUpload
        footer
        cancelText={t('cancel')}
        onCancelClick={resetValues}
        onSubmitClick={handleUpload}
        saveText={t('upload')}
        width={513}
        downloadSampleFile={addCampaign?.templateId && t('download_sample_file')}
        downloadSampleFileClick={() => {
          if (openEditOneTimeCampaign) {
            setDownloadSampleFileTrigger(true);
          } else {
            DownloadSampleFile({
              type: 'manual-campaign',
              columnsToDownload: openEditOneTimeCampaign
                ? currentTemplateVariables
                : sampleFileColumns,
              mandatoryColumns: SHEETS_MANDATORY_COLUMNS,
              sampleFileName: SAMPLE_FILE_NAMES.CAMPAIGN_SAMPLE_FILE_NAME
            });
          }
        }}
        open={open}
        title={title || titleHtml}
      >
        {(preSignedUrlLoading || addCampaignLoading) && <Loader />}
        <Row gutter={16}>
          {uploadNickname ? '' : (
            <>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Input
                  name="name"
                  label={t('modal_campaign_name')}
                  placeholder={t('enter')}
                  className="form-input add-employee-input"
                  value={addCampaign?.name}
                  onChange={(e: any) => handleChange(e)}
                  helperText={addCampaignHelperText.name}
                  marginBottom="24px"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Select
                  label={t('template')}
                  placeholder={t('select')}
                  marginBottom="8px"
                  options={options}
                  value={openEditOneTimeCampaign
                    ? (templates?.find((item) => item?._id
                      === currentCampaign?.templateId)?.name)
                    : options?.find((item) => item?.value
                      === addCampaign?.templateId)?.label || ''}
                  onChange={(value) => {
                    handleSelectChange(value, 'templateId');
                  }}
                  helperText={addCampaignHelperText.templateId}
                  disabled={user?.role === 'employee'}
                />
                {user?.role === 'employee'
                    && !isUsed
                    && (
                      <button
                        type="button"
                        className="edit-template-text-button"
                        onClick={editTemplateHandler}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            editTemplateHandler();
                          }
                        }}
                      >
                        {t('edit_template')}
                      </button>
                    )}
              </Col>
            </>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Upload
              handleChangeAttachment={(info: UploadChangeParam) => handleChangeAttachment(
                info,
                BUCKET_NAME_VALUES.campaign_files
              )}
            />
            {addCampaignHelperText.fileKey && (
            <div className="file-upload-helper-text">{addCampaignHelperText.fileKey}</div>
            )}
            {addCampaign?.fileKey
              ? (
                <div className="progress-box">
                  <CampaignsWrapper>
                    <div className="d-flex justify-content-between add-space">
                      <Space align="center">
                        <img src={Document} alt={t('no_document')} />
                        <span className="label">
                          {
                            openEditOneTimeCampaign
                              ? addCampaign?.fileKey
                              : attachment?.name
                          }
                        </span>
                      </Space>
                      {openEditOneTimeCampaign
                        && (
                          <div
                            role="button"
                            tabIndex={0}
                            className="cursor-pointer"
                            onClick={() => {
                              downloadFile(addCampaign?.fileKey);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                downloadFile(addCampaign?.fileKey);
                              }
                            }}
                          >
                            <img
                              src={Download}
                              alt={t('download')}
                            />
                          </div>
                        )}
                      <div
                        role="button"
                        tabIndex={0}
                        className="cursor-pointer"
                        onClick={handleDelete}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleDelete();
                          }
                        }}
                      >
                        <img
                          src={(progress === 100 || openEditOneTimeCampaign) ? Trash : TickCircle}
                          alt={t('no_document')}
                        />
                      </div>
                    </div>
                  </CampaignsWrapper>
                  {!openEditOneTimeCampaign
                    ? (progress !== 100 && <Progress percent={progress} />)
                    : null}
                </div>
              ) : null}
          </Col>
        </Row>
      </ModalUpload>
      <ErrorsModal
        width="513px"
        className="bf-error-wrapper"
        icon={<img src={Blocked} alt={t('no_icon')} height={80} />}
        heading={t('campaign_errors_heading')}
        desc={<div dangerouslySetInnerHTML={{ __html: htmlErrorTable }} />}
        open={openErrorModal}
        onCancel={() => {
          setOpenErrorModal(false);
        }}
        okayText="OK"
        noDesc
      />
    </>
  );
};

export default Modal;
