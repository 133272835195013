import React, { ReactNode, ChangeEvent } from 'react';
import { Input, DatePicker, Popover } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import InputWrapper from './input.style';

import ChatInfo from '../../assets/images/chat-info.svg';

interface IndexProps {
  placeholder?: string;
  onChange?: ((e: ChangeEvent<HTMLInputElement>) => void) | ((e: ChangeEvent<HTMLTextAreaElement>) => void) | undefined;
  onFocus?: () => void;
  onBlur?: () => void;
  type?: 'password' | 'search' | 'date' | string;
  helperText?: ReactNode;
  helperTextClass?: string;
  label?: string;
  optionalLabel?:string,
  onSearch?: (value: string) => void;
  width?: string;
  className?: string;
  labelPopoverContent?: string;
  labelPopover?: string;
  name?: string;
  value?: string | undefined;
  authInput?: boolean | undefined;
  display?: string;
  marginBottom?: string | undefined
  rows?: number
  onIconClick?:React.MouseEventHandler<HTMLElement> | undefined;
  editable?: boolean;
  id?: string | undefined;
  onClick?: () => void;
  disabled?: boolean;
  style?: any;
  onSelect?: (e: any) => void;
  onKeyUp?: (e: any) => void;
}

const Index: React.FC<IndexProps> = (props) => {
  const {
    placeholder,
    onChange,
    onFocus,
    onBlur,
    type,
    helperText,
    helperTextClass,
    label,
    onSearch,
    width,
    className,
    name,
    value,
    authInput,
    marginBottom,
    display,
    rows,
    optionalLabel,
    onIconClick,
    editable = true,
    id,
    onClick,
    disabled,
    style,
    onSelect,
    onKeyUp,
    labelPopover,
    labelPopoverContent
  } = props;

  const { Search } = Input;
  const { TextArea } = Input;

  return (
    <InputWrapper
      width={width}
      marginBottom={marginBottom}
      className={className}
      authInput={authInput}
      display={display}
    >
      {label && <label>{label}</label>}
      {labelPopover && (
        <div className="popover-content-wrapper">
          <label>{labelPopover}</label>
          <Popover placement="top" className="control-width pl-1" content={labelPopoverContent}>
            <span className="tooltip"><img src={ChatInfo} alt="info" /></span>
          </Popover>
        </div>
      )}
      <div className="input-wrapper">

        { optionalLabel && <i className="italic-letter">{optionalLabel}</i>}
        {type === 'password' ? (
          <Input.Password
            placeholder={placeholder}
            onChange={onChange as (e: ChangeEvent<HTMLInputElement>) => void}
            name={name}
            value={value}
          />
        ) : type === 'search' ? (
          <Search
            placeholder={placeholder}
            onSearch={onSearch as (value: string) => void}
          />
        ) : type === 'date' ? (
          <DatePicker
            onChange={onChange as (date: unknown, dateString: string | string[]) => void}
            name={name}
          />
        ) : type === 'textarea'
          ? (
            <TextArea
              rows={rows}
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange as (e: any) => void}
              id={id}
              style={{ ...style, resize: 'none' }}
              onClick={onClick}
              disabled={disabled}
              onSelect={onSelect}
              onKeyUp={onKeyUp}
            />
          ) : type === 'simpleInput'
            ? (
              <Input
                placeholder={placeholder}
                onChange={onChange as (e: ChangeEvent<HTMLInputElement>) => void}
                type="password"
                onFocus={onFocus}
                onBlur={onBlur}
                name={name}
                value={value}
                autoComplete="off"
                suffix={<EyeOutlined onClick={onIconClick} className="color-Primary" />}
                disabled={!editable}
              />
            )
            : (
              <Input
                placeholder={placeholder}
                onChange={onChange as (e: ChangeEvent<HTMLInputElement>) => void}
                type={type}
                onFocus={onFocus}
                onBlur={onBlur}
                name={name}
                value={value}
                autoComplete="off"
                disabled={!editable}
                className={className}
              />
            )}
        {helperText && (
        <div className={`helper-text ${helperTextClass || (type === 'password' ? 'input-password' : 'error-text')} `}>
          {helperText}
        </div>
        )}
      </div>
    </InputWrapper>
  );
};

export default Index;
