import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
  Popover,
  RadioChangeEvent
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { extend } from 'lodash';
import { Range, RangeKeyDict } from 'react-date-range';

import Loader from '../../components/loader';
import Select from '../../components/select';
import TreeSelect from '../../components/tree-select';
import DateRange from '../../components/date-range-picker';
import GroupButton from '../../components/button-group';
import StatisticsChart from '../../components/dashboard-graph';
import Button from '../../components/button';
import ActionsButtons from './actions-buttons';

import ChatInfo from '../../assets/images/chat-info.svg';
import DashboardWrapper from './style';

import { GetDashboardStats, GetGraphData } from '../../redux/slices/dashboard';
import { GetCampaignsList } from '../../redux/slices/campaign';
import { GetProductsList } from '../../redux/slices/product';

import { AppDispatch } from '../../redux/store';

import { DashboardState } from '../../redux/types/dashboard';
import { CampaignState } from '../../redux/types/campaign';
import { ProductState } from '../../redux/types/product';

interface OptionsType {
  value: string
  label: string
}

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch();

  const {
    loading,
    dashboardTilesData,
    graphData
  } = useSelector((state: { dashboard: DashboardState }) => state.dashboard);
  const {
    loading: productLoading,
    parentProducts
  } = useSelector((state: { product: ProductState }) => state.product);
  const {
    loading: campaignLoading,
    campaignsList,
    templatesList
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const [date, setDate] = useState('daily');
  const [asinDropDownData, setAsinDropDownData] = useState<any[]>([]);
  const [daysArray, setDaysArray] = useState<string[]>();
  const [dateRange, setDateRange] = useState<Range[]>([{
    startDate: moment().startOf('month').toDate(),
    endDate: new Date(),
    key: 'selection'
  }]);
  const [groupBy, setGroupBy] = useState<'daily' | 'weekly' | 'monthly'>('daily');
  const [scannedCountList, setScannedCountList] = useState<number[]>();
  const [reviewCountList, setReviewCountList] = useState<number[]>();
  const [lettersSentOutWithQrCodeList, setLettersSentOutWithQrCodeList] = useState<number[]>();
  const [lettersSentOutWithoutQrCodeList, setLettersSentOutWithoutQrCodeList] = useState<number[]>();
  const [newDate, setNewDate] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');
  const [selectedAsins, setSelectedAsins] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>(moment().startOf('month').toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [optionsCampaign, setOptionsCampaign] = useState<OptionsType[]>();
  const [optionsTemplate, setOptionsTemplate] = useState<OptionsType[]>();
  const { t } = useTranslation();
  const contentReview = (
    <div className="add-max-width">
      <p>Reviews generated / Letters sent out *100</p>
    </div>
  );
  const contentScan = (
    <div className="add-max-width">
      <p>QR Code Scans / Letters sent out *100</p>
    </div>
  );

  const StorageButtonGroup = [{
    value: 'daily',
    label: 'Daily'
  }, {
    value: 'weekly',
    label: 'Weekly'
  }, {
    value: 'monthly',
    label: 'Monthly'
  }];

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setDate(value);
    setGroupBy(value);
  };

  const handleChangeCampaign = (value: string) => {
    setSelectedCampaign(value);
  };

  const handleChangeTemplate = (value: string) => {
    setSelectedTemplate(value);
  };

  const handleChangeAsin = (value: string[]) => {
    const newAsins: string[] = [];
    value.forEach((asin) => {
      const isParent = parentProducts?.find(({ parentAsin }) => parentAsin === asin);
      if (isParent && isParent?.childAsins?.length > 0) {
        newAsins.push(...isParent.childAsins);
      } else {
        newAsins.push(asin);
      }
    });

    setSelectedAsins(newAsins);
  };

  const handleSelectDate = (value: RangeKeyDict) => {
    if (value) {
      const { selection } = value;
      setDateRange([selection]);

      if (selection.startDate && selection.endDate) {
        setStartDate(selection.startDate);
        setEndDate(selection.endDate);
      }
    }
  };

  const handleApplyDates = () => {
    const setObj = {
      campaignId: selectedCampaign,
      templateId: selectedTemplate,
      selectedAsins
    };

    if (startDate && endDate) {
      const startDay = moment(startDate).date();
      const startMonth = moment(startDate).month();
      const startYear = moment(startDate).year();

      const endDay = moment(endDate).date();
      const endMonth = moment(endDate).month();
      const endYear = moment(endDate).year();

      extend(setObj, {
        startDate: {
          day: startDay,
          month: startMonth,
          year: startYear
        },
        endDate: {
          day: endDay,
          month: endMonth,
          year: endYear
        }
      });
    }

    dispatch(GetGraphData(setObj));
    setNewDate(false);
  };

  const groupDataBy = (
    start: moment.Moment,
    end: moment.Moment,
    interval: moment.unitOfTime.DurationConstructor
  ) => {
    const daysList = [];
    for (let day = start.clone(); day <= end; day.add(1, interval)) {
      daysList.push(day.clone());
    }
    return daysList;
  };

  useEffect(() => {
    if (graphData && graphData.length > 0) {
      let start = moment().startOf('month').startOf('day');
      let end = moment().endOf('day');

      if (startDate && endDate) {
        start = moment(startDate).startOf('day');
        end = moment(endDate).endOf('day');
      }

      if (groupBy === 'weekly') {
        end = moment(end).endOf('week');
      }

      if (groupBy === 'monthly') {
        end = moment(end).endOf('month');
      }

      let daysList: moment.Moment[];
      let currentGroupBy: moment.unitOfTime.StartOf;
      switch (groupBy) {
        case 'weekly':
          daysList = groupDataBy(start, end, 'week');
          currentGroupBy = 'week';
          break;
        case 'monthly':
          daysList = groupDataBy(start, end, 'month');
          currentGroupBy = 'month';
          break;
        case 'daily':
        default:
          daysList = groupDataBy(start, end, 'day');
          currentGroupBy = 'days';
          break;
      }

      const scannedCountArray: number[] = [];
      const reviewCountArray: number[] = [];
      const lettersSentOutWithQrCodeArray: number[] = [];
      const lettersSentOutWithoutQrCodeArray: number[] = [];

      const newDaysArray = daysList.map((day) => {
        const startRange = day.startOf(currentGroupBy).format('YYYY-MM-DD');
        const endRange = day.endOf(currentGroupBy).format('YYYY-MM-DD');

        const dataForPeriod = graphData.filter((data) => {
          const dataTimestamp = moment(data.timestamp).format('YYYY-MM-DD');
          return moment(dataTimestamp).isBetween(startRange, endRange, null, '[]');
        });

        const totalScannedCount = dataForPeriod
          .reduce((sum, data) => sum + (data.scannedCount || 0), 0);
        const totalReviewCount = dataForPeriod
          .reduce((sum, data) => sum + (data.reviewCount || 0), 0);
        const totalLettersSentOutWithQrCode = dataForPeriod
          .reduce((sum, data) => sum + (data.lettersSentOutWithQrCode || 0), 0);
        const totalLettersSentOutWithoutQrCode = dataForPeriod
          .reduce((sum, data) => sum + (data.lettersSentOutWithoutQrCode || 0), 0);

        scannedCountArray.push(totalScannedCount);
        reviewCountArray.push(totalReviewCount);
        lettersSentOutWithQrCodeArray.push(totalLettersSentOutWithQrCode);
        lettersSentOutWithoutQrCodeArray.push(totalLettersSentOutWithoutQrCode);

        return day.format(groupBy === 'monthly' ? 'MMMM YYYY' : 'MMMM DD');
      });

      setDaysArray(newDaysArray);
      setScannedCountList(scannedCountArray);
      setReviewCountList(reviewCountArray);
      setLettersSentOutWithQrCodeList(lettersSentOutWithQrCodeArray);
      setLettersSentOutWithoutQrCodeList(lettersSentOutWithoutQrCodeArray);
    } else {
      setDaysArray([]);
      setScannedCountList([]);
      setReviewCountList([]);
      setLettersSentOutWithQrCodeList([]);
      setLettersSentOutWithoutQrCodeList([]);
    }
  }, [graphData, groupBy]);

  useEffect(() => {
    if (campaignsList?.length) {
      const newList = campaignsList.map(({
        _id,
        name
      }) => ({
        value: _id,
        label: name
      }));

      newList.splice(0, 0, {
        label: 'All',
        value: 'All'
      });

      setOptionsCampaign(newList);
    }
  }, [campaignsList]);

  useEffect(() => {
    if (templatesList?.length) {
      const newList = templatesList.map(({
        _id,
        name
      }) => ({
        value: _id,
        label: name
      }));

      newList.splice(0, 0, {
        label: 'All',
        value: 'All'
      });

      setOptionsTemplate(newList);
    }
  }, [templatesList]);

  useEffect(() => {
    if (parentProducts?.length) {
      const asinDataDropDown = [];
      for (let j = 0; j < parentProducts.length; j += 1) {
        const {
          parentAsin,
          childAsins
        } = parentProducts[j];

        const children = childAsins.map((child) => ({
          title: child,
          value: child,
          key: child
        }));

        asinDataDropDown.push({
          title: parentAsin,
          value: parentAsin,
          key: parentAsin,
          children
        });
      }

      setAsinDropDownData(asinDataDropDown);
    }
  }, [parentProducts]);

  useEffect(() => {
    dispatch(GetDashboardStats());
    dispatch(GetCampaignsList());
    dispatch(GetProductsList());
  }, []);

  useEffect(() => {
    const setObj = {
      campaignId: selectedCampaign,
      templateId: selectedTemplate,
      selectedAsins
    };

    if (startDate && endDate) {
      const startDay = moment(startDate).date();
      const startMonth = moment(startDate).month();
      const startYear = moment(startDate).year();

      const endDay = moment(endDate).date();
      const endMonth = moment(endDate).month();
      const endYear = moment(endDate).year();

      extend(setObj, {
        startDate: {
          day: startDay,
          month: startMonth,
          year: startYear
        },
        endDate: {
          day: endDay,
          month: endMonth,
          year: endYear
        }
      });
    }

    dispatch(GetGraphData(setObj));
  }, [selectedCampaign, selectedTemplate, selectedAsins]);

  return (
    <>
      {loading && <Loader />}
      <DashboardWrapper>
        <div className="header-bar">
          <h2 className="heading">{t('dashboard')}</h2>
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-2">
              <Select
                showSearch
                width="237px"
                placeholder={t('campaign')}
                onChange={(value) => handleChangeCampaign(value)}
                dropDownData={optionsCampaign}
                disabled={campaignLoading}
              />
              <Select
                showSearch
                width="237px"
                placeholder={t('template')}
                onChange={(value) => handleChangeTemplate(value)}
                dropDownData={optionsTemplate}
                disabled={campaignLoading}
              />
              <TreeSelect
                treeData={asinDropDownData}
                placeholder={t('parent_child_dropdown')}
                onChange={handleChangeAsin}
                disabled={productLoading}
              />
              <div className="inner-content">
                <ActionsButtons
                  setNewDate={setNewDate}
                  date={newDate}
                />
                {newDate
              && (
              <div className="data-range-box">
                <div className="button-box">
                  <h3>{t('current_date_range')}</h3>
                  <div className="d-flex gap-2">
                    <Button width="98px" text={t('cancel')} type="default" onClick={() => setNewDate(false)} />
                    <Button text={t('apply')} width="104px" type="primary" onClick={() => handleApplyDates()} />
                  </div>
                </div>
                <DateRange dateRange={dateRange} handleSelectDate={handleSelectDate} />
              </div>
              )}
                <GroupButton options={StorageButtonGroup} onChange={onChange} value={date} />
              </div>
            </div>
          </div>
        </div>
        <div className="info-box">
          <div className="info">
            <span>{t('letters_sent_out_with_qr_code')}</span>
            <span>{dashboardTilesData?.lettersSentOutQrCode || 0}</span>
          </div>
          { dashboardTilesData?.lettersSentOutWithoutQrCode !== undefined
            && dashboardTilesData?.lettersSentOutWithoutQrCode > 0
              && (
                <div className="info">
                  <span>{t('letters_sent_out_without_qr_code')}</span>
                  <span>{dashboardTilesData?.lettersSentOutWithoutQrCode || 0}</span>
                </div>
              )}
          <div className="info">
            <span>
              {t('qr_codes_scanned')}
              <Popover className="control-width pl-1" content={contentScan}>
                <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
              </Popover>
            </span>
            <span>
              {dashboardTilesData?.scannedQRCodes || 0}
              %
            </span>
          </div>
          <div className="info">
            <span>
              {t('reviews_generated')}
              <Popover className="control-width pl-1" content={contentReview}>
                <span className="tooltip"><img src={ChatInfo} alt={t('no_info')} /></span>
              </Popover>
            </span>
            <span>
              {dashboardTilesData?.reviewsGenerated || 0}
              %
            </span>
          </div>
        </div>
        <div className="statistics-box">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <h3>{t('statistics')}</h3>
          </div>
          <div className="graph-box">
            <StatisticsChart
              daysArray={daysArray}
              scannedCountList={scannedCountList}
              reviewCountList={reviewCountList}
              lettersSentOutWithQrCodeList={lettersSentOutWithQrCodeList}
              lettersSentOutWithoutQrCodeList={lettersSentOutWithoutQrCodeList}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
