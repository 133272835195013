import {
  ReactNode,
  useEffect
} from 'react';
import {
  createBrowserRouter,
  Navigate,
  useLocation
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { isEmpty } from 'lodash';
import { jwtDecode } from 'jwt-decode';

// Components
import AppLayout from '../layout';
import Dashboard from '../pages/dashboard';
import ManualCampaign from '../pages/manual-campaigns';
import Employees from '../pages/employees/index';
import Customers from '../pages/customers/index';
import Templates from '../pages/templates/index';
import Jobs from '../pages/jobs/index';
import Files from '../pages/files';
import HelpAndSupport from '../pages/help-support';
import Maxi from '../pages/maxi';
import Payment from '../pages/payment';
import PaymentSetting from '../pages/payment-setting';
import Products from '../pages/products';
import Profile from '../pages/profile';
import PaymentMethod from '../pages/payment-method';

import WaitingScreen from '../layout/header/drawer/waiting-screen';

import {
  GetUserById,
  SetAuthState
} from '../redux/slices/auth';
import { GetCurrentUser } from '../redux/slices/user';

import { AuthState } from '../redux/types/auth';
import { UserState } from '../redux/types/user';

import { AppDispatch } from '../redux/store';

import {
  GetLocalStorageItem,
  HandleLogout,
  RemoveLocalStorageItems
} from '../utils/helpers';

import { setAuthToken } from '../config/axios-configuration';
import i18n from '../config/language-conversion';

// This wrapper handles Logout Functionality on
// Token Expiry
// or Password Update.

const AuthWrapper = ({
  children
}: {
  children: ReactNode;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  const { user } = useSelector((state: { auth: AuthState }) => state.auth);
  const { user: userData } = useSelector((state: { user: UserState }) => state.user);

  const token = GetLocalStorageItem('token');

  setAuthToken(token || '');

  useEffect(() => {
    if (!isEmpty(token)) {
      const decodedToken = jwtDecode(token as string);
      const currentTime = parseInt((Date.now() / 1000).toFixed(0), 10);

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        RemoveLocalStorageItems(['token', 'user']);

        dispatch(SetAuthState({
          field: 'authToken',
          value: ''
        }));

        dispatch(SetAuthState({
          field: 'user',
          value: null
        }));

        window.location.href = '/sign-in';
      }

      if (userData?.language) {
        i18n.changeLanguage(userData?.language);
      } else {
        i18n.changeLanguage('en');
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const userId = JSON.parse(GetLocalStorageItem('user') as string)?._id;

    dispatch(GetUserById(userId));
    dispatch(GetCurrentUser());
  }, [location.pathname]);

  useEffect(() => {
    if (userData?.language) {
      i18n.changeLanguage(userData?.language);
    } else {
      i18n.changeLanguage('en');
    }
  }, [userData]);

  useEffect(() => {
    HandleLogout(user);
  }, [user]);

  return children;
};

const adminRouter = createBrowserRouter([{
  path: '/',
  element:
  <AuthWrapper>
    <AppLayout>
      <Dashboard />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '*',
  element: <Navigate to="/" replace />
}, {
  path: '/campaigns',
  element:
  <AuthWrapper>
    <AppLayout>
      <ManualCampaign />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/employees',
  element:
  <AuthWrapper>
    <AppLayout>
      <Employees />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/customers',
  element:
  <AuthWrapper>
    <AppLayout>
      <Customers />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/templates',
  element:
  <AuthWrapper>
    <AppLayout>
      <Templates />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/jobs',
  element:
  <AuthWrapper>
    <AppLayout>
      <Jobs />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/files',
  element:
  <AuthWrapper>
    <AppLayout>
      <Files />
    </AppLayout>
  </AuthWrapper>
}, {
//   path: '/maxi',
//   element:
//   <AuthWrapper>
//     <AppLayout>
//       <Maxi />
//     </AppLayout>
//   </AuthWrapper>
// }, {
  path: '/products',
  element:
  <AuthWrapper>
    <AppLayout>
      <Products />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/profile',
  element:
  <AuthWrapper>
    <AppLayout>
      <Profile />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/payment',
  element:
  <AuthWrapper>
    <AppLayout>
      <PaymentSetting />
    </AppLayout>
  </AuthWrapper>
}]);

const employeeRouter = createBrowserRouter([{
  path: '/',
  element:
  <AuthWrapper>
    <AppLayout>
      <Files />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/campaigns',
  element:
  <AuthWrapper>
    <AppLayout>
      <ManualCampaign />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '*',
  element: <Navigate to="/" replace />
}, {
  path: '/files',
  element:
  <AuthWrapper>
    <AppLayout>
      <Files />
    </AppLayout>
  </AuthWrapper>
}, {
//   path: '/maxi',
//   element:
//   <AuthWrapper>
//     <AppLayout>
//       <Maxi />
//     </AppLayout>
//   </AuthWrapper>
// }, {
  path: '/templates',
  element:
  <AuthWrapper>
    <AppLayout>
      <Templates />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/profile',
  element:
  <AuthWrapper>
    <AppLayout>
      <Profile />
    </AppLayout>
  </AuthWrapper>
}]);

const customerRouter = createBrowserRouter([{
  path: '/',
  element:
  <AuthWrapper>
    <AppLayout>
      <Dashboard />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '*',
  element: <Navigate to="/" replace />
}, {
  path: '/campaigns',
  element:
  <AuthWrapper>
    <AppLayout>
      <ManualCampaign />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/templates',
  element:
  <AuthWrapper>
    <AppLayout>
      <Templates />
    </AppLayout>
  </AuthWrapper>
}, {
//   path: '/maxi',
//   element:
//   <AuthWrapper>
//     <AppLayout>
//       <Maxi />
//     </AppLayout>
//   </AuthWrapper>
// }, {
  path: '/payment-method',
  element:
  <AuthWrapper>
    <AppLayout>
      <PaymentMethod />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/products',
  element:
  <AuthWrapper>
    <AppLayout>
      <Products />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/profile',
  element:
  <AuthWrapper>
    <AppLayout>
      <Profile />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/auth/sp-api',
  element:
  <AuthWrapper>
    <AppLayout>
      <WaitingScreen />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/payment',
  element:
  <AuthWrapper>
    <AppLayout>
      <Payment />
    </AppLayout>
  </AuthWrapper>
}, {
  path: '/help-and-support',
  element:
  <AuthWrapper>
    <AppLayout>
      <HelpAndSupport />
    </AppLayout>
  </AuthWrapper>
}]);

export {
  adminRouter,
  employeeRouter,
  customerRouter
};
