/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import PurchaseTag from '../../assets/images/purchased-tag.svg';
import PopularIcon from '../../assets/icons/popular-banner.svg';

import Button from '../button';

import CardItem from './card-item';

import { SetPaymentNotifyState } from '../../redux/slices/payment';

import { AppDispatch } from '../../redux/store';

import PriceCardWrapper from './style';

interface PriceCardProps {
  cardDisc1?: string,
  cardDisc2?: string,
  cardDisc3?: string,
  cardDisc4?: string,
  cardHeading?: string,
  cardSubHeading?: string,
  handlePayment?: any,
  items: any[],
  isBest?: boolean,
  planText: string,
  planPrice: string,
  className?: string,
  selected?: boolean,
  showButton?: boolean,
  setSubscription?: any,
  setPaymentModal?: any,
  user?: any
  basicPriceId?: any;
  brandedPriceId?: any;
  maxiPriceId?: any;
  isPurchasedPlan?: boolean;
  purchasedPlan?: string;
  setOpenCancelSubscriptionWarning?: any;
}

let currentHeading: string | null = null;

const PriceCard:React.FC<PriceCardProps> = ({
  cardDisc1,
  selected,
  cardDisc2,
  cardDisc3,
  cardDisc4,
  cardHeading,
  cardSubHeading,
  handlePayment,
  items,
  isBest,
  planPrice,
  planText,
  showButton,
  setSubscription,
  setPaymentModal,
  user,
  basicPriceId,
  brandedPriceId,
  maxiPriceId,
  isPurchasedPlan,
  purchasedPlan,
  setOpenCancelSubscriptionWarning,
  className
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const handleChoosePlan = (planType: string) => {
    if (user?.purchasedBundle?.planType === purchasedPlan
      && !isEmpty(user?.purchasedBundle?.subscriptionId)
    ) {
      setOpenCancelSubscriptionWarning(true);
      return;
    }

    if (basicPriceId?.productId || brandedPriceId?.productId || maxiPriceId?.productId) {
      if (user && isEmpty(user?.payment)) {
        setSubscription(true);
      } else {
        setPaymentModal(true);
      }

      handlePayment(planType === 'Plus' ? 'Maxi' : planType);
    } else {
      dispatch(SetPaymentNotifyState({
        message: 'Please Select a bundle value',
        type: 'error'
      }));

      setPaymentModal?.(false);
    }
  };

  return (
    <PriceCardWrapper className={`${selected ? 'overflow-wrapper' : ''} ${className}`}>
      <div className="card-header">
        {((planText === 'Plus' ? 'Maxi' : planText === purchasedPlan) && user?.purchasedBundle?.planType === purchasedPlan && isPurchasedPlan)
          ? <img src={PurchaseTag} alt="purchase-tag" className="purchase-tag" />
          : null}
        {isBest ? <img src={PopularIcon} alt={PopularIcon} /> : ''}
        <p className="plan-text">{planText}</p>
        <div className="amount-text">
          <p className="start-text">{t('starts')}</p>
          <h5>{planPrice}</h5>
          <p className="end-text">
            {t('letter')}
          </p>
        </div>
        <h6>{cardHeading}</h6>
        <h5>{cardSubHeading}</h5>
        <p>{cardDisc1}</p>
        <p>{cardDisc2}</p>
        <p>{cardDisc3}</p>
        <p>{cardDisc4}</p>
      </div>
      <div className="letter-card-sec">
        {items.map((item, index) => {
          const shouldRenderHeading = item.heading !== currentHeading;
          if (shouldRenderHeading) {
            currentHeading = item.heading!;
          }
          return (
            <div key={index}>
              <CardItem
                subItem={item.subItems}
                className={item.className}
                heading={shouldRenderHeading ? item.heading : undefined}
              />
            </div>
          );
        })}
        <div className="button-wrapper">
          {!showButton
            ? (
              <Button
                onClick={() => handleChoosePlan(planText)}
                type="default"
                text={user?.purchasedBundle?.planType === purchasedPlan
                  && !isEmpty(user?.purchasedBundle?.subscriptionId)
                  ? t('cancel_subscription')
                  : t('choose_plan')}
              />
            )
            : null}
        </div>
      </div>
    </PriceCardWrapper>
  );
};

export default PriceCard;
