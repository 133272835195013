import React, {
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import Input from '../../../components/input';
import Modal from '../../../components/modal';

import {
  CloneCampaign,
  SetCampaignState
} from '../../../redux/slices/campaign';

import { AppDispatch } from '../../../redux/store';

import { CampaignState } from '../../../redux/types/campaign';

interface CloneModalProps {
  onCancel: () => void;
  open?: boolean;
  title?: string;
  cloneCampaignId?: string
  setEditCampaignId: (value: string) => void;
  setCampaignAction?: (value: string) => void;
  setProductsData: (value: string[]) => void;
}

interface HelperText {
  [key: string]: string | undefined;
}

const CloneModal: React.FC<CloneModalProps> = ({
  onCancel,
  open,
  title,
  cloneCampaignId,
  setEditCampaignId,
  setCampaignAction,
  setProductsData
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    cloneCampaignLoading,
    clonedCampaign,
    isCampaignCloned
  } = useSelector((state: { campaign: CampaignState }) => state.campaign);

  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignNameHelperText, setCampaignNameHelperText] = useState<HelperText>({});

  const handleCampaignNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setCampaignName(value);

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'name') errors[name] = t('name_is_required');
    else if (!isEmpty(value) && name === 'name') {
      errors[name] = '';
    }

    setCampaignNameHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const cloneCampaign = () => {
    dispatch(CloneCampaign({
      campaignId: cloneCampaignId,
      name: campaignName
    }));

    dispatch(SetCampaignState({
      field: 'campaigns',
      value: []
    }));
  };

  const handleSubmitClick = () => {
    if (!campaignName) {
      setCampaignNameHelperText({ name: t('name_is_required') });
    } else {
      cloneCampaign();
    }
  };

  useEffect(() => {
    if (isCampaignCloned) {
      dispatch(SetCampaignState({
        field: 'campaigns',
        value: []
      }));
      if (clonedCampaign?._id && !isEmpty(clonedCampaign?._id)) {
        setCampaignAction?.('edit');
        setEditCampaignId(clonedCampaign?._id);
      }
      onCancel();
      setCampaignName('');
      setCampaignNameHelperText({});
    }
  }, [isCampaignCloned]);

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => {
        onCancel();
        setCampaignName('');
        setCampaignNameHelperText({});
        setProductsData([]);
      }}
      onSubmitClick={handleSubmitClick}
      saveText={t('clone_&_edit_button')}
      width={325}
      open={open}
      title={title}
      loading={cloneCampaignLoading}
      saveButtonWidth="40%"
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3 className="clone-heading">{t('clone')}</h3>
          <Input
            name="name"
            label={t('campaign_name')}
            marginBottom="24px"
            placeholder={t('enter')}
            className="form-input"
            value={campaignName}
            onChange={handleCampaignNameChange}
            helperText={campaignNameHelperText?.name}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CloneModal;
