import React, {
  useState,
  useEffect
} from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import { isEmpty } from 'lodash';
import CloneModal from './modals/clone';
import DeleteModal from './modals/delete';

import Table from '../../components/table';
import Pagination from '../../components/pagination';
import Button from '../../components/button';
import { TemplateFilterComponent } from '../../components/filter-column-header';
import { TemplateSearchComponent } from '../../components/search-column-header';
import QuickTutorial from '../../components/modal/quick-tutorial';
import Drawer from '../../components/drawer';
import Loader from '../../components/loader';
import ActionButtons from './action-buttons';
import DrawerData from './drawers/add-template';

import {
  AddTemplate,
  DeleteTemplateById,
  GetAllTemplates,
  GetTemplateForEmployee,
  SetTemplateState,
  UpdateTemplateById
} from '../../redux/slices/template';
import { SetOtherNotifyState } from '../../redux/slices/other';
import { GetPurchasedPlan } from '../../redux/slices/payment';

import { AppDispatch } from '../../redux/store';

import {
  AddTemplate as AddTemplateInterface,
  AddTemplateSheet as AddTemplateSheetInterface,
  Template,
  TemplateFilters,
  TemplateState,
  SortBy
} from '../../redux/types/template';
import { UserState } from '../../redux/types/user';

import {
  ConnectSocket,
  DisconnectSocket,
  HandleNotificationCreation
} from '../../utils/socket';

import {
  ADD_TEMPLATE_INITIAL_STATE,
  // ADVERTISEMENT_FORMATS,
  // LETTER_TYPES,
  PAGE_SIZES,
  PLAN_TYPES,
  // SHEET_COUNTS,
  TEMPLATE_SHEETS_INITIAL_STATE
} from '../../constants';

interface CellRendererProps {
  value: string | undefined;
}

const reactAppSocketServer = process.env.REACT_APP_API_SOCKET_SERVER;

const TemplateNameCustomCellRenderer:React.FC<CellRendererProps> = ({ value }) => {
  if (value) {
    return (
      value.length > 31 ? <Tooltip title={value}>{value}</Tooltip> : value
    );
  }

  return 'N/A';
};

const TemplateFormatCustomCellRenderer:React.FC<CellRendererProps> = ({ value }) => {
  if (value === 'Maxi') {
    return 'Gummy Bear + Letter';
  }

  return value;
};

const TemplatesTable: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  // const isMounted = useRef(true);

  const {
    getAllTemplatesSuccess,
    loading,
    deleteTemplateLoading,
    templates,
    totalTemplates,
    clonedTemplate,
    isTemplateDeleted,
    isTemplateAdded,
    isTemplateSaved,
    isTemplateUpdated,
    isTemplateCloned,
    templateSheets: templateSheetsData
  } = useSelector((state: { template: TemplateState }) => state.template);

  const { user } = useSelector((state: { user: UserState }) => state.user);

  const [isDataRetrievalSuccessful, setIsDataRetrievalSuccessful] = useState<boolean>(false);
  const [clone, setClone] = useState<boolean>(false);
  const [deleteTemplate, setDeleteTemplate] = useState<boolean>(false);
  const [templateAction, setTemplateAction] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(25);
  const [sortValue, setSortValue] = useState<SortBy | null>({});
  const [templatesData, setTemplatesData] = useState<Template[]>([]);
  const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>('');
  const [editTemplateId, setEditTemplateId] = useState<string>('');
  const [cloneTemplateId, setCloneTemplateId] = useState<string>('');
  const [videoPlayerModal, setVideoPlayerModal] = useState<boolean>(false);
  const [videoPlayerModalLink, setVideoPlayerModalLink] = useState<string>('');
  const [templateSheets,
    setTemplateSheets] = useState<AddTemplateSheetInterface[]>(TEMPLATE_SHEETS_INITIAL_STATE);
  const [addTemplate, setAddTemplate] = useState<AddTemplateInterface>(
    templateAction === 'edit'
      ? templates?.find((item) => item?._id === editTemplateId)
        || ADD_TEMPLATE_INITIAL_STATE
      : ADD_TEMPLATE_INITIAL_STATE
  );
  const [filters, setFilters] = useState<TemplateFilters>({
    searchByKeyWords: {
      name: '',
      advertisementFormat: '',
      letterStyle: '',
      letterType: '',
      envelopeFormat: '',
      envelopeColor: '',
      countOfSheets: undefined
    }
  });

  const query = new URLSearchParams(location.search);

  const [currentTemplateId, setCurrentTemplateId] = useState<string>(query.get('templateId') || '');

  const actionCellRenderer = (params: { data: Template }) => {
    const { _id: templateId } = params.data;
    return (
      <ActionButtons
        setClone={setClone}
        setTemplateAction={setTemplateAction}
        setDeleteTemplate={setDeleteTemplate}
        setDeleteTemplateId={setDeleteTemplateId}
        setEditTemplateId={setEditTemplateId}
        setCloneTemplateId={setCloneTemplateId}
        templateId={templateId}
      />
    );
  };

  const getAllTemplates = ({ applyFilters = filters }) => {
    const skip = (pageNumber - 1) * pageLimit;
    const limit = pageLimit;
    dispatch(
      GetAllTemplates({
        skip,
        limit,
        sortBy: sortValue || undefined,
        filters: applyFilters
      })
    );
  };

  const deleteTemplateById = () => {
    if (!isEmpty(deleteTemplateId)) {
      dispatch(DeleteTemplateById({
        templateId: deleteTemplateId || ''
      }));
    }
    setDeleteTemplateId(null);
    setDeleteTemplate(false);
  };

  const isTemplateSheetsDataUnchanged = () => {
    const tempObj = JSON.parse(JSON.stringify(templateSheets));

    tempObj.forEach((item: AddTemplateSheetInterface) => {
      delete item.logoImage;
    });

    return JSON.stringify(tempObj) === JSON.stringify(templateSheetsData);
  };

  const updateTemplateById = (isDraft = false) => {
    const temp = templates?.find((item) => item?._id === editTemplateId);

    if (temp && clonedTemplate?._id !== editTemplateId) {
      const {
        name,
        advertisementFormat,
        letterStyle,
        envelopeColor,
        envelopeFormat,
        maxiInsertId
      } = temp;

      if (name === addTemplate.name
        && advertisementFormat === addTemplate.advertisementFormat
        && letterStyle === addTemplate.letterStyle
        && envelopeColor === addTemplate.envelopeColor
        && envelopeFormat === addTemplate.envelopeFormat
        && maxiInsertId === addTemplate.maxiInsertId
        && isTemplateSheetsDataUnchanged()) {
        dispatch(SetOtherNotifyState({
          message: t('no_changes_found'),
          type: 'info'
        }));

        return;
      }
    }

    if (editTemplateId && !isEmpty(editTemplateId)) {
      const sheets: AddTemplateSheetInterface[] = [];
      templateSheets.forEach((obj: any) => {
        const {
          message,
          s3FileUrl = '',
          logoImage,
          variables,
          ...rest
        } = obj;

        const regex = /\{\{(.*?)\}\}/g;
        const matches = message.match(regex) || [];

        const values = matches.map((match: any) => match.slice(2, -2).trim());

        if (message || !isEmpty(s3FileUrl)) {
          sheets.push({
            message,
            ...rest,
            templateId: obj?.templateId || editTemplateId,
            variables: values.length ? values : variables
          });
        }
      });

      const updateParams = {
        ...addTemplate,
        countOfSheets: templateSheets?.length
        && (templateSheets.filter((sheet) => sheet.message !== '').length
        + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length),
        isDraft
      };

      if (updateParams.envelopeColor === '') {
        delete updateParams.envelopeColor;
      }

      if (sheets.every((sheet) => sheet?.sheetType === 'Handwritten')) {
        updateParams.letterType = 'Handwritten';
      } else if (sheets.every((sheet) => sheet?.sheetType === 'Printed')) {
        updateParams.letterType = 'Printed';
      } else {
        updateParams.letterType = addTemplate.letterType;
      }

      dispatch(UpdateTemplateById({
        templateId: editTemplateId,
        updateParams,
        templateSheets: sheets?.length ? sheets : []
      }));
    }
  };

  const columnDefs = [{
    headerName: t('template_name_1'),
    field: 'name',
    headerComponent: TemplateSearchComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'name',
      filters,
      showSearch: user?.role !== 'employee',
      setFilters,
      getAllTemplates,
      showArrow: sortValue?.name === 'asc'
        ? 'up'
        : sortValue?.name === 'desc'
          ? 'down'
          : null
    },
    flex: 1,
    // minWidth: 100,
    width: 450,
    pinned: 'left',
    cellRenderer: TemplateNameCustomCellRenderer
  }, {
  //   headerName: t('tbl_advertising_format'),
  //   field: 'advertisementFormat',
  //   flex: 1,
  //   headerComponent: TemplateFilterComponent,
  //   headerComponentParams: {
  //     setSortValue,
  //     fieldName: 'advertisementFormat',
  //     options: ADVERTISEMENT_FORMATS,
  //     filters,
  //     showFilter: user?.role !== 'employee',
  //     setFilters,
  //     getAllTemplates,
  //     showArrow: sortValue?.advertisementFormat === 'asc'
  //       ? 'up'
  //       : sortValue?.advertisementFormat === 'desc'
  //         ? 'down'
  //         : null
  //   },
  //   minWidth: 100,
  //   cellRenderer: TemplateFormatCustomCellRenderer
  // }, {
    headerName: t('plan'),
    field: 'plan',
    flex: 1,
    headerComponent: TemplateFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'plan',
      options: PLAN_TYPES,
      filters,
      showFilter: user?.role !== 'employee',
      setFilters,
      getAllTemplates,
      showArrow: sortValue?.plan === 'asc'
        ? 'up'
        : sortValue?.plan === 'desc'
          ? 'down'
          : null
    },
    // minWidth: 100,
    width: 100
    // cellRenderer: TemplateFormatCustomCellRenderer
    // }, {
    //   headerName: t('type'),
    //   field: 'letterType',
    //   flex: 1,
    //   headerComponent: TemplateFilterComponent,
    //   headerComponentParams: {
    //     setSortValue,
    //     fieldName: 'letterType',
    //     options: LETTER_TYPES,
    //     filters,
    //     showFilter: user?.role !== 'employee',
    //     setFilters,
    //     getAllTemplates,
    //     showArrow: sortValue?.letterType === 'asc'
    //       ? 'up'
    //       : sortValue?.letterType === 'desc'
    //         ? 'down'
    //         : null
    //   },
    //   minWidth: 100,
    //   valueGetter: (params: any) => {
    //     const temp = templates?.find((item) => item?._id === params.data._id);
    //     if (templateSheets?.length) {
    //       const sheets = templateSheets?.filter((sheet) => sheet.templateId === temp?._id);

  //       if (sheets?.some((sheet) => sheet.sheetType === 'Handwritten')
  //         && sheets.some((sheet) => sheet.sheetType === 'Printed')) {
  //         return 'Handwritten & Printed';
  //       }
  //       return params.data.letterType;
  //     }
  //     return params.data.letterType;
  //   }
  // }, {
  //   headerName: t('tbl_count_of_sheets'),
  //   field: 'countOfSheets',
  //   minWidth: 100,
  //   flex: 1,
  //   headerComponent: TemplateFilterComponent,
  //   headerComponentParams: {
  //     setSortValue,
  //     fieldName: 'countOfSheets',
  //     options: SHEET_COUNTS,
  //     filters,
  //     showFilter: user?.role !== 'employee',
  //     setFilters,
  //     getAllTemplates,
  //     showArrow: sortValue?.countOfSheets === 'asc'
  //       ? 'up'
  //       : sortValue?.countOfSheets === 'desc'
  //         ? 'down'
  //         : null
  //   }
  }, {
    headerName: t('created_at'),
    field: 'createdAt',
    // minWidth: 100,
    width: 100,
    flex: 1,
    headerComponent: TemplateFilterComponent,
    headerComponentParams: {
      setSortValue,
      fieldName: 'createdAt',
      filters,
      setFilters,
      getAllTemplates,
      showFilter: false,
      showArrow: sortValue?.createdAt === 'asc'
        ? 'up'
        : sortValue?.createdAt === 'desc'
          ? 'down'
          : null
    }
  }, {
    headerName: t('actions'),
    field: 'action',
    pinned: 'right',
    flex: 1,
    cellRenderer: actionCellRenderer
  }];

  const onPageChange = (
    page: number,
    size: number
  ) => {
    setPageNumber(page);
    setPageLimit(size);
  };

  useEffect(() => {
    const socket = ConnectSocket({
      url: reactAppSocketServer || '',
      userId: user?._id || ''
    });

    HandleNotificationCreation(socket, dispatch);

    dispatch(GetPurchasedPlan());

    return () => {
      DisconnectSocket(socket);
    };
  }, []);

  useEffect(() => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    if (user?.role === 'employee' && currentTemplateId) {
      dispatch(GetTemplateForEmployee({
        templateId: currentTemplateId
      }));
    } else {
      getAllTemplates({ applyFilters: filters });
    }
  }, [pageLimit,
    pageNumber,
    sortValue]);

  useEffect(() => {
    if (templates?.length) {
      const processedTemplates = templates.map((template) => ({
        ...template,
        createdAt: moment(template?.createdAt).format('DD MMMM YYYY')
      }));
      setTemplatesData(processedTemplates);
    } else {
      setTemplatesData([]);
    }

    if (isTemplateAdded) {
      getAllTemplates({ applyFilters: filters });
      dispatch(SetTemplateState({
        field: 'isTemplateAdded',
        value: false
      }));
    }
    if (isTemplateDeleted) {
      setDeleteTemplate(false);
      getAllTemplates({ applyFilters: filters });
      dispatch(SetTemplateState({
        field: 'isTemplateDeleted',
        value: false
      }));
    }
    if (isTemplateUpdated) {
      if (user?.role === 'employee' && currentTemplateId) {
        dispatch(GetTemplateForEmployee({
          templateId: currentTemplateId
        }));
      } else {
        getAllTemplates({ applyFilters: filters });
      }
      setEditTemplateId('');
      dispatch(SetTemplateState({
        field: 'isTemplateUpdated',
        value: false
      }));
    }
    if (isTemplateCloned) {
      getAllTemplates({ applyFilters: filters });
      dispatch(SetTemplateState({
        field: 'isTemplateCloned',
        value: false
      }));
    }
  }, [templates,
    isTemplateAdded,
    isTemplateCloned,
    isTemplateDeleted,
    isTemplateUpdated]);

  useEffect(() => {
    if (getAllTemplatesSuccess) {
      setIsDataRetrievalSuccessful(true);

      dispatch(SetTemplateState({
        field: 'getAllTemplatesSuccess',
        value: false
      }));
    }
  }, [getAllTemplatesSuccess]);

  const titleComponent = (
    <div className="drawer-tutorial-link d-flex align-items-center justify-content-between">
      <h5 className="drawer-heading m-0">{t('template')}</h5>
    </div>
  );

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="content-header">
        <h2 className="heading">
          {t('templates')}
        </h2>
        {user?.role !== 'employee' && (
          <Button
            type="primary"
            icon={<FaPlus />}
            text={t('new_template')}
            onClick={() => {
              setTemplateAction('add');

              dispatch(SetTemplateState({
                field: 'isTemplateSaved',
                value: false
              }));
            }}
          />
        )}
      </div>
      <Table
        rowData={templatesData}
        columnDefs={columnDefs}
        resizable
        editable
        filter
        height="164px"
        rowHeight={36}
        headerHeight={40}
        className="custom-table"
        isDataRetrievalSuccessful={isDataRetrievalSuccessful}
      />
      <Pagination
        totalPages={totalTemplates}
        currentPage={pageNumber}
        onChange={onPageChange}
        pageSize={pageLimit}
        pageSizes={PAGE_SIZES}
      />
      <CloneModal
        open={clone}
        title={t('clone')}
        onCancel={() => setClone(false)}
        cloneTemplateId={cloneTemplateId}
      />
      <DeleteModal
        open={deleteTemplate}
        heading={t('delete_template_heading')}
        desc={t('you_wont_be_able_to_revert')}
        onCancel={() => {
          setDeleteTemplateId(null);
          setDeleteTemplate(false);
        }}
        onConfirm={() => {
          deleteTemplateById();
        }}
        loading={deleteTemplateLoading}
      />
      <QuickTutorial
        open={false}
        videoPlayerModal={videoPlayerModal}
        setVideoPlayerModal={setVideoPlayerModal}
        videoPlayerModalLink={videoPlayerModalLink}
      />
      <Drawer
        className="add-template-drawer-wrapper"
        onClose={() => {
          setTemplateAction('');
          setEditTemplateId('');

          if (!isTemplateSaved && !isTemplateUpdated) {
            if (!isEmpty(editTemplateId) && user?.role !== 'employee') {
              updateTemplateById(true);
            } else if (addTemplate.advertisementFormat !== '' && user?.role !== 'employee' && !(templateAction === 'edit')) {
              if (templateSheets?.length && (templateSheets.filter((sheet) => sheet.message !== '').length)) {
                dispatch(AddTemplate({
                  addTemplate: {
                    ...addTemplate,
                    countOfSheets: templateSheets?.length
                    && (templateSheets.filter((sheet) => sheet.message !== '').length
                    + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length),
                    isDraft: true
                  }
                }));
              }
            }
          }

          dispatch(SetTemplateState({
            field: 'isTemplateSaved',
            value: false
          }));
        }}
        title={titleComponent}
        open={templateAction === 'add' || templateAction === 'edit'}
        width="1107px"
      >
        <DrawerData
          editTemplateId={editTemplateId}
          setEditTemplateId={setEditTemplateId}
          templateAction={templateAction}
          setTemplateAction={setTemplateAction}
          addTemplate={addTemplate}
          setAddTemplate={setAddTemplate}
          templateSheets={templateSheets}
          setTemplateSheets={setTemplateSheets}
          updateTemplateById={updateTemplateById}
          setVideoPlayerModal={setVideoPlayerModal}
          setVideoPlayerModalLink={setVideoPlayerModalLink}
        />
      </Drawer>
    </>
  );
};

export default TemplatesTable;
