import React, {
  ChangeEvent,
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button';
import Input from '../../../components/input/index';
import Select from '../../../components/select';
import { ConnectionWrapper } from './style';

import { SetOtherNotifyState } from '../../../redux/slices/other';

import { AppDispatch } from '../../../redux/store';

import { UserState } from '../../../redux/types/user';

import { DecryptCredentials } from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  LANGUAGES_OPTIONS
} from '../../../constants';

interface FormData {
  language: string;
  name: string;
  senderAddress: {
    street: string;
    postCode: string;
    city: string;
  }
}

interface ProfileProps {
  formData: FormData;
  helperText: {
    name: string;
    senderAddress: {
      street: string;
      postCode: string;
      city: string;
    }
  } | undefined;
  onCancel?: () => void;
  onIconClick?:()=>void;
  onUpdate?:()=>void;
  setFormData?: (data: any) => void;
  setHelperText?: (data: any) => void;
}

const Profile: React.FC<ProfileProps> = ({
  formData,
  helperText,
  onCancel,
  onIconClick,
  onUpdate,
  setFormData,
  setHelperText
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: { user: UserState }) => state.user);
  const [email, setEmail] = useState('');

  const validateField = (
    value: string
  ): { name: string } => {
    const errors = { name: '' };

    if (value.trim() === '') {
      errors.name = AUTH_FORMS_ERROR_MESSAGES.REQUIRED('name');
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: string,
    value: string
  ) => {
    const errors = validateField(value);

    const updatedValue = { ...formData };

    const keys = fieldName.split('.');
    keys.reduce((obj, k, index) => {
      if (index === keys.length - 1) {
        obj[k] = value;
      } else {
        obj[k] = { ...obj[k] };
      }

      return obj[k];
    }, updatedValue as any);

    setFormData?.(updatedValue);

    setHelperText?.(errors);
  };

  const handleSubmit = () => {
    const errors = validateField(formData.name);

    if (errors.name) {
      setHelperText?.({
        name: errors.name
      });
      return;
    }

    if (formData.name === user?.name
      && formData.language === user?.language
        && formData.senderAddress.street === user?.senderAddress?.street
          && formData.senderAddress.city === user?.senderAddress?.city
            && formData.senderAddress.postCode === user?.senderAddress?.postCode) {
      dispatch(SetOtherNotifyState({
        message: t('no_changes_made'),
        type: 'info'
      }));
      return;
    }

    onUpdate?.();
  };

  useEffect(() => {
    if (user) {
      const decryptedEmail = DecryptCredentials(user?.email as string);
      setEmail(decryptedEmail);
    } else {
      setEmail('');
    }
  }, []);

  return (
    <ConnectionWrapper>
      <Input
        label={t('name')}
        placeholder={t('name')}
        value={formData.name}
        marginBottom="24px"
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('name', e.target.value)}
        helperText={helperText?.name}
        helperTextClass="error-text-nick-name"
      />
      <Input
        label={t('email')}
        placeholder={email}
        marginBottom="24px"
        editable={false}
      />
      <Input
        label={t('current_password')}
        type="simpleInput"
        marginBottom="24px"
        onIconClick={onIconClick}
        editable={false}
        placeholder="********"
      />
      <Select
        label={t('language')}
        options={LANGUAGES_OPTIONS}
        marginBottom="24px"
        value={formData.language}
        onChange={(value: string) => setFormData?.({ ...formData, language: value })}
      />
      <Input
        name="street"
        display="block"
        labelPopover={t('sender_address')}
        labelPopoverContent={t('sender_address_info')}
        placeholder={t('street')}
        value={formData?.senderAddress?.street}
        onChange={(e: any) => handleInputChange('senderAddress.street', e.target.value)}
        helperText={helperText?.senderAddress?.street}
        marginBottom="24px"
      />
      <Input
        name="postCode"
        display="block"
        placeholder={t('post_code')}
        value={formData?.senderAddress?.postCode}
        onChange={(e: any) => handleInputChange('senderAddress.postCode', e.target.value)}
        helperText={helperText?.senderAddress?.postCode}
        marginBottom="24px"
      />
      <Input
        name="city"
        display="block"
        placeholder={t('city')}
        value={formData?.senderAddress?.city}
        onChange={(e: any) => handleInputChange('senderAddress.city', e.target.value)}
        helperText={helperText?.senderAddress?.city}
        marginBottom="24px"
      />
      <div className="button-box">
        <Button
          text={t('cancel')}
          width="91px"
          type="default"
          onClick={() => {
            onCancel?.();
            setHelperText?.({ name: '' });
            setFormData?.({
              name: user?.name || '',
              language: user?.language || ''
            });
          }}
        />
        <Button
          text={t('update')}
          width="91px"
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </ConnectionWrapper>
  );
};

export default Profile;
