import React, { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { isEmpty } from 'lodash';

import {
  adminRouter,
  employeeRouter,
  customerRouter
} from './routes/private-routes';

import publicRoutes from './routes/public-routes';
import Notify from './components/notify';

// styles
import theme from './theme/theme-variables';
import GlobalStyles from './theme/global-style';

import './app.css';
import './font/font.css';

import { SetAuthState } from './redux/slices/auth';

import { AppDispatch } from './redux/store';

import { AuthState } from './redux/types/auth';

import {
  AddToLocalStorage,
  GetLocalStorageItem,
  RemoveLocalStorageItems
} from './utils/helpers';

import { setAuthToken } from './config/axios-configuration';

import { USER_ROLES } from './constants';

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const {
    authToken,
    user
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const currentUser = JSON.parse(GetLocalStorageItem('user') || '{}');

  const token = GetLocalStorageItem('token');

  setAuthToken(token || '');

  useEffect(() => {
    if (!isEmpty(authToken)) {
      AddToLocalStorage(
        ['token', authToken],
        ['user', JSON.stringify(user)]
      );

      RemoveLocalStorageItems(['expiredToken', 'setPasswordExpiredToken']);

      window.location.href = '/campaigns';
    }
    if (!isEmpty(token)) {
      const decodedToken = jwtDecode(token as string);
      const currentTime = parseInt((Date.now() / 1000).toFixed(0), 10);

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        RemoveLocalStorageItems(['token', 'user']);

        dispatch(SetAuthState({
          field: 'authToken',
          value: ''
        }));

        dispatch(SetAuthState({
          field: 'user',
          value: null
        }));

        window.location.href = '/sign-in';
      }
    }

    if (user === null && !isEmpty(GetLocalStorageItem('user') as string)) {
      dispatch(SetAuthState({
        field: 'user',
        value: JSON.parse(GetLocalStorageItem('user') as string)
      }));
    }
  }, [authToken]);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        theme={{ token: { fontFamily: 'SF Pro Display, sans-serif' } }}
      >
        <GlobalStyles />
        <RouterProvider router={authToken || token ? (
          currentUser?.role === USER_ROLES.ADMIN ? adminRouter : (
            currentUser?.role === USER_ROLES.EMPLOYEE
              ? employeeRouter
              : currentUser?.role === USER_ROLES.CUSTOMER
                ? customerRouter : publicRoutes
          )
        ) : publicRoutes}
        />
        <Notify />
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
