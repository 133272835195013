import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';
import ExcelJS from 'exceljs';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  AddAutoCampaignPayload,
  AddCampaignPayload,
  Campaign,
  CampaignIdPayload,
  CampaignState,
  CloneCampaignPayload,
  GetAllCampaignsData,
  ReadOneTimeCampaignFilePayload,
  UpdateOneTimeCampaignPayload
} from '../types/campaign';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: CampaignState = {
  addCampaign: {},
  addCampaignLoading: false,
  deleteCampaignLoading: false,
  getAllCampaignsSuccess: false,
  loading: false,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  success: false,
  currentCampaign: {},
  newCampaign: {},
  isCampaignAdded: false,
  isCampaignDeleted: false,
  isCampaignMarkedAsCanBeStarted: false,
  markAsCanBeStartedLoading: false,
  campaigns: [],
  campaignsList: null,
  templatesList: null,
  totalCampaigns: 0,
  getCampaignLoading: false,
  startCampaignLoading: false,
  blockCampaignLoading: false,
  isCampaignBlocked: false,
  isCampaignStarted: false,
  cloneCampaignLoading: false,
  isCampaignCloned: false,
  isCampaignUpdated: false,
  clonedCampaign: null,
  updatedCampaign: {}
};

export const AddAutoCampaign = createAsyncThunk(
  'campaign/add-auto-campaign',
  async (data: AddAutoCampaignPayload, { rejectWithValue }) => {
    try {
      const { newCampaign } = data;

      const response = await axios.post('/campaign/add-auto-campaign', {
        ...newCampaign
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const AddManualCampaign = createAsyncThunk(
  'campaign/add-one-time-campaign-manually',
  async (data: AddCampaignPayload, { rejectWithValue }) => {
    try {
      const { addCampaign } = data;

      const response = await axios.post('/campaign/add-one-time-campaign-manually', {
        addCampaign
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const BlockCampaign = createAsyncThunk(
  'campaign/block-campaign',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;

      const response = await axios.post('/campaign/block-campaign', {
        campaignId
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const CloneCampaign = createAsyncThunk(
  'campaign/clone-campaign',
  async (data: CloneCampaignPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId,
        name
      } = data;

      const response = await axios.post('/campaign/clone-campaign', {
        campaignId,
        name
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const CompleteCampaign = createAsyncThunk(
  'campaign/complete-campaign',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;

      const response = await axios.post('/campaign/complete-campaign', {
        campaignId
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const DeleteCampaignById = createAsyncThunk(
  'campaign/delete-campaign',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;

      const response = await axios.delete('/campaign/delete-campaign', {
        data: {
          campaignId
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetAllCampaigns = createAsyncThunk(
  'campaign/get-all-campaigns',
  async (data: GetAllCampaignsData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;
      const response = await axios.get('/campaign/get-all-campaigns', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetCampaignsList = createAsyncThunk(
  'campaign/get-campaigns-list',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/campaign/get-campaigns-list');

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const GetCampaignById = createAsyncThunk(
  'campaign/get-campaign-by-id',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;
      const response = await axios.get('/campaign/get-campaign-by-id', {
        params: {
          campaignId
        }

      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const MarkCampaignAsCanBeStarted = createAsyncThunk(
  'campaign/mark-campaign-as-can-be-started',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;

      const response = await axios.patch('/campaign/mark-campaign-as-can-be-started', {
        campaignId
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const PauseOrStopAutoCampaign = createAsyncThunk(
  'campaign/pause-or-stop-auto-campaign',
  async (data: {
    campaignId: string,
    status: string
  }, { rejectWithValue }) => {
    try {
      const newCampaign = data;

      const response = await axios.post('/campaign/pause-or-stop-auto-campaign', {
        ...newCampaign
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const ReadOneTimeCampaignFile = createAsyncThunk(
  'campaign/read-one-time-campaign-file',
  async (data: ReadOneTimeCampaignFilePayload, { rejectWithValue }) => {
    try {
      const { campaignName, fileKey } = data;

      const getQueryString = (params: ReadOneTimeCampaignFilePayload) => {
        const esc = encodeURIComponent;
        return Object.entries(params)
          .map(([key, value]) => `${esc(key)}=${esc(value)}`)
          .join('&');
      };

      const queryString = getQueryString({ campaignName, fileKey });
      const url = `${process.env.REACT_APP_API_URL}/auth/read-one-time-campaign-file?${queryString}`;

      const response = await axios.get(url, {
        responseType: 'json'
      });

      const { data: responseData } = response;

      if (responseData.success && responseData.data && responseData.data.content) {
        const fileContent = responseData.data.content;
        const fileName = `${campaignName}.${fileKey.split('.').pop()}`;
        let fileBlob = new Blob([fileContent], { type: 'text/csv' });

        if (fileName.includes('xlsx')) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet1');

          const csvRows = fileContent.split('\n').map((row: any) => row.split(','));
          csvRows.forEach((row: any, rowIndex: any) => {
            row.forEach((cell: any, colIndex: any) => {
              worksheet.getCell(rowIndex + 1, colIndex + 1).value = cell.trim();
            });
          });

          const buffer = await workbook.xlsx.writeBuffer();
          fileBlob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
        }

        const fileUrl = window.URL.createObjectURL(fileBlob);

        // Create a temporary link element for downloading
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up the Blob URL and remove the temporary link
        window.URL.revokeObjectURL(fileUrl);
        link.parentNode?.removeChild(link);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const StartCampaign = createAsyncThunk(
  'campaign/start-campaign',
  async (data: CampaignIdPayload, { rejectWithValue }) => {
    try {
      const {
        campaignId
      } = data;

      const response = await axios.post('/campaign/start-campaign', {
        campaignId
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const UpdateAutoCampaign = createAsyncThunk(
  'campaign/update-auto-campaign',
  async (data: AddAutoCampaignPayload, { rejectWithValue }) => {
    try {
      const { newCampaign } = data;

      const response = await axios.post('/campaign/update-auto-campaign', {
        ...newCampaign
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

export const UpdateOneTimeCampaign = createAsyncThunk(
  'campaign/update-one-time-campaign',
  async (data: UpdateOneTimeCampaignPayload, { rejectWithValue }) => {
    try {
      const {
        filterParams,
        updateParams
      } = data;

      const response = await axios.post('/campaign/update-one-time-campaign', {
        filterParams,
        updateParams
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(HandleCatchBlock(err));
    }
  }
);

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    SetCampaignState(state, action: PayloadAction<{
      field: keyof CampaignState;
      value: CampaignState[keyof CampaignState]
    }>) {
      const updateCampaign = <T extends keyof CampaignState>(field: T, value: CampaignState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateCampaign(field, value as CampaignState[keyof CampaignState]);
    },
    SetCampaignNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddAutoCampaign.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(AddAutoCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = action.payload.data.isDraft;
        state.isCampaignAdded = true;
        state.newCampaign = action.payload.data.newCampaign;
      })
      .addCase(AddAutoCampaign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(AddManualCampaign.pending, (state) => {
        state.success = false;
        state.addCampaignLoading = true;
      })
      .addCase(AddManualCampaign.fulfilled, (state, action) => {
        state.addCampaignLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignAdded = true;
        state.newCampaign = action.payload.data.newCampaign;
      })
      .addCase(AddManualCampaign.rejected, (state, action) => {
        state.addCampaignLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(BlockCampaign.pending, (state) => {
        state.isCampaignBlocked = false;
        state.blockCampaignLoading = true;
      })
      .addCase(BlockCampaign.fulfilled, (state, action) => {
        state.blockCampaignLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignBlocked = true;
      })
      .addCase(BlockCampaign.rejected, (state, action) => {
        state.blockCampaignLoading = false;
        state.isCampaignBlocked = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(CloneCampaign.pending, (state) => {
        state.success = false;
        state.cloneCampaignLoading = true;
      })
      .addCase(CloneCampaign.fulfilled, (state, action) => {
        state.cloneCampaignLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
        state.isCampaignCloned = true;
        state.clonedCampaign = action.payload.data.clonedCampaign;
      })
      .addCase(CloneCampaign.rejected, (state, action) => {
        state.cloneCampaignLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(CompleteCampaign.pending, (state) => {
        state.success = false;
        state.startCampaignLoading = true;
      })
      .addCase(CompleteCampaign.fulfilled, (state, action) => {
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignStarted = true;
        state.startCampaignLoading = false;
      })
      .addCase(CompleteCampaign.rejected, (state, action) => {
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.startCampaignLoading = false;
        state.isCampaignStarted = false;
      })
      .addCase(DeleteCampaignById.pending, (state) => {
        state.success = false;
        state.deleteCampaignLoading = true;
      })
      .addCase(DeleteCampaignById.fulfilled, (state, action) => {
        state.deleteCampaignLoading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignDeleted = true;
      })
      .addCase(DeleteCampaignById.rejected, (state, action) => {
        state.deleteCampaignLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetAllCampaigns.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.getAllCampaignsSuccess = false;
        state.notify = false;
      })
      .addCase(GetAllCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllCampaignsSuccess = true;
        state.success = true;
        const payload = action.payload.data as { campaigns: Campaign[], totalCampaigns: number } | undefined;
        if (payload) {
          state.campaigns = payload.campaigns;
          state.totalCampaigns = payload.totalCampaigns;
        }
        state.notify = false;
      })
      .addCase(GetAllCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.getAllCampaignsSuccess = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetCampaignsList.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.getAllCampaignsSuccess = false;
        state.notify = false;
      })
      .addCase(GetCampaignsList.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.campaignsList = action.payload.data.campaignsList;
        state.templatesList = action.payload.data.templatesList;
        state.notify = false;
      })
      .addCase(GetCampaignsList.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(GetCampaignById.pending, (state) => {
        state.success = false;
        state.getCampaignLoading = true;
        state.currentCampaign = {};
      })
      .addCase(GetCampaignById.fulfilled, (state, action) => {
        state.getCampaignLoading = false;
        state.success = true;
        state.newCampaign = action.payload.data.campaign;
        state.currentCampaign = action.payload.data.campaign;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = false;
      })
      .addCase(GetCampaignById.rejected, (state, action) => {
        state.getCampaignLoading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.currentCampaign = {};
      })
      .addCase(MarkCampaignAsCanBeStarted.pending, (state) => {
        state.isCampaignMarkedAsCanBeStarted = false;
        state.markAsCanBeStartedLoading = true;
      })
      .addCase(MarkCampaignAsCanBeStarted.fulfilled, (state, action) => {
        state.markAsCanBeStartedLoading = false;
        state.isCampaignMarkedAsCanBeStarted = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(MarkCampaignAsCanBeStarted.rejected, (state, action) => {
        state.markAsCanBeStartedLoading = false;
        state.isCampaignMarkedAsCanBeStarted = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(PauseOrStopAutoCampaign.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(PauseOrStopAutoCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignUpdated = true;
        state.updatedCampaign = action.payload.data.updatedCampaign;
      })
      .addCase(PauseOrStopAutoCampaign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(ReadOneTimeCampaignFile.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(ReadOneTimeCampaignFile.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(ReadOneTimeCampaignFile.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(StartCampaign.pending, (state) => {
        state.success = false;
        state.startCampaignLoading = true;
      })
      .addCase(StartCampaign.fulfilled, (state, action) => {
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignStarted = true;
        state.startCampaignLoading = false;
      })
      .addCase(StartCampaign.rejected, (state, action) => {
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.startCampaignLoading = false;
        state.isCampaignStarted = false;
      })
      .addCase(UpdateAutoCampaign.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(UpdateAutoCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignUpdated = true;
        state.updatedCampaign = action.payload.data.updatedCampaign;
      })
      .addCase(UpdateAutoCampaign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateOneTimeCampaign.pending, (state) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(UpdateOneTimeCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notifyMessage = action.payload.message;
        state.notifyType = 'success';
        state.notify = true;
        state.isCampaignUpdated = true;
        state.updatedCampaign = action.payload.data.updatedCampaign;
      })
      .addCase(UpdateOneTimeCampaign.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || '';
        }
        state.notifyType = 'error';
        state.notify = true;
      });
  }
});

const { actions } = campaignSlice;

export const { SetCampaignState, SetCampaignNotifyState } = actions;

export default campaignSlice.reducer;
