import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router';
import {
  Layout,
  Button,
  Popover,
  Tooltip
} from 'antd';
import moment from 'moment';
import {
  extend,
  isEmpty
} from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import Drawer from '../../components/drawer';
import Switch from '../../components/switch';
import Connection from './drawer/connections';
import Profile from './drawer/profile';
import TypePassword from './drawer/type-password';
import UpdatePassword from './drawer/update-password';

import HeaderCollapseClose from '../../assets/icons/header-collapse.svg';
import Thunder from '../../assets/icons/thunder.svg';
import HeaderInfo from '../../assets/icons/header-info.svg';
import BellIcon from '../../assets/icons/bell-icon.svg';
import HeaderNotification from '../../assets/icons/notification.svg';
import BasicCredit from '../../assets/images/basic-ohne-schatten.png';
import PlusCredit from '../../assets/images/plus-ohne-schatten.png';
import BrandingCredit from '../../assets/images/branding-ohne-schatten.png';

import {
  GetUserById,
  SetAuthState
} from '../../redux/slices/auth';
import {
  GetAllNotifications,
  MarkNotificationsAsRead,
  SetNotificationState
} from '../../redux/slices/notification';
import {
  SetUserState,
  UpdateUser
} from '../../redux/slices/user';
import {
  GetRightToUpdateProfileSettings,
  SetProfileState,
  UpdateProfileSettings
} from '../../redux/slices/profile';

import { AppDispatch } from '../../redux/store';

import { SetStoreState } from '../../redux/slices/store';
import { ProfileState } from '../../redux/types/profile';
import { AuthState } from '../../redux/types/auth';
import {
  NotificationState,
  Notification
} from '../../redux/types/notification';
import { StoreState } from '../../redux/types/store';
import { UserState } from '../../redux/types/user';

import {
  DecryptCredentials,
  EncryptCredentials,
  GetLocalStorageItem,
  HandleLogout,
  RemoveLocalStorageItems
} from '../../utils/helpers';
import { CHOOSE_PLAN_VALUES_HEADERS } from '../../constants';

const { Header: AntdHeader } = Layout;

interface FormData {
  language: string;
  name: string;
  senderAddress: {
    street: string;
    postCode: string;
    city: string;
  }
}

interface HeaderProps {
  collapsed: boolean;
  onToggleCollapse: () => void;
}

interface UpdateCredentialsHelperTextState {
  email: string;
  password: string;
}

const NotificationPopoverTitle = ({
  onToggle,
  isToggled,
  setIsToggled,
  isNotificationPopoverOpened
}: {
  onToggle: () => void;
  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
  isNotificationPopoverOpened: boolean;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const hasMounted = useRef(true);
  const isInitialMount = useRef(true);

  const {
    updateUserSuccess,
    user
  } = useSelector((state: { user: UserState }) => state.user);

  const {
    isNotificationPopoverOpenedBySidebar,
    notifications
  } = useSelector((state: { notification: NotificationState }) => state.notification);

  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    if (hasMounted.current) {
      hasMounted.current = false;
    } else if (currentUser) {
      dispatch(UpdateUser({
        userId: currentUser._id,
        updateParams: {
          notification: {
            ...user?.notification,
            showNotifications: isToggled
          }
        }
      }));
    }
  }, [isToggled]);

  useEffect(() => {
    if (isNotificationPopoverOpened || isNotificationPopoverOpenedBySidebar) {
      dispatch(MarkNotificationsAsRead({
        notificationIds: notifications.map((notification) => notification._id)
      }));
    }
  }, []);

  useEffect(() => {
    setIsToggled(user?.notification?.showNotifications as boolean);
  }, [user]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (updateUserSuccess) {
      dispatch(SetUserState({
        field: 'user',
        value: {
          ...user,
          notification: {
            ...user?.notification,
            showNotifications: isToggled
          }
        }
      }));
    }
  }, [updateUserSuccess]);

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="popover-title">
        <span>{t('notifications')}</span>
      </div>
      <Switch
        onChange={onToggle}
        value={isToggled}
      />
    </div>
  );
};

const Header: React.FC<HeaderProps> = ({ collapsed, onToggleCollapse }) => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const token = GetLocalStorageItem('token');

  const {
    success,
    user
  } = useSelector((state: { auth: AuthState }) => state.auth);

  const {
    updateUserSuccess,
    user: userState
  } = useSelector((state: { user: UserState }) => state.user);

  const {
    isNotificationPopoverOpenedBySidebar,
    notifications,
    totalNotifications,
    unreadNotificationsCount
  } = useSelector((state: { notification: NotificationState }) => state.notification);

  const {
    isCurrentPasswordValid,
    isProfileUpdated,
    isProfilePopoverOpenedBySidebar,
    isProfileSettingsActive
  } = useSelector((state: { profile: ProfileState }) => state.profile);

  const { isConnectionsPopoverOpenedBySidebar } = useSelector((state: { store: StoreState }) => state.store);

  const [connection, setConnection] = useState(false);
  const [profile, setProfile] = useState(false);
  const [typePassword, setTypePassword] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [addStoreDrawer, setAddStoreDrawer] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    name: userState?.name || '',
    language: userState?.language || '',
    senderAddress: {
      street: userState?.senderAddress?.street || '',
      postCode: userState?.senderAddress?.postCode || '',
      city: userState?.senderAddress?.city || ''
    }
  });
  const [helperText, setHelperText] = useState<{
    name: string,
    senderAddress: {
      street: string;
      postCode: string;
      city: string;
    }
  }>();
  const [updateCredentialsHelperText, setUpdateCredentialsHelperText] = useState<UpdateCredentialsHelperTextState>({
    email: '',
    password: ''
  });
  const [password, setPassword] = useState<string>('');
  const [updatedEmail, setUpdatedEmail] = useState<string>(DecryptCredentials(userState?.email || ''));
  const [updatedPassword, setUpdatedPassword] = useState<string>('');
  const [basicPlan, setBasicPlan] = useState<any>({});
  const [brandedPlan, setBrandedPlan] = useState<any>({});
  const [maxiPlan, setMaxiPlan] = useState<any>({});
  const [isToggled, setIsToggled] = useState(userState?.notification?.showNotifications as boolean);
  const [isNotificationPopoverOpened, setIsNotificationPopoverOpened] = useState(false);
  const [currentNotifications, setCurrentNotifications] = useState<Notification[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [localSkip, setLocalSkip] = useState(0);
  const scrollableDivRef = useRef(null);

  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  const [nameInitials, setNameInitials] = useState(currentUser?.name?.split(' ').map(
    (n: string) => n[0]
  ).join('').slice(0, 2)
    .toUpperCase() || 'A');

  const infoPopover = (
    <div>
      <p>Some information goes here.</p>
    </div>
  );

  const FetchNotifications = () => {
    dispatch(GetAllNotifications({
      skip: localSkip,
      limit: 5
    }));
  };

  const HandleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      const { scrollTop, clientHeight, scrollHeight } = div;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        if (localSkip <= totalNotifications && hasMore) {
          setLocalSkip(localSkip + 5);
        } else {
          setHasMore(false);
        }
      }
    }
  };

  const notificationPopover = (
    <div className="notification-popover" ref={scrollableDivRef}>
      {currentNotifications
        .sort((a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime())
        .map((notification, key) => (
          <div key={key} className="notification-box">
            <img src={BellIcon} alt="no-bell-icon" />
            <div className="d-flex flex-column info">
              <span className="title">{notification?.notificationHeading}</span>
              <span className="description">
                {notification?.message?.length > 180 ? (
                  <Tooltip title={notification?.message}>
                    {notification?.message?.substring(0, 180)}
                    ...
                  </Tooltip>
                ) : (
                  notification?.message
                )}
              </span>
              <span className="time">{moment(notification?.createdAt).format('MMM DD, h:mm A')}</span>
            </div>
          </div>
        ))}

    </div>
  );
  const hasInfo = false;
  const hasNotification = true;

  const onCancelUpdateProfile = () => {
    if (isProfilePopoverOpenedBySidebar) {
      dispatch(SetProfileState({
        field: 'isProfilePopoverOpenedBySidebar',
        value: false
      }));
    }

    if (isProfileSettingsActive) {
      dispatch(SetProfileState({
        field: 'isProfileSettingsActive',
        value: false
      }));
    }

    setProfile(false);
    setConnection(false);
    setAddStoreDrawer(false);
  };

  const getRightToUpdateProfile = () => {
    const encryptedPassword = EncryptCredentials(password);

    dispatch(GetRightToUpdateProfileSettings({ password: encryptedPassword }));
  };

  const updateNameAndLanguage = () => {
    const updateParams: Partial<FormData> = { senderAddress: formData?.senderAddress };

    if (formData.name !== userState?.name) {
      extend(updateParams, { name: formData.name });
    }

    if (formData.language !== userState?.language) {
      extend(updateParams, { language: formData.language });
    }

    if (formData.senderAddress.city !== userState?.senderAddress?.city) {
      extend(updateParams, {
        senderAddress: {
          ...updateParams?.senderAddress,
          city: formData.senderAddress.city
        }
      });
    }

    if (formData.senderAddress.street !== userState?.senderAddress?.street) {
      extend(updateParams, {
        senderAddress: {
          ...updateParams?.senderAddress,
          street: formData.senderAddress.street
        }
      });
    }

    if (formData.senderAddress.postCode !== userState?.senderAddress?.postCode) {
      extend(updateParams, {
        senderAddress: {
          ...updateParams?.senderAddress,
          postCode: formData.senderAddress.postCode
        }
      });
    }

    dispatch(UpdateUser({
      userId: currentUser._id,
      updateParams
    }));
  };

  const updateProfileSettings = () => {
    const encryptedEmail = EncryptCredentials(updatedEmail);
    const encryptedPassword = EncryptCredentials(updatedPassword);

    const updateParams = {};

    if (updatedEmail !== userState?.email) {
      extend(updateParams, { email: encryptedEmail });
    }

    if (updatedPassword) {
      extend(updateParams, { newPassword: encryptedPassword });
    }

    dispatch(UpdateProfileSettings({ updateParams }));

    setUpdatedPassword('');
    setUpdatePassword(false);

    dispatch(SetProfileState({
      field: 'isProfileSettingsActive',
      value: false
    }));

    dispatch(SetProfileState({
      field: 'isProfilePopoverOpenedBySidebar',
      value: false
    }));
  };

  useEffect(() => {
    const div = scrollableDivRef.current as HTMLDivElement | null;
    div?.addEventListener('scroll', HandleScroll);
    return () => {
      div?.removeEventListener('scroll', HandleScroll);
    };
  }, [localSkip, scrollableDivRef.current]);

  useEffect(() => {
    if (hasMore) {
      FetchNotifications();
    }
  }, [localSkip]);

  useEffect(() => {
    if (notifications.length === 0) {
      setHasMore(false);
    } else {
      // filter unique notifications
      setCurrentNotifications((prevNotifications) => {
        const uniqueNotifications = notifications.filter((notification) => (
          prevNotifications.findIndex((prevNotification) => prevNotification._id === notification._id) === -1
        ));

        return [...prevNotifications, ...uniqueNotifications];
      });
      setHasMore(true);

      if (isNotificationPopoverOpened || isNotificationPopoverOpenedBySidebar) {
        dispatch(MarkNotificationsAsRead({
          notificationIds: notifications.map((notification) => notification._id)
        }));
      }
    }
  }, [
    notifications,
    isNotificationPopoverOpened,
    isNotificationPopoverOpenedBySidebar
  ]);

  useEffect(() => {
    setIsNotificationPopoverOpened(false);
  }, [location.pathname]);

  useEffect(() => {
    setNameInitials(currentUser?.name?.split(' ').map(
      (n: string) => n[0]
    ).join('').slice(0, 2)
      .toUpperCase() || 'A');

    if (userState) {
      const basic = userState?.allPurchasedPlans?.find((obj: any) => obj.planType === 'Basic');
      const branded = userState?.allPurchasedPlans?.find((obj: any) => obj.planType === 'Branding');
      const maxi = userState?.allPurchasedPlans?.find((obj: any) => obj.planType === 'Maxi');

      setBasicPlan(basic);
      setBrandedPlan(branded);
      setMaxiPlan(maxi);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isCurrentPasswordValid) {
      setPassword('');
      setTypePassword(false);
      setUpdatePassword(true);
    }
  }, [isCurrentPasswordValid]);

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(GetUserById(currentUser?._id));
    }
  }, [isProfileUpdated]);

  useEffect(() => {
    if (success) {
      HandleLogout(user);
    }
  }, [success]);

  // Logout User on Click of Profile Icon if
  // Password is Updated
  // or Token is Expired
  useEffect(() => {
    if (connection) {
      const userId = JSON.parse(GetLocalStorageItem('user') as string)?._id;
      dispatch(GetUserById(userId));

      if (!isEmpty(token)) {
        const decodedToken = jwtDecode(token as string);
        const currentTime = parseInt((Date.now() / 1000).toFixed(0), 10);

        if (decodedToken.exp && decodedToken.exp < currentTime) {
          RemoveLocalStorageItems(['token', 'user']);

          dispatch(SetAuthState({
            field: 'authToken',
            value: ''
          }));

          dispatch(SetAuthState({
            field: 'user',
            value: null
          }));

          window.location.href = '/sign-in';
        }
      }

      if (user) {
        HandleLogout(user);
      }
    }
  }, [connection]);

  useEffect(() => {
    if (updateUserSuccess) {
      onCancelUpdateProfile();
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    if (userState) {
      setFormData({
        name: userState?.name || '',
        language: userState?.language || '',
        senderAddress: {
          street: userState?.senderAddress?.street || '',
          postCode: userState?.senderAddress?.postCode || '',
          city: userState?.senderAddress?.city || ''
        }
      });

      setUpdatedEmail(DecryptCredentials(userState?.email || ''));
    }
  }, [userState]);

  return (
    <>
      <AntdHeader>
        <Button
          type="text"
          icon={collapsed
            ? <img src={HeaderCollapseClose} alt={t('sidebar_collapse_icon')} />
            : <img src={HeaderCollapseClose} alt={t('sidebar_collapse_icon')} />}
          onClick={onToggleCollapse}
          className="collapse-btn"
        />
        <div className="header-right">
          <span className="header-icon-label">
            <img className="label-icon" src={Thunder} alt={t('thunder')} />
            {`${t('credits')}:`}
          </span>
          <div className="credits-counter">
            <img className="credit-coin" src={BasicCredit} alt="no-svg" />
            <span>
              {(basicPlan?.credits || 0) - (basicPlan?.creditsUsed || 0)}
            </span>
            <img className="credit-coin" src={BrandingCredit} alt="no-svg" />
            <span>
              {(brandedPlan?.credits || 0) - (brandedPlan?.creditsUsed || 0)}
            </span>
            <img className="credit-coin" src={PlusCredit} alt="no-svg" />
            <span>
              {(maxiPlan?.credits || 0) - (maxiPlan?.creditsUsed || 0)}
            </span>
          </div>
          <div className="header-buttons">
            <Popover
              content={notificationPopover}
              title={(
                <NotificationPopoverTitle
                  onToggle={
                    () => setIsToggled(!isToggled)
                  }
                  isToggled={isToggled}
                  setIsToggled={setIsToggled}
                  isNotificationPopoverOpened
                />
                )}
              placement="bottomRight"
              trigger="click"
              open={isNotificationPopoverOpenedBySidebar || isNotificationPopoverOpened}
              onOpenChange={(visible) => {
                setIsNotificationPopoverOpened(visible);

                if (!visible) {
                  dispatch(SetNotificationState({
                    field: 'isNotificationPopoverOpenedBySidebar',
                    value: false
                  }));

                  dispatch(SetNotificationState({
                    field: 'isNotificationSettingsActive',
                    value: false
                  }));
                }
              }}
            >
              <Button className={`header-icon-btn ${hasNotification ? 'badge' : ''}`}>
                <img className="header-icon-btn-img" src={HeaderNotification} alt={t('notification')} />
                {unreadNotificationsCount > 0 && (
                  <p className="notification-badge">{unreadNotificationsCount}</p>
                )}
              </Button>
            </Popover>
            <div className="header-divider" />
            <Button
              className="header-avatar-btn"
              onClick={() => {
                setConnection(true);
                setAddStoreDrawer(true);
              }}
            >
              <h5>{nameInitials}</h5>
            </Button>
          </div>
        </div>
      </AntdHeader>
      <Drawer
        open={isConnectionsPopoverOpenedBySidebar || connection}
        width="455px"
        closable={false}
        changeHeight="change-connection-height"
      >
        <Connection
          onClose={() => {
            setConnection(false);
            setAddStoreDrawer(false);
            dispatch(SetStoreState({
              field: 'isConnectionsPopoverOpenedBySidebar',
              value: false
            }));
            dispatch(SetStoreState({
              field: 'isConnectionsSettingActive',
              value: false
            }));
          }}
          goProfile={() => {
            setProfile(true);
            setConnection(false);
          }}
          addStoreDrawer={addStoreDrawer}
        />
      </Drawer>
      <Drawer
        open={isProfilePopoverOpenedBySidebar || profile}
        width="455px"
        onClose={() => {
          setProfile(false);
          setConnection(true);
          setAddStoreDrawer(true);
          dispatch(SetProfileState({
            field: 'isProfilePopoverOpenedBySidebar',
            value: false
          }));
          dispatch(SetProfileState({
            field: 'isProfileSettingsActive',
            value: false
          }));
        }}
        title={t('profile')}
        changeHeight="change-profile-height"
      >
        <Profile
          formData={formData}
          setFormData={setFormData}
          helperText={helperText}
          setHelperText={setHelperText}
          onCancel={onCancelUpdateProfile}
          onIconClick={() => {
            setProfile(false);
            setTypePassword(true);

            dispatch(SetProfileState({
              field: 'isProfilePopoverOpenedBySidebar',
              value: false
            }));
          }}
          onUpdate={updateNameAndLanguage}
        />
      </Drawer>
      <Drawer open={typePassword} width="345px" closable={false} changeHeight="change-type-height">
        <TypePassword
          password={password}
          setPassword={setPassword}
          onCancel={() => {
            setTypePassword(false);
            setPassword('');
          }}
          onUpdate={getRightToUpdateProfile}
        />
      </Drawer>
      <Drawer
        open={updatePassword}
        width="345px"
        onClose={() => {
          setUpdatePassword(false);
          setProfile(true);
          setTypePassword(false);
          setConnection(false);
          setUpdatedPassword('');
        }}
        title="Update Profile"
        changeHeight="change-update-height"
      >
        <UpdatePassword
          email={updatedEmail}
          setEmail={setUpdatedEmail}
          password={updatedPassword}
          setPassword={setUpdatedPassword}
          onCancel={() => {
            setPassword('');
            setUpdatePassword(false);
          }}
          onUpdate={updateProfileSettings}
          helperText={updateCredentialsHelperText}
          setHelperText={setUpdateCredentialsHelperText}
        />
      </Drawer>
    </>
  );
};

export default Header;
