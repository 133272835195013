/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Container from '../../../components/custom-stepper/style';
import StepsData from '../../../components/custom-stepper/stepsData';
import ChoosePlan from './component/choose-plan';
import Advertising from './component/advertising-format';
import LetterType from './component/choose-letter-type';
import HandWritten from './component/handwritten';
import ChooseEnvelope from './component/choose-envelope';
import InsertMaxi from './component/insert-maxi';
import Summary from './component/summary';
import Congratulations from './component/congratulations';
import Loader from '../../../components/loader';
import Button from '../../../components/button';
import CheckModal from '../../../components/check-modal';

import WarningIcon from '../../../assets/icons/warning.svg';

import {
  AddTemplate,
  AddTemplateSheet,
  GetTemplateById,
  SetTemplateState
} from '../../../redux/slices/template';
import { GetAllMaxiInserts } from '../../../redux/slices/maxi';
import { SetUserState } from '../../../redux/slices/user';

import { AppDispatch } from '../../../redux/store';

import {
  AddTemplate as AddTemplateInterface,
  AddTemplateSheet as AddTemplateSheetInterface,
  HelperText,
  TemplateState
} from '../../../redux/types/template';
import { FileState } from '../../../redux/types/files';
import { UserState } from '../../../redux/types/user';

import {
  ADD_TEMPLATE_INITIAL_STATE,
  ADVERTISEMENT_FORMAT_VALUES,
  CHOOSE_PLAN_VALUES,
  LETTER_STYLE_VALUES,
  PLAN_TYPES,
  PLAN_TYPES_DB,
  PRINTED_INITIAL_OBJECT,
  TEMPLATE_SHEETS_INITIAL_STATE
} from '../../../constants';

interface AddTemplateProps {
  templateAction: string;
  setTemplateAction?: (value: string) => void;
  editTemplateId?: string | null;
  setEditTemplateId: (value: string) => void;
  addTemplate: AddTemplateInterface,
  setAddTemplate: React.Dispatch<React.SetStateAction<AddTemplateInterface>>,
  templateSheets: AddTemplateSheetInterface[];
  setTemplateSheets: React.Dispatch<React.SetStateAction<AddTemplateSheetInterface[]>>
  updateTemplateById: (isDraft?: boolean) => void;
  setVideoPlayerModal: (value: boolean) => void;
  setVideoPlayerModalLink: (value: string) => void;
}

const AddTemplateComponent: React.FC<AddTemplateProps> = ({
  templateAction,
  setTemplateAction,
  editTemplateId,
  setEditTemplateId,
  addTemplate,
  setAddTemplate,
  templateSheets,
  setTemplateSheets,
  updateTemplateById,
  setVideoPlayerModal,
  setVideoPlayerModalLink
}) => {
  // const isMounted = useRef(true);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    getTemplateLoading,
    isTemplateAdded,
    isTemplateSaved,
    isTemplateSheetAdded,
    isTemplateUpdated,
    loading,
    newTemplate,
    template,
    templates,
    templateSheets: templateSheetsData
  } = useSelector((state: { template: TemplateState }) => state.template);

  const { remainingPagesBuffer } = useSelector((state: { file: FileState }) => state.file);
  const { user } = useSelector((state: { user: UserState }) => state.user);
  const [step, setStep] = useState<number>(0);
  const [addTemplateHelperText, setAddTemplateHelperText] = useState<HelperText>({});
  const [templateSheetsHelperText, setTemplateSheetsHelperText] = useState<HelperText[]>([]);
  const [callGetMaxiInsertsAPI, setCallGetMaxiInsertsAPI] = useState<boolean>(false);
  const [selectHelperText, setSelectHelperText] = useState<string | undefined>('');
  const [openTemplateSheetsLimitPopup, setOpenTemplateSheetsLimitPopup] = useState<boolean>(false);
  const [characterLimitExceeds, setCharacterLimitExceeds] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningModalDescription, setWarningModalDescription] = useState<string>('');
  const [buyCreditsClicked, setBuyCreditsClicked] = useState(false);
  const [openPopupForFirstTemplate, setOpenPopupForFirstTemplate] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);

  const stepsData = [{
    content: (
      <>
        <p>{t('step_1')}</p>
        <p>{t('choose_plan')}</p>
      </>
    )
  }, {
    // content: (
    //   <>
    //     <p>{t('step_2')}</p>
    //     <p>{t('choose_format')}</p>
    //   </>
    // )
  }, {
  //   content: (
  //     <>
  //       <p>{t('step_3')}</p>
  //       <p>{t('letter_type')}</p>
  //     </>
  //   )
  }, {
    content: (
      <>
        {/* <p>{t('step_4')}</p> */}
        <p>{t('step_2')}</p>
        <p>{t('create_letter')}</p>
      </>
    )
  }, {
  //   content: (
  //     <>
  //       <p>{t('step_5')}</p>
  //       <p>{t('envelope')}</p>
  //     </>
  //   )
  }, {
    content: (
      <>
        {/* <p>{t('step_6')}</p> */}
        <p>{t('step_3')}</p>
        <p>{t('summary')}</p>
      </>
    )
  }];

  const maxiStepsData = [{
    content: (
      <>
        <p>{t('step_1')}</p>
        <p>{t('choose_plan')}</p>
      </>
    )
  }, {
    // content: (
    //   <>
    //     <p>{t('step_2')}</p>
    //     <p>{t('choose_format')}</p>
    //   </>
    // )
  }, {
    // content: (
    //   <>
    //     <p>{t('step_3')}</p>
    //     <p>{t('choose_insert')}</p>
    //   </>
    // )
  }, {
    // content: (
    //   <>
    //     <p>{t('step_4')}</p>
    //     <p>{t('letter_type')}</p>
    //   </>
    // )
  }, {
    content: (
      <>
        {/* <p>{t('step_5')}</p> */}
        <p>{t('step_2')}</p>
        <p>{t('create_letter')}</p>
      </>
    )
  }, {
    // content: (
    //   <>
    //     <p>{t('step_6')}</p>
    //     <p>{t('envelope')}</p>
    //   </>
    // )
  }, {
    content: (
      <>
        {/* <p>{t('step_7')}</p> */}
        <p>{t('step_3')}</p>
        <p>{t('summary')}</p>
      </>
    )
  }];

  const [selectedSheet, setSelectedSheet] = useState<number>(0);

  const validateTemplateName = () => {
    if (addTemplate.name === '') {
      setAddTemplateHelperText({ name: 'Template Name is required!' });
    }
  };

  const getTemplateById = () => {
    // if (isMounted.current) {
    //   isMounted.current = false;
    //   return;
    // }

    if (editTemplateId && !isEmpty(editTemplateId)) {
      dispatch(GetTemplateById({ templateId: editTemplateId }));
    }
  };

  const handleStepFour = () => {
    let firstErrorIndex = -1;
    const errors: Partial<Record<string, string>>[] = templateSheets.map((sheet, index) => {
      const sheetErrors: Partial<Record<string, string>> = {};

      // if (sheet?.sheetType === LETTER_TYPE_VALUES.HANDWRITTEN) {
      //   if (sheet?.font === '') {
      //     sheetErrors.font = 'Font is required!';
      //   }
      // }

      if (!sheet?.s3FileUrl) {
        if (!sheet?.message) {
          sheetErrors.message = sheet?.sheetType === 'Handwritten'
            ? t('message_required')
            : t('file_required');
          if (firstErrorIndex === -1) {
            firstErrorIndex = index;
          }
        }
      }

      return sheetErrors;
    });

    const hasErrors = errors.some((error) => Object.keys(error).length > 0);

    if (!hasErrors) {
      // if (addTemplate.letterStyle === LETTER_STYLE_VALUES.BASIC) {
      //   if (addTemplate.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi) {
      //     setStep(step + 1);
      //   } else if (addTemplate?.plan === CHOOSE_PLAN_VALUES.BASIC) {
      //     setStep(step + 2);
      //   } else {
      //     setStep(step + 1);
      //   }
      // } else {
      //   setStep(step + 1);
      // }
      setStep(step + 2);
    } else {
      if (firstErrorIndex > -1) {
        setSelectedSheet(firstErrorIndex);
      }

      setTemplateSheetsHelperText((prevHelperText: HelperText[]) => errors
        .map((sheetErrors, index) => ({ ...prevHelperText[index], ...sheetErrors })));
    }
  };

  const chooseMaxiInsertHandler = (
    maxiInsertId: string
  ) => {
    if (!isEmpty(maxiInsertId)) {
      setSelectHelperText?.('');
      setStep(step + 1);
    } else {
      setSelectHelperText?.('Please select an insert');
    }
  };

  const resetValues = () => {
    setTemplateAction?.('');
    setAddTemplate(ADD_TEMPLATE_INITIAL_STATE);
    setStep(0);
    setSelectedSheet(0);
  };

  const draftTemplate = () => {
    if (!isEmpty(editTemplateId) && user?.role !== 'employee') {
      updateTemplateById(true);
    } else if (addTemplate.advertisementFormat !== '' && user?.role !== 'employee') {
      if (templateSheets?.length && (templateSheets.filter((sheet) => sheet.message !== '').length)) {
        dispatch(AddTemplate({
          addTemplate: {
            ...addTemplate,
            countOfSheets: templateSheets?.length
            && (templateSheets.filter((sheet) => sheet.message !== '').length
            + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length),
            isDraft: true
          }
        }));
      }

      resetValues();
    }
  };

  const handleBack = () => {
    if (step === 0) {
      if (!isEmpty(editTemplateId) && user?.role !== 'employee') {
        updateTemplateById(true);
      } else if (addTemplate.advertisementFormat !== '' && user?.role !== 'employee') {
        if (templateSheets?.length && (templateSheets.filter((sheet) => sheet.message !== '').length)) {
          dispatch(AddTemplate({
            addTemplate: {
              ...addTemplate,
              countOfSheets: templateSheets?.length
              && (templateSheets.filter((sheet) => sheet.message !== '').length
              + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length),
              isDraft: true
            }
          }));
        }

        resetValues();
      }

      setTemplateAction?.('');
      dispatch(SetTemplateState({
        field: 'addTemplate',
        value: {}
      }));
    } else if (step === 6) {
      if (addTemplate.letterStyle === LETTER_STYLE_VALUES.BASIC
        || addTemplate.letterStyle === LETTER_STYLE_VALUES.BRANDED) {
        setStep(step - 2);
      }
    } else if ((addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step === 4)
        // || (addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
        //   && step === 3)) {
        || (step === 3)) {
      const filteredSheets = templateSheets.filter((sheet) => (sheet.message !== '' || sheet?.s3FileUrl));

      setTemplateSheets(filteredSheets);

      setSelectedSheet(0);

      if (addTemplate?.plan === CHOOSE_PLAN_VALUES.BIG) {
        setStep(step - 4);
        // if (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi) setStep(step - 3);
        // else setStep(step - 2);
      } else {
        setStep(step - 3);
      }
    } else if ((step === 2 && addTemplate.plan !== CHOOSE_PLAN_VALUES.BIG)
      // if ((step === 2 && addTemplate.plan === CHOOSE_PLAN_VALUES.BASIC)
      || (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step === 6
        && addTemplate.plan === CHOOSE_PLAN_VALUES.BASIC)
      || (addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step === 5)
      // && addTemplate.plan === CHOOSE_PLAN_VALUES.BASIC)
    ) {
      setStep(step - 2);
    } else {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step === 0) {
      const choosenPlanData = user?.allPurchasedPlans?.find(
        (obj: any) => obj.planType === PLAN_TYPES_DB[addTemplate?.plan || '']
      );

      if (
        (choosenPlanData?.credits || 0) - (choosenPlanData?.creditsUsed || 0) < 0
        || !((choosenPlanData?.credits || 0) - (choosenPlanData?.creditsUsed || 0))
      ) {
        setWarningModal(true);
        setWarningModalDescription(t('selection_does_not_match_credits_description'));
        return;
      }

      // if (!((((addTemplate?.plan === CHOOSE_PLAN_VALUES.BASIC
      //   && user?.purchasedBundle?.planType === PLAN_TYPES.BASIC)
      // || (addTemplate?.plan === CHOOSE_PLAN_VALUES.BRANDING
      //   && user?.purchasedBundle?.planType === PLAN_TYPES.BRANDING)
      // || (addTemplate?.plan === CHOOSE_PLAN_VALUES.BIG
      //   && user?.purchasedBundle?.planType === PLAN_TYPES.MAXI)))
      //   && ((choosenPlanData?.credits || 0) - (choosenPlanData?.creditsUsed || 0) > 0))) {
      // setWarningModal(true);
      // setWarningModalDescription(t('selection_does_not_match_credits_description'));
      // return;
      // }

      setWarningModal(false);
      setWarningModalDescription('');

      if (templateSheets
        && !templateSheets.length) { // if there is no sheet, add one.
        setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
      }
      // if (addTemplate.plan === CHOOSE_PLAN_VALUES.BASIC) {
      if (addTemplate.plan === CHOOSE_PLAN_VALUES.BIG) {
        setStep(step + 4); // Go to CreateLetter step skipping AdvertisementFormat and LetterType steps.
        return;
      }

      if (addTemplate.plan !== CHOOSE_PLAN_VALUES.BIG) {
        setStep(step + 3);
        return;
      }
    }

    // if (step === 1 && addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi) {
    //   setCallGetMaxiInsertsAPI(true);
    // }

    if (step === 2 && addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi) {
      chooseMaxiInsertHandler(addTemplate?.maxiInsertId as string);
      return;
    }

    if ((step === 2 && addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi)
      || (step === 3 && addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi)) {
      if (templateSheets.length === 0) {
        if (addTemplate?.letterType === 'Printed') {
          setTemplateSheets([PRINTED_INITIAL_OBJECT]);
        } else {
          setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
        }
      }
    }

    if (step === 1) {
      if (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi) {
        setStep(step + 3);
      } else {
        setStep(step + 2);
      }
      if (templateSheets && !templateSheets.length) { // if there is no sheet, add one.
        setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
      }
    } else if ((step === 3 && addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi)
      || (step === 4 && addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi)) {
      handleStepFour();
    } else if ((addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
      && step === 5)
      || (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step === 6
      )) {
      validateTemplateName();
      if (!isEmpty(addTemplate?.name)) {
        if (!isEmpty(editTemplateId)) {
          updateTemplateById();
        } else {
          dispatch(AddTemplate({
            addTemplate: {
              ...addTemplate,
              envelopeColor: addTemplate.envelopeColor,
              countOfSheets: templateSheets?.length
              && (templateSheets.filter((sheet) => sheet.message !== '').length
              + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length)
            }
          }));
        }

        setStep(step + 1);
      }
    } else {
      setStep(step + 1);
    }
  };

  const handleDisable = () => {
    let disabled = false;

    if (characterLimitExceeds) disabled = true;

    if (step === 0 && !addTemplate?.plan) disabled = true;

    if (remainingPagesBuffer) disabled = true;

    else if (step === 1 && !addTemplate?.advertisementFormat) disabled = true;
    else if (step === 2) {
      if (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && !addTemplate?.maxiInsertId) disabled = true;
      else if (addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
        && !addTemplate?.letterType) disabled = true;
    } else if (step === 3) {
      if (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && !addTemplate?.letterType) disabled = true;

      else if (addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
      // && templateSheets?.length
      && (!templateSheets.find((sheet) => sheet.s3FileUrl)
      && !templateSheets.filter((sheet) => sheet.message !== '').length)) disabled = true;
    } else if (step === 4) {
      if (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && templateSheets?.length
          && templateSheets.filter((sheet) => sheet.message !== '').length === 0) disabled = true;

      else if (addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
        && ((!addTemplate?.envelopeColor && addTemplate?.plan !== CHOOSE_PLAN_VALUES.BASIC)
        || (addTemplate?.plan === CHOOSE_PLAN_VALUES.BASIC && !addTemplate?.name))) disabled = true;
    } else if ((addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
      && step === 5)
      || (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step === 6)) {
      if (addTemplate?.plan === CHOOSE_PLAN_VALUES.BASIC) {
        if (!addTemplate?.name) disabled = true;
      }
    }

    return disabled;
  };

  const handleVideoPlayer = () => {
    setVideoPlayerModal(true);

    if (user?.language === 'en') setVideoPlayerModalLink('https://www.youtube.com/watch?v=topBLaz4zgk');
    else if (user?.language === 'de') setVideoPlayerModalLink('https://www.youtube.com/watch?v=r9os9Q6t6Xc&t=3694s');
  };

  const handleBuyCreditsClick = () => {
    if (!isEmpty(editTemplateId)) {
      updateTemplateById();
    } else {
      dispatch(AddTemplate({
        addTemplate: {
          ...addTemplate,
          envelopeColor: addTemplate.envelopeColor,
          countOfSheets: templateSheets?.length
          && (templateSheets.filter((sheet) => sheet.message !== '').length
          + templateSheets?.filter((sheet) => !isEmpty(sheet.s3FileUrl)).length)
        }
      }));
    }
  };

  useEffect(() => {
    if (isTemplateSaved || isTemplateUpdated) {
      dispatch(SetTemplateState({
        field: 'templates',
        value: []
      }));
    }
  }, [isTemplateSaved, isTemplateUpdated]);

  useEffect(() => {
    if (!isEmpty(templateAction)) {
      setStep(0);
      setSelectedSheet(0);

      if (templateAction === 'edit') {
        setAddTemplate(templates?.find((item) => item?._id === editTemplateId)
          || ADD_TEMPLATE_INITIAL_STATE);
      }
      setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
      setAddTemplateHelperText({});
      setTemplateSheetsHelperText([]);
    } else if (isEmpty(templateAction)) {
      resetValues();
      setEditTemplateId('');
      dispatch(SetTemplateState({
        field: 'template',
        value: null
      }));
    }
  }, [templateAction]);

  useEffect(() => {
    if (isTemplateAdded) {
      if (user?.firstTemplateCreated === false) {
        setOpenPopupForFirstTemplate(true);
        setWarningModalDescription(t('first_template_created_text'));
      }
      if (newTemplate?._id) {
        const sheets: AddTemplateSheetInterface[] = [];
        templateSheets.forEach((obj: any) => {
          const {
            message = '',
            s3FileUrl = '',
            variables = []
          } = obj;

          const regex = /\(\((.*?)\)\)/g;
          const matches = message.match(regex) || [];

          const values = matches.map((match: any) => match.slice(2, -2).trim());

          if (message || !isEmpty(s3FileUrl)) {
            sheets.push({
              ...obj,
              templateId: newTemplate?._id,
              variables: values.length ? values : variables
            });
          }
        });

        if (sheets.length > 0) {
          dispatch(AddTemplateSheet({
            templateSheets: sheets
          }));
        }
        dispatch(SetTemplateState({
          field: 'isTemplateAdded',
          value: false
        }));
      }
    }
    if (isTemplateSheetAdded) {
      setStep(addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? 7 : 6);
      dispatch(SetTemplateState({
        field: 'isTemplateSheetAdded',
        value: false
      }));
    }
    if (isTemplateUpdated) {
      setStep(addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? 7 : 6);
      dispatch(SetTemplateState({
        field: 'isTemplateUpdated',
        value: false
      }));
    }
  }, [isTemplateAdded,
    isTemplateSheetAdded,
    isTemplateUpdated]);

  useEffect(() => {
    if (!isEmpty(editTemplateId)) {
      getTemplateById();
    }
  }, [editTemplateId]);

  useEffect(() => {
    if (!isEmpty(template)) {
      const {
        name,
        plan,
        advertisementFormat,
        letterStyle,
        letterType,
        envelopeColor,
        envelopeFormat,
        maxiInsertId
      } = template || {};

      setAddTemplate({
        name,
        plan,
        advertisementFormat,
        letterStyle,
        letterType,
        envelopeColor,
        envelopeFormat,
        maxiInsertId
      });

      const filteredData = templateSheetsData?.map((item) => ({
        _id: item?._id,
        sheetType: item?.sheetType,
        templateId: item?.templateId,
        templateName: item?.templateName,
        fileKey: item?.fileKey,
        font: item?.font,
        logoSize: item?.logoSize,
        logoUrl: item?.logoUrl,
        message: item?.message,
        qrCode: item?.qrCode,
        variables: item?.variables,
        s3FileUrl: item?.s3FileUrl
      }));
      setTemplateSheets(filteredData);
    }
  }, [template]);

  useEffect(() => {
    if (callGetMaxiInsertsAPI) {
      dispatch(GetAllMaxiInserts({}));
      setCallGetMaxiInsertsAPI(false);
    }
  }, [callGetMaxiInsertsAPI]);

  useEffect(() => {
    if (isTemplateSheetAdded) {
      setTemplateSheets(TEMPLATE_SHEETS_INITIAL_STATE);
    }

    dispatch(SetTemplateState({
      field: 'isTemplateSheetAdded',
      value: false
    }));
  }, [isTemplateSheetAdded]);

  useEffect(() => {
    if ((isTemplateSheetAdded || isTemplateUpdated) && buyCreditsClicked) {
      navigate('/payment');
    }
  }, [isTemplateSheetAdded, isTemplateUpdated]);

  return (
    <Container minWidth="330px">
      {getTemplateLoading && <Loader />}
      <div className="container">
        <ul className="stepper-li">
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? maxiStepsData.map((obj, index) => (
            !isEmpty(obj) && (
              <li
                key={index}
                className={`${step === index
                  ? 'current'
                  : step > index
                    ? 'completed'
                    : ''}`}
              >
                <div className="content">{obj.content}</div>
              </li>
            )
          )) : stepsData.map((obj, index) => (
            !isEmpty(obj) && (
              <li
                key={index}
                className={`${step === index
                  ? 'current'
                  : step > index
                    ? 'completed'
                    : ''}`}
              >
                <div className="content">{obj.content}</div>
              </li>
            )
          ))}
        </ul>
      </div>
      {(!step || step === 0) && (
        <StepsData>
          <ChoosePlan
            addTemplate={addTemplate}
            handleNext={handleNext}
            setAddTemplate={setAddTemplate}
            templateAction={templateAction}
            handleComparePlanClick={handleBack}
            setDisableNext={setDisableNext}
          />
        </StepsData>
      )}
      {step === 1 && (
        <StepsData>
          <Advertising
            addTemplate={addTemplate}
            handleNext={handleNext}
            setAddTemplate={setAddTemplate}
          />
        </StepsData>
      )}
      {step === 2 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            <InsertMaxi
              selectHelperText={selectHelperText}
              setSelectHelperText={setSelectHelperText}
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
            />
          ) : (
            <LetterType
              handleNext={handleNext}
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              templateSheets={templateSheets}
              setTemplateSheets={setTemplateSheets}
              templateSheetsHelperText={templateSheetsHelperText}
              setTemplateSheetsHelperText={setTemplateSheetsHelperText}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
            />
          )}
        </StepsData>
      )}
      {step === 3 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            <LetterType
              handleNext={handleNext}
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              templateSheets={templateSheets}
              setTemplateSheets={setTemplateSheets}
              templateSheetsHelperText={templateSheetsHelperText}
              setTemplateSheetsHelperText={setTemplateSheetsHelperText}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
            />
          ) : (
            <HandWritten
              handleNext={handleNext}
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setTemplateSheets={setTemplateSheets}
              templateSheetsHelperText={templateSheetsHelperText}
              setTemplateSheetsHelperText={setTemplateSheetsHelperText}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              openTemplateSheetsLimitPopup={openTemplateSheetsLimitPopup}
              setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
              setCharacterLimitExceeds={setCharacterLimitExceeds}
              setAddTemplate={setAddTemplate}
              draftTemplate={draftTemplate}
              setBuyCreditsClicked={setBuyCreditsClicked}
            />

          )}
        </StepsData>
      )}
      {step === 4 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            // <LetterType
            //   handleNext={handleNext}
            //   addTemplate={addTemplate}
            //   setAddTemplate={setAddTemplate}
            //   setTemplateSheets={setTemplateSheets}
            //   templateSheetsHelperText={templateSheetsHelperText}
            //   setTemplateSheetsHelperText={setTemplateSheetsHelperText}
            //   selectedSheet={selectedSheet}
            //   setSelectedSheet={setSelectedSheet}
            //   setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
            // />
            <HandWritten
              handleNext={handleNext}
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setTemplateSheets={setTemplateSheets}
              templateSheetsHelperText={templateSheetsHelperText}
              setTemplateSheetsHelperText={setTemplateSheetsHelperText}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              openTemplateSheetsLimitPopup={openTemplateSheetsLimitPopup}
              setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
              setCharacterLimitExceeds={setCharacterLimitExceeds}
              setAddTemplate={setAddTemplate}
              draftTemplate={draftTemplate}
              setBuyCreditsClicked={setBuyCreditsClicked}
            />
          ) : addTemplate?.plan !== CHOOSE_PLAN_VALUES.BASIC ? (
            <ChooseEnvelope
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              handleNext={handleNext}
            />
          ) : (
            <Summary
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setStep={setStep}
              setSelectedSheet={setSelectedSheet}
              addTemplateHelperText={addTemplateHelperText}
              setAddTemplateHelperText={setAddTemplateHelperText}
              setAddTemplate={setAddTemplate}
              handleBuyCreditsClick={handleBuyCreditsClick}
            />
          )}
        </StepsData>
      )}
      {/* {step === 4 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            <HandWritten
              handleNext={handleNext}
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setTemplateSheets={setTemplateSheets}
              templateSheetsHelperText={templateSheetsHelperText}
              setTemplateSheetsHelperText={setTemplateSheetsHelperText}
              selectedSheet={selectedSheet}
              setSelectedSheet={setSelectedSheet}
              openTemplateSheetsLimitPopup={openTemplateSheetsLimitPopup}
              setOpenTemplateSheetsLimitPopup={setOpenTemplateSheetsLimitPopup}
            />
          ) : (
            <ChooseEnvelope
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              handleNext={handleNext}
            />
          )}
        </StepsData>
      )} */}
      {step === 5 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            <ChooseEnvelope
              addTemplate={addTemplate}
              setAddTemplate={setAddTemplate}
              handleNext={handleNext}
            />
          ) : (
            <Summary
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setStep={setStep}
              setSelectedSheet={setSelectedSheet}
              addTemplateHelperText={addTemplateHelperText}
              setAddTemplateHelperText={setAddTemplateHelperText}
              setAddTemplate={setAddTemplate}
              handleBuyCreditsClick={handleBuyCreditsClick}
            />
          )}
        </StepsData>
      )}
      {loading && <Loader />}
      {step === 6 && (
        <StepsData>
          {addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi ? (
            <Summary
              addTemplate={addTemplate}
              templateSheets={templateSheets}
              setStep={setStep}
              setSelectedSheet={setSelectedSheet}
              addTemplateHelperText={addTemplateHelperText}
              setAddTemplateHelperText={setAddTemplateHelperText}
              setAddTemplate={setAddTemplate}
              handleBuyCreditsClick={handleBuyCreditsClick}
            />
          ) : (
            <Congratulations
              templateAction={templateAction}
              setTemplateAction={setTemplateAction}
              editTemplateId={editTemplateId || null}
              addTemplate={addTemplate}
            />
          )}
        </StepsData>
      )}
      {step === 7 && (
        <StepsData>
          <Congratulations
            templateAction={templateAction}
            setTemplateAction={setTemplateAction}
            editTemplateId={editTemplateId || null}
            addTemplate={addTemplate}
          />
        </StepsData>
      )}
      {((addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
        && step < 6)
        || (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
          && step < 7))
        && (
        <div className="d-flex justify-content-end flex-end align-items-end drawer-buttons-wrapper-ui">
          {/* <Button
            type="primary"
            onClick={() => handleVideoPlayer()}
            text={t('tutorial_link_text')}
          /> */}
          <div className="d-flex drawer-button drawer-buttons-wrapper">
            <Button
              width="100px"
              text={step === 0 ? t('cancel') : t('back_button')}
              onClick={handleBack}
              type="default"
            />
            <Button
              width="112px"
              text={((addTemplate?.advertisementFormat !== ADVERTISEMENT_FORMAT_VALUES.Maxi
                && step === 5)
                || (addTemplate?.advertisementFormat === ADVERTISEMENT_FORMAT_VALUES.Maxi
                  && step === 6))
                ? t('complete_button')
                : t('next_button')}
              type="primary"
              onClick={handleNext}
              disabled={handleDisable() || disableNext}
            />
          </div>
        </div>
        )}

      <CheckModal
        heading={t('insufficient_letter_credits')}
        desc={warningModalDescription}
        open={warningModal}
        // onCancel={() => {
        //   // if (addTemplate.plan === CHOOSE_PLAN_VALUES.BASIC) {
        //   if (addTemplate.plan !== CHOOSE_PLAN_VALUES.BIG) {
        //     setStep(step + 3);
        //   } else {
        //     setStep(step + 4);
        //   }
        //   setWarningModal(false);
        // }}
        onCancel={() => {
          setDisableNext(true);
          setWarningModal(false);
        }}
        onConfirm={() => {
          setWarningModal(false);
          navigate('/payment');
        }}
        icon={<img src={WarningIcon} alt={t('no_icon')} />}
        okayText={t('ok')}
        confirmText={t('buy_credits_button')}
      />

      <CheckModal
        heading={t('first_template_created_heading')}
        desc={warningModalDescription}
        open={openPopupForFirstTemplate}
        onCancel={() => {
          setOpenPopupForFirstTemplate(false);
          dispatch(SetUserState({
            field: 'user',
            value: {
              ...user,
              firstTemplateCreated: true
            }
          }));
        }}
        onConfirm={() => {
          window.open('https://brief-adler.de/kostenloses-exemplar', '_blank');
          setOpenPopupForFirstTemplate(false);
          dispatch(SetUserState({
            field: 'user',
            value: {
              ...user,
              firstTemplateCreated: true
            }
          }));
        }}
        icon={<img src={WarningIcon} alt={t('no_icon')} />}
        okayText={t('no')}
        confirmText={t('get_sample')}
      />
    </Container>
  );
};

export default AddTemplateComponent;
