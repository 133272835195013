import React, {
  ChangeEvent,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button/index';
import Input from '../../../components/input/index';

import { ConnectionWrapper } from './style';

import { ApplyValidation } from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

interface TypePasswordProps {
  password: string;
  setPassword: (password: string) => void;
  onCancel?:()=> void;
  onUpdate?:()=>void;
}

interface TypePasswordDataState {
  password: string;
}

interface TypePasswordHelperTextState {
  password: string;
}

const TypePassword: React.FC<TypePasswordProps> = ({
  password,
  setPassword,
  onCancel,
  onUpdate
}) => {
  const { t } = useTranslation();
  const [helperText, setHelperText] = useState<TypePasswordHelperTextState>({
    password: ''
  });

  const validateField = (
    fieldName: keyof TypePasswordDataState,
    value: string
  ): Partial<TypePasswordHelperTextState> => {
    const errors: Partial<TypePasswordHelperTextState> = {};

    if (value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'password' && !ApplyValidation(REGEX_PATTERNS.LENGTH_REGEX, value)) {
      errors[fieldName] = 'Enter at least 8 characters!';
    } else {
      errors[fieldName] = '';
    }
    return errors;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const errors = validateField('password', value);

    if (errors.password) {
      setHelperText({
        password: errors.password
      });
    } else {
      setHelperText({
        password: ''
      });
    }

    setPassword(value);
  };

  const handleSubmit = () => {
    const errors = validateField('password', password);

    if (errors.password) {
      setHelperText({
        password: errors.password
      });
      return;
    }

    onUpdate?.();
  };

  return (
    <ConnectionWrapper>
      <Input
        label={t('current_password')}
        type="password"
        marginBottom="24px"
        placeholder="Enter your current password"
        onChange={handleChange}
        value={password}
        helperText={helperText.password}
      />
      <div className="button-box">
        <Button
          text={t('cancel')}
          width="91px"
          type="default"
          onClick={() => {
            onCancel?.();
            setHelperText({
              password: ''
            });
            setPassword('');
          }}
        />
        <Button
          text="Confirm"
          width="91px"
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </ConnectionWrapper>
  );
};

export default TypePassword;
