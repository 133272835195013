import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Row,
  Col,
  Space
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import Upload from '../../../components/upload';
import Loader from '../../../components/loader';
import ModalUpload from '../../../components/modal';
import Progress from '../../../components/progress';
import Document from '../../../assets/icons/document-text.svg';
import TickCircle from '../../../assets/icons/tick-circle.svg';
import Trash from '../../../assets/icons/trash-1.svg';

import {
  GetS3PreSignedUrl,
  SetOtherState,
  SetOtherNotifyState
} from '../../../redux/slices/other';
import {
  SetProductState,
  UploadBulkNicknames
} from '../../../redux/slices/product';

import { AppDispatch } from '../../../redux/store';

import { OtherState } from '../../../redux/types/other';
import { ProductState } from '../../../redux/types/product';

import {
  CheckFileExtension,
  DownloadSampleFile,
  ExtractHeadersFromFile,
  ExtractRowsFromFile,
  FormatColumnName,
  UploadDocumentOnS3,
  ValidateHeaders
} from '../../../utils/helpers';

import {
  BUCKET_NAME_VALUES,
  FILES_EXTENSION_LIST,
  NICKNAMES_SAMPLE_SHEET_COLUMNS,
  SAMPLE_FILE_NAMES
} from '../../../constants';

interface HelperText {
  [key: string]: string | undefined;
}

interface ModalProps {
  onCancel: () => void;
  open?: boolean;
  uploadNickname?: boolean;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  onCancel, open, uploadNickname, title
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    preSignedUrl,
    preSignedUrlLoading,
    fileUploadKey
  } = useSelector((state: { other: OtherState }) => state.other);

  const {
    addBulkNicknamesFileLoading,
    isBulkNicknamesFileAdded
  } = useSelector((state: { product: ProductState }) => state.product);

  const [progress, setProgress] = useState<number>(0);
  const [attachment, setAttachment] = useState<any | undefined>(undefined);
  const [fileKey, setFileKey] = useState<string>('');
  const [uploadFileHelperText, setUploadFileHelperText] = useState<HelperText>({});

  const uploadBulkNicknames = () => {
    dispatch(UploadBulkNicknames({ fileKey }));
  };

  const handleUpload = () => {
    const uploadErrors: Partial<Record<string, string>> = {};

    if (fileKey === '') {
      uploadErrors.fileKey = t('file_is_required');
    }

    const hasErrors = Object.keys(uploadErrors).length > 0;
    if (!hasErrors) {
      uploadBulkNicknames();
    } else {
      setUploadFileHelperText(uploadErrors);
    }
  };

  const handleChangeAttachment = async (info: UploadChangeParam, uploadBucket: string) => {
    setProgress(0);
    setFileKey('');
    const { file } = info;
    if (!file?.status) {
      const { name } = file;
      const extension = name.split('.').pop();

      const notValidExtension = CheckFileExtension({
        extension: extension as string,
        extensionList: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? FILES_EXTENSION_LIST : [],
        attachmentType: BUCKET_NAME_VALUES.campaign_files === uploadBucket ? 'files' : ''
      });
      if (notValidExtension) {
        dispatch(SetOtherNotifyState({
          message: notValidExtension,
          type: 'error'
        }));
        return;
      }

      const headers = await ExtractHeadersFromFile(file);
      const formattedColumns = NICKNAMES_SAMPLE_SHEET_COLUMNS.map((column) => FormatColumnName(column));
      const isValidHeaders = ValidateHeaders(headers, formattedColumns);

      if (!isValidHeaders) {
        dispatch(SetOtherNotifyState({
          message: t('error_in_sheet_header_format'),
          type: 'error'
        }));
        return;
      }

      const rows = await ExtractRowsFromFile(file);

      const nickNameColumnIndex = formattedColumns.indexOf('Nick Name');
      const invalidNickName = rows.some((row) => {
        let nickName = row[nickNameColumnIndex];

        if (!nickName) return false;

        nickName = nickName.replace(/\r/g, '').trim();

        if (nickName.length < 2) {
          dispatch(SetOtherNotifyState({
            message: t('error_in_sheet_nick_name_2_characters'),
            type: 'error'
          }));

          return true;
        }

        if (nickName.length > 25) {
          dispatch(SetOtherNotifyState({
            message: t('error_in_sheet_nick_name_25_characters'),
            type: 'error'
          }));

          return true;
        }

        return false;
      });

      if (invalidNickName) return;

      setAttachment(file);
      const fileName = name.split('.')[0];
      const formattedFileName = fileName.replace(/\s/g, '-');
      dispatch(GetS3PreSignedUrl({
        fileName: `${formattedFileName}-${moment().format('YYYY-MM-DD HH:mm:ss')}.${extension}`,
        fileType: file.type || '',
        fileExtension: extension || '',
        uploadBucket,
        id: ''
      }));
    }
  };

  const handleUploadAttachmentOnS3 = async () => {
    if (attachment) {
      try {
        await UploadDocumentOnS3({
          preSignedUrl,
          fileToUpload: attachment,
          onProgress: (prog: number) => {
            setProgress(Math.round(prog));
          }
        });
      } catch (error) {
        dispatch(SetOtherNotifyState({
          message: t('file_uploading_failed_on_S3'),
          type: 'error'
        }));
      }
    }
  };

  const resetValues = () => {
    setProgress(0);
    onCancel();
    setFileKey('');
    setUploadFileHelperText({});
  };

  const handleDelete = () => {
    if (progress === 100) {
      setFileKey('');
    }
  };

  useEffect(() => {
    if (preSignedUrl !== '') {
      if (!isEmpty(fileKey)) {
        dispatch(SetOtherNotifyState({
          message: t('file_replaced_message'),
          type: 'success'
        }));
      }
      if (!isEmpty(fileUploadKey)) {
        setFileKey(fileUploadKey);
        setUploadFileHelperText({ fileKey: '' });
      }
      handleUploadAttachmentOnS3();
      dispatch(SetOtherState({
        field: 'preSignedUrl',
        value: ''
      }));
    }
  }, [preSignedUrl]);

  useEffect(() => {
    if (isBulkNicknamesFileAdded) {
      resetValues();
      SetProductState({
        field: 'isBulkNicknamesFileAdded',
        value: false
      });
    }
  }, [isBulkNicknamesFileAdded]);

  return (
    <ModalUpload
      footer
      cancelText={t('cancel')}
      onCancelClick={resetValues}
      onSubmitClick={handleUpload}
      saveText={t('upload')}
      width={513}
      open={open}
      title={title || t('upload_bulk_nicknames')}
      downloadSampleFile={uploadNickname && t('download_sample_file')}
      downloadSampleFileClick={() => {
        DownloadSampleFile({
          type: 'product',
          mandatoryColumns: NICKNAMES_SAMPLE_SHEET_COLUMNS,
          sampleFileName: SAMPLE_FILE_NAMES.NICKNAMES_SAMPLE_FILE_NAME
        });
      }}
    >
      {(preSignedUrlLoading || addBulkNicknamesFileLoading) && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Upload
            handleChangeAttachment={(info: UploadChangeParam) => handleChangeAttachment(
              info,
              BUCKET_NAME_VALUES.campaign_files
            )}
          />
          {uploadFileHelperText.fileKey && (
            <div className="file-upload-helper-text">{uploadFileHelperText.fileKey}</div>
          )}
          {fileKey
            ? (
              <div className="progress-box">
                <div className="d-flex justify-content-between add-space">
                  <Space align="center">
                    <img src={Document} alt={t('no_document')} />
                    <span className="label">
                      {attachment?.name}
                    </span>
                  </Space>
                  <div
                    role="button"
                    tabIndex={0}
                    className="cursor-pointer"
                    onClick={handleDelete}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleDelete();
                      }
                    }}
                  >
                    <img
                      src={progress === 100 ? Trash : TickCircle}
                      alt={t('no_document')}
                    />
                  </div>
                </div>
                {progress !== 100 && <Progress percent={progress} />}
              </div>
            ) : null}
        </Col>
      </Row>
    </ModalUpload>
  );
};

export default Modal;
