import {
  createSlice,
  createAsyncThunk,
  PayloadAction
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

import {
  Customer,
  GetCustomersData,
  SubmitFeedbackPayload,
  UpdateUserPayload,
  UserState,
  UpdateCustomerStatusPayload
} from '../types/user';

import { HandleCatchBlock } from '../../utils/helpers';

const axios = axiosBaseUrl();

const initialState: UserState = {
  loading: false,
  error: null,
  notify: false,
  notifyMessage: '',
  notifyType: 'error',
  updateUserSuccess: false,
  user: {},
  getCurrentUserSuccess: false,
  success: false,
  totalCustomers: 0,
  customers: [],
  getCustomersSuccess: false,
  customerActionLoading: false,
  isCustomerEdited: false
};

export const GetCurrentUser = createAsyncThunk(
  'user/get-current-user',
  async (data: undefined, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/get-current-user');
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const GetCustomers = createAsyncThunk(
  'user/get-customers',
  async (data: GetCustomersData, { rejectWithValue }) => {
    try {
      const {
        filters,
        skip,
        limit,
        sortBy
      } = data;

      const response = await axios.get('/user/get-customers', {
        params: {
          filters: JSON.stringify(filters),
          skip,
          limit,
          sortBy
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const SubmitFeedback = createAsyncThunk(
  'user/submit-feedback',
  async (data: SubmitFeedbackPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/submit-feedback', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateCustomerStatus = createAsyncThunk(
  'user/update=customer-status',
  async (data: UpdateCustomerStatusPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/user/update=customer-status', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const UpdateUser = createAsyncThunk(
  'user/update-user',
  async (data: UpdateUserPayload, { rejectWithValue }) => {
    try {
      const response = await axios.patch('/user/update-user', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(HandleCatchBlock(error));
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SetUserState(state, action: PayloadAction<{
      field: keyof UserState;
      value: UserState[keyof UserState]
    }>) {
      const updateProduct = <T extends keyof UserState>(field: T, value: UserState[T]) => {
        state[field] = value;
      };
      const { field, value } = action.payload;
      updateProduct(field, value as UserState[keyof UserState]);
    },
    SetUserNotifyState(state, { payload: { message, type } }) {
      state.notify = true;
      state.notifyMessage = message;
      state.notifyType = type;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetCurrentUser.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
        state.getCurrentUserSuccess = false;
      })
      .addCase(GetCurrentUser.fulfilled, (state, action) => {
        state.error = null;
        state.user = action.payload.data.user;
        const payload = action.payload as { message?: string };
        state.loading = false;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.getCurrentUserSuccess = true;
      })
      .addCase(GetCurrentUser.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
        state.loading = false;
        state.getCurrentUserSuccess = false;
      })
      .addCase(GetCustomers.pending, (state) => {
        state.loading = true;
        state.getCustomersSuccess = false;
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(GetCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomersSuccess = true;
        state.success = true;
        state.error = null;
        const payload = action.payload.data as { customers: Customer[]; totalCustomers: number } | undefined;
        if (payload) {
          state.customers = payload.customers;
          state.totalCustomers = payload.totalCustomers;
        }
      })
      .addCase(GetCustomers.rejected, (state, action) => {
        state.loading = false;
        state.getCustomersSuccess = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(SubmitFeedback.pending, (state) => {
        state.error = null;
        state.success = false;
        state.notify = false;
      })
      .addCase(SubmitFeedback.fulfilled, (state, action) => {
        state.success = true;
        state.error = null;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
      })
      .addCase(SubmitFeedback.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateCustomerStatus.pending, (state) => {
        state.customerActionLoading = true;
        state.error = null;
        state.success = false;
        state.notify = false;
        state.isCustomerEdited = false;
      })
      .addCase(UpdateCustomerStatus.fulfilled, (state, action) => {
        state.customerActionLoading = false;
        state.success = true;
        state.error = null;
        state.isCustomerEdited = true;
        const payload = action.payload as { message?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.message || '';
        }
        state.notifyType = 'success';
        state.notify = true;
        state.customers = state.customers.map((customer) => {
          if (customer._id === action.payload.data.updatedCustomer._id) {
            return action.payload.data.updatedCustomer;
          }
          return customer;
        });
      })
      .addCase(UpdateCustomerStatus.rejected, (state, action) => {
        state.isCustomerEdited = false;
        state.customerActionLoading = false;
        state.error = action.payload as string;
        const payload = action.payload as { error?: string } | undefined;
        if (payload) {
          state.notifyMessage = payload.error || 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = true;
      })
      .addCase(UpdateUser.pending, (state) => {
        state.error = null;
        state.notify = false;
        state.loading = true;
        state.updateUserSuccess = false;
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.notify = false;
        state.updateUserSuccess = true;
        state.user = action.payload.data.updatedUser;
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.error = action.payload as string;
        const payload = action.payload as { error?: string };
        if (payload) {
          state.notifyMessage = 'An error occurred!';
        }
        state.notifyType = 'error';
        state.notify = false;
        state.loading = false;
        state.updateUserSuccess = false;
      });
  }
});

const { actions } = userSlice;

export const { SetUserState, SetUserNotifyState } = actions;

export default userSlice.reducer;
