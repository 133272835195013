import { thunk } from 'redux-thunk';

import logger from 'redux-logger';
import {
  configureStore,
  combineReducers
} from '@reduxjs/toolkit';
import {
  Middleware,
  AnyAction,
  Dispatch
} from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authSlice from './slices/auth';
import campaignSlice from './slices/campaign';
import dashboardSlice from './slices/dashboard';
import employeeSlice from './slices/employee';
import fileSlice from './slices/file';
import errorSlice from './slices/error';
import maxiInsertSlice from './slices/maxi';
import notificationSlice from './slices/notification';
import otherSlice from './slices/other';
import paymentPlanSlice from './slices/payment-plan';
import paymentSlice from './slices/payment';
import productSlice from './slices/product';
import profileSlice from './slices/profile';
import storeSlice from './slices/store';
import stripeSlice from './slices/stripe';
import templateSlice from './slices/template';
import userSlice from './slices/user';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['store', 'user']
};

const typedLogger: any = logger as Middleware<object, any, Dispatch<AnyAction>>;

const reducers = combineReducers({
  auth: authSlice,
  campaign: campaignSlice,
  dashboard: dashboardSlice,
  employee: employeeSlice,
  error: errorSlice,
  file: fileSlice,
  other: otherSlice,
  maxiInsert: maxiInsertSlice,
  notification: notificationSlice,
  paymentPlan: paymentPlanSlice,
  payment: paymentSlice,
  product: productSlice,
  profile: profileSlice,
  store: storeSlice,
  stripe: stripeSlice,
  template: templateSlice,
  user: userSlice
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/LogOut') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, typedLogger],
  devTools: true
});

export type AppDispatch = typeof store.dispatch;

export default store;
