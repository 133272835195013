import React from 'react';
import { useSelector } from 'react-redux';

import { CgBlock, CgUnblock } from 'react-icons/cg';

import { USER_STATUS } from '../../constants';
import { UserState } from '../../redux/types/user';

interface ActionButtonProps {
  employeeId?: string;
  setUnBlock?: (value: boolean) => void;
  setBlock?: (value: boolean) => void;
  setEmployeeId?: (value: string) => void;
}

const renderActionButton = (icon: React.ReactNode, onClick: () => void) => (
  <div
    role="button"
    className="mt-3"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    {icon}
  </div>
);

const ActionButtons: React.FC<ActionButtonProps> = ({
  employeeId,
  setUnBlock,
  setBlock,
  setEmployeeId
}) => {
  const { customers } = useSelector((state: { user: UserState }) => state.user);

  return (
    <div className="d-flex gap-1 align-items-center">
      {setBlock
      && customers?.find((customer) => customer._id === employeeId)?.status === USER_STATUS.ACTIVE
      && renderActionButton(
        <CgBlock className="pointer" size="20px" />,
        () => {
          setBlock(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
      {setUnBlock
      && customers?.find((customer) => customer._id === employeeId)?.status === USER_STATUS.BLOCKED
      && renderActionButton(
        <CgUnblock className="pointer" size="18px" />,
        () => {
          setUnBlock(true);
          if (setEmployeeId && employeeId) {
            setEmployeeId(employeeId);
          }
        }
      )}
    </div>
  );
};

export default ActionButtons;
