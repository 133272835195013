import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { FaStripe } from 'react-icons/fa';
import {
  isEmpty,
  sortBy
} from 'lodash';

import BackIcon from '../../assets/icons/close-icon-1.svg';
import CheckIcon from '../../assets/icons/payment-check-gold.svg';
import FaTimeIcon from '../../assets/icons/crose-icon-1.svg';
import InfoIcon from '../../assets/icons/chat-info-1.svg';

import ButtonComponent from '../../components/button';
import Input from '../../components/input';
import Loader from '../../components/loader';
import PriceCard from '../../components/price-card';
import Select from '../../components/select';

import {
  CreateSubscription,
  DirectPurchase
} from '../../redux/slices/payment';
import {
  CreateCustomer,
  SetStripeState
} from '../../redux/slices/stripe';
import { GetCurrentUser } from '../../redux/slices/user';

import { AppDispatch } from '../../redux/store';

import { StripeState } from '../../redux/types/stripe';

import { SubItem } from '../../utils/types/pages/payment-card';

import { COUNTRIES } from '../../constants';

interface PaymentCartProps {
  bundlePlanType?: string;
  basicTypePrice?: any;
  brandedTypePrice?: any;
  maxiTypePrice?: any
  bundleProductId?: string;
  selectedValue?: string;
  setBundleProductId: (value: string) => void;
  setSubscription: (value: boolean) => void;
  setIsPurchasedPlan: (value: boolean) => void;
  setPurchasedPlan: any;
}

interface CardValidationError {
  cvcError: string | undefined;
  dateError: string | undefined;
  numberError: string | undefined;
  cvcTouched: boolean;
  dateTouched: boolean;
  numberTouched: boolean;
}

interface AddressDataErrorProps {
  countryError: string | undefined;
  postalCodeError: string | undefined;
}

interface AddressDataProps {
  selectedCountry: string;
  postalCode: string;
}

interface CountriesDropdown {
  value: string | undefined;
  label: string | undefined;
}

const ExpiryOptions = {
  showIcon: true,
  placeholder: '123',
  style: {
    base: {
      color: '#9094A0',
      fontWeight: 300,
      ':-webkit-autofill': {
      },
      '::placeholder': {
        color: '#9094A0'
      }
    }
  }
};

const CardNumberOptions = {
  showIcon: true,
  placeholder: '0000 0000 0000 0000',
  style: {
    base: {
      color: '#9094A0',
      fontWeight: 300,
      ':-webkit-autofill': {
      },
      '::placeholder': {
        color: '#9094A0'
      }
    }
  }
};

const ExNumberOptions = {
  showIcon: true,
  placeholder: 'MM/YYY',
  style: {
    base: {
      color: '#9094A0',
      fontWeight: 300,
      ':-webkit-autofill': {
      },
      '::placeholder': {
        color: '#9094A0'
      }
    }
  }
};

const PaymentCart: React.FC<PaymentCartProps> = ({
  basicTypePrice,
  bundlePlanType,
  brandedTypePrice,
  maxiTypePrice,
  bundleProductId,
  selectedValue,
  setBundleProductId,
  setSubscription,
  setIsPurchasedPlan,
  setPurchasedPlan
}) => {
  const dispatch: AppDispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const {
    customerCreated,
    loading
  } = useSelector((state: { stripe: StripeState }) => state.stripe) || {};

  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [cardValidationError, setCardValidationError] = useState<CardValidationError>({
    cvcError: '',
    dateError: '',
    numberError: '',
    cvcTouched: false,
    dateTouched: false,
    numberTouched: false
  });
  const [addressError, setAddressError] = useState<AddressDataErrorProps>({
    countryError: '',
    postalCodeError: ''
  });
  const [addressData, setAddressData] = useState<AddressDataProps>({
    selectedCountry: '',
    postalCode: ''
  });
  const [allCountries, setAllCountries] = useState<CountriesDropdown[]>([{
    value: '',
    label: ''
  }]);

  const handleBackClick = () => {
    setSubscription(false);
  };

  const ITEMS: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: FaTimeIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }
  ];

  const ITEMS1: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: FaTimeIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const ITEMS2: SubItem[] = [{
    heading: t('features'),
    subItems: [{
      name: t('addressing_customer'),
      title: t('addressing_customer_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon

    }, {
      name: t('personalization'),
      title: t('personalization_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('variables'),
      title: t('variables_info'),
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('expert_view'),
      title: t('expert_view_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('print_pages'),
      title: t('print_pages_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('insert_magazines'),
      title: t('insert_magazines_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }, {
    heading: t('software_evaluation'),
    subItems: [{
      name: t('brief_adler_software'),
      title: t('brief_adler_software_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('fully_automated_processing'),
      title: `Enjoy a fully automated process with connecting
           your Seller Central via our API interface to take advantage
           of our automated letter distribution. All order data is automatically
           fetched and the letters are sent fully automated.
            Don't want integration? No problem, we also offer manual CSV file uploads of address data.`,
      imagePath: InfoIcon,
      descriptionImage: CheckIcon
    }, {
      name: t('filtering_and_cancellations'),
      title: t('filtering_and_cancellations_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('dashboard_quota'),
      title: t('dashboard_quota_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }, {
      name: t('qr_code_tracking'),
      title: t('qr_code_tracking_info'),
      descriptionImage: CheckIcon,
      imagePath: InfoIcon
    }]
  }];

  const handleName = (value: string | undefined) => {
    const name = value;

    if (name) {
      setNameError('');
    }
    setCardHolderName(name || '');
  };

  const handleCardValidationError = (e: any) => {
    const {
      elementType,
      error,
      complete,
      empty
    } = e;

    let cvcTouched = false;
    let dateTouched = false;
    let numberTouched = false;

    let cvcError = '';
    let dateError = '';
    let numberError = '';

    if (elementType === 'cardNumber') {
      if (!isEmpty(error)) {
        numberError = error.message;
        numberTouched = true;
      } else if (complete) numberTouched = true;
      else if (empty) numberTouched = false;
      setCardValidationError({
        ...cardValidationError,
        numberError,
        numberTouched
      });
    } else if (elementType === 'cardCvc') {
      if (!isEmpty(error)) {
        cvcError = error.message;
        cvcTouched = true;
      } else if (complete) cvcTouched = true;
      else if (empty) cvcTouched = false;
      setCardValidationError({
        ...cardValidationError,
        cvcError,
        cvcTouched
      });
    } else if (elementType === 'cardExpiry') {
      if (!isEmpty(error)) {
        dateError = error.message;
        dateTouched = true;
      } else if (complete) dateTouched = true;
      else if (empty) dateTouched = false;
      setCardValidationError({
        ...cardValidationError,
        dateError,
        dateTouched
      });
    }
  };

  const handleAddressError = (key: string, value: string) => {
    setAddressData({
      ...addressData,
      [key]: value
    });

    let errorObj = addressError;

    if (key === 'selectedCountry') {
      if (!value) {
        errorObj = {
          ...errorObj,
          countryError: 'Country cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          countryError: ''
        };
      }
    } else if (key === 'postalCode') {
      if (!value) {
        errorObj = {
          ...errorObj,
          postalCodeError: 'Postal Code cannot be Empty!'
        };
      } else {
        errorObj = {
          ...errorObj,
          postalCodeError: ''
        };
      }
    }

    setAddressError(errorObj);
  };

  const handlePurchaseBundle = () => {
    if (bundleProductId) {
      if (selectedValue === 'monthly') {
        dispatch(CreateSubscription({
          productId: bundleProductId,
          planType: bundlePlanType
        }));
      } else {
        dispatch(DirectPurchase({
          productId: bundleProductId,
          planType: bundlePlanType
        }));
      }

      setIsPurchasedPlan(true);
      setPurchasedPlan(bundlePlanType);
      setBundleProductId('');
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    const {
      cvcTouched,
      dateTouched,
      numberTouched
    } = cardValidationError;

    if (cardHolderName === '' || cvcTouched !== true || dateTouched !== true || numberTouched !== true) {
      let cardHolderNameError = '';
      let cvcError = '';
      let numberError = '';
      let dateError = '';

      if (cardHolderName === '') cardHolderNameError = 'Name is Required!';
      if (cardValidationError.cvcError === '' && cvcTouched === false) cvcError = 'Cvc is Required!';
      else cvcError = cardValidationError.cvcError || '';
      if (cardValidationError.dateError === '' && dateTouched === false) dateError = 'Date is Required!';
      else dateError = cardValidationError.dateError || '';
      if (cardValidationError.numberError === '' && numberTouched === false) numberError = 'Card Number is Required!';
      else numberError = cardValidationError.numberError || '';

      setNameError(cardHolderNameError);

      setCardValidationError({
        ...cardValidationError,
        cvcError,
        numberError,
        dateError
      });

      return;
    }

    const card = elements.getElement(CardNumberElement);
    if (card) {
      const { token } = await stripe.createToken(card);

      if (token) {
        dispatch(CreateCustomer({
          token,
          cardHolderName: cardHolderName?.trim(),
          country: addressData.selectedCountry,
          postalCode: addressData.postalCode
        }));
      }
    }
  };

  useEffect(() => {
    let countriesData = COUNTRIES?.map((country, index) => ({
      value: country.name,
      label: country.name
    }));

    countriesData = sortBy(countriesData, 'label');
    setAllCountries(countriesData);
  }, []);

  useEffect(() => {
    if (customerCreated) {
      dispatch(GetCurrentUser());
      dispatch(SetStripeState({ field: 'customerCreated', value: false }));
      // setPaymentModal?.(false);
      handlePurchaseBundle();
    }
  }, [customerCreated]);

  return (
    <div className="payment-cart-wrapper">
      {loading && <Loader />}
      <div className="cart-title">
        <Button
          role="button"
          onClick={handleBackClick}
          shape="circle"
          style={{ border: 'none', background: 'transparent' }}
        >
          <img
            src={BackIcon}
            alt="Back"
          />
        </Button>

        <h6>Add Card</h6>
      </div>
      <div className=" overlay-wrapper w-100">
        <div className="payment-form-ui">
          <div className="header-wrapper-ui">
            <h5>Payment Details</h5>
            <FaStripe />
          </div>
          <div className="strip-st-ui-wrapper">
            <div className="w-100">
              <Input
                className="custom-style"
                label="Card holder name"
                name="name"
                placeholder="Enter"
                onChange={(e: any) => handleName(e.target.value)}
              />
              <span className="error">
                {nameError}
              </span>
            </div>
            <div className="card-number-wrapper">
              <label>Credit card here</label>
              <div className="card-number-input">
                <CardNumberElement
                  options={CardNumberOptions}
                  className="card-number-stripe"
                  onChange={(e) => handleCardValidationError(e)}
                />
              </div>
              <span className="error">
                {cardValidationError.numberError}
              </span>
            </div>
            <div className=" elements-wrapper d-flex">
              <div className="card-number-expiry">
                <label>Expiry</label>
                <div className="card-expiry-wrapper">
                  <CardExpiryElement
                    className="card-number-stripe"
                    options={ExNumberOptions}
                    onChange={(e) => handleCardValidationError(e)}
                  />
                </div>
                <span className="error">
                  {cardValidationError.dateError}
                </span>
              </div>
              <div className="card-number-expiry">
                <label>CVC</label>
                <div className="card-number-stripe">
                  <CardCvcElement
                    className="card-cvc-stripe"
                    options={ExpiryOptions}
                    onChange={(e) => handleCardValidationError(e)}
                  />
                </div>
                <span className="error">
                  {cardValidationError.cvcError}
                </span>
              </div>
            </div>
            <div className="elements-wrapper d-flex">
              <div className="w-100">
                <Select
                  defaultValue="United States"
                  options={allCountries}
                  placeholder="Please select an option"
                  label="Country"
                  onChange={(e) => handleAddressError('selectedCountry', e)}
                  showSearch
                />
                <span className="error">
                  {addressError.countryError}
                </span>
              </div>
              <div className="w-100">
                <Input
                  className="custom-style"
                  label="Postal code"
                  name="name"
                  placeholder="90210"
                  onChange={(e: any) => handleAddressError('postalCode', e.target.value)}
                />
                <span className="error">
                  {addressError.postalCodeError}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <ButtonComponent
                width="197px"
                className="m-auto"
                text={t('purchase_now')}
                type="primary"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
        {
          bundlePlanType === 'Basic' ? (
            <PriceCard
              items={ITEMS}
              planText="Basic"
              planPrice={basicTypePrice?.pricePerLetter ? `${basicTypePrice?.pricePerLetter}€` : '780€'}
              cardHeading="Reach your customers as cost-effectively and personally as possible."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5"
              cardDisc2="Envelope: DIN C6 white"
              cardDisc3="Number of pages: 1"
              cardDisc4="Number of characters: unlimited"
              selected
              showButton
            />
          ) : bundlePlanType === 'Branding' ? (
            <PriceCard
              items={ITEMS1}
              isBest
              planText="Branding"
              planPrice={brandedTypePrice?.pricePerLetter ? `${brandedTypePrice?.pricePerLetter}€` : '300€'}
              cardHeading="Send letters that are even more personal and rely on premium-quality materials."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5 / A4"
              cardDisc2="Envelope: DIN C6 / Lang Premium"
              cardDisc3="Number of pages: 1-3"
              cardDisc4="Number of characters: unlimited"
              selected
              showButton
            />
          ) : bundlePlanType === 'Maxi' ? (
            <PriceCard
              items={ITEMS2}
              planText="Plus"
              planPrice={maxiTypePrice?.pricePerLetter ? `${maxiTypePrice?.pricePerLetter}€` : '500€'}
              cardHeading="The Plus packaging allows the addition of inserts, such as brochures,
               catalogs, small gifts, samples or informational materials."
              cardSubHeading="Letter General"
              cardDisc1="Letterhead: DIN A5 / A4"
              cardDisc2="Envelope: DIN C6 / Lang Premium"
              cardDisc3="Number of pages: 1-3"
              cardDisc4="Number of characters: unlimited"
              selected
              showButton
            />
          ) : null
        }
      </div>
    </div>
  );
};

export default PaymentCart;
