import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  Space,
  Progress
} from 'antd';

import Input from '../../../components/input/index';
import Button from '../../../components/button/index';
import TickIcon from '../../../assets/icons/tick-icon.svg';
import {
  ConnectionWrapper,
  StrengthWrapper
} from './style';

import { SetOtherNotifyState } from '../../../redux/slices/other';

import { AppDispatch } from '../../../redux/store';

import { AuthState } from '../../../redux/types/auth';
import { ProfileState } from '../../../redux/types/profile';

import {
  ApplyValidation,
  CheckPasswordStrength,
  DecryptCredentials,
  TestPasswordStrength
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

interface UpdateCredentialsHelperTextState {
  email: string;
  password: string;
}

interface UpdateCredentialsProps {
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  onCancel?:()=> void;
  onUpdate?:()=>void;
  helperText: UpdateCredentialsHelperTextState;
  setHelperText: (data: any) => void;
}

interface UpdateCredentialsState {
  email: string;
  password: string;
}

const UpdatePassword: React.FC<UpdateCredentialsProps> = ({
  email: emailProp,
  setEmail,
  password,
  setPassword,
  onCancel,
  onUpdate,
  helperText,
  setHelperText
}) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: { auth: AuthState }) => state.auth);
  const { currentPassword } = useSelector((state: { profile: ProfileState }) => state.profile);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const [currentDecryptedPassword, setCurrentDecryptedPassword] = useState('');

  const strengthData = [{
    title: t('at_least_8_characters'),
    check: password.length >= 8
  }, {
    title: (
      <>
        {t('have_uppercase_lowercase_letters')}
        <br />
        {t('numbers_and_special_characters')}
      </>
    ),
    check: CheckPasswordStrength(password)
  }];

  const validateField = (
    fieldName: keyof UpdateCredentialsState,
    value: string
  ): Partial<UpdateCredentialsHelperTextState> => {
    const errors: Partial<UpdateCredentialsHelperTextState> = {};

    if (value.trim() === '' && fieldName === 'email') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'email' && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_EMAIL);
    } else if (fieldName === 'password'
      && value.trim() !== ''
      && !ApplyValidation(REGEX_PATTERNS.PASSWORD_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_PASSWORD);
    } else {
      errors[fieldName] = '';
    }
    return errors;
  };

  const handleChange = (
    fieldName: keyof UpdateCredentialsState,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    if (fieldName === 'password') {
      setPassword(value);
    } else {
      setEmail(value);
    }

    setHelperText({
      ...helperText,
      [fieldName]: errors[fieldName] || ''
    });

    const strength: number = TestPasswordStrength(value);
    setPasswordStrength(strength);
  };

  const handleSubmit = () => {
    setHelperText({
      email: validateField('email', emailProp).email,
      password: validateField('password', password).password
    });

    if (helperText.email || helperText.password) {
      return;
    }

    if (DecryptCredentials(user?.email as string) === emailProp && (
      !password || DecryptCredentials(currentPassword) === password
    )) {
      dispatch(SetOtherNotifyState({
        message: t('no_changes_made'),
        type: 'info'
      }));
      return;
    }

    onUpdate?.();
  };

  useEffect(() => {
    if (currentPassword) {
      const decryptedPassword = DecryptCredentials(currentPassword);
      setCurrentDecryptedPassword(decryptedPassword);
    } else {
      setCurrentDecryptedPassword('');
    }
  }, [
    currentPassword,
    user
  ]);

  return (
    <ConnectionWrapper>
      <Input
        label="Name"
        value={user?.name}
        marginBottom="24px"
        editable={false}
      />
      <Input
        label={t('email')}
        type="email"
        value={emailProp}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('email', e.target.value)}
        marginBottom="24px"
      />
      <Input
        label={t('current_password')}
        type="password"
        marginBottom="24px"
        value={currentDecryptedPassword}
        editable={false}
      />
      <Popover
        open={popoverVisible}
        onOpenChange={(visible) => setPopoverVisible(visible)}
        overlayClassName="auth-password-popover"
        content={(
          <StrengthWrapper>
            <span className="strength-title">{t('your_password_must_contain')}</span>
            <Space direction="vertical">
              {strengthData.map((data, ind) => (
                <div
                  className={`password-check ${data.check && 'check-pass'}`}
                  key={ind}
                >
                  <img src={TickIcon} alt={t('tick')} />
                  <span>{data.title}</span>
                </div>
              ))}
              <div className="progress-wrapper">
                <div className="progress-header-wrapper">
                  <span>{t('strength')}</span>
                  <span
                    style={{
                      marginRight: 8,
                      color: (passwordStrength === 1 || passwordStrength === 2)
                        ? '#D40001'
                        : (passwordStrength === 3 || passwordStrength === 4)
                          ? '#F17400'
                          : passwordStrength === 5
                            ? '#0FB600'
                            : '#D40001'
                    }}
                  >
                    {(passwordStrength === 1 || passwordStrength === 2)
                      ? t('weak')
                      : (passwordStrength === 3 || passwordStrength === 4)
                        ? t('normal')
                        : passwordStrength === 5 && t('fantastic')}
                  </span>
                </div>
                <Progress
                  percent={(passwordStrength === 1 || passwordStrength === 2)
                    ? 20
                    : (passwordStrength === 3 || passwordStrength === 4)
                      ? 60
                      : passwordStrength === 5
                        ? 100
                        : 0}
                  status={(passwordStrength === 1 || passwordStrength === 2)
                    ? 'exception'
                    : (passwordStrength === 3 || passwordStrength === 4)
                      ? 'active'
                      : passwordStrength === 5
                        ? 'success'
                        : 'exception'}
                  showInfo={false}
                  strokeColor={(passwordStrength === 1 || passwordStrength === 2)
                    ? '#D40001'
                    : (passwordStrength === 3 || passwordStrength === 4)
                      ? '#F17400'
                      : passwordStrength === 5
                        ? '#0FB600'
                        : '#D40001'}
                  trailColor="#E7E7E7"
                />
                <span className="progress-message">
                  {(passwordStrength === 1 || passwordStrength === 2)
                    ? t('this_password_is_too_short')
                    : passwordStrength > 2 ? t('avoid_sequence') : ''}
                </span>
              </div>
            </Space>
          </StrengthWrapper>
        )}
        trigger="focus"
        placement="bottomRight"
      >
        <div>
          <Input
            type="password"
            label={t('new_password')}
            className="form-input"
            marginBottom="24px"
            editable
            placeholder="Enter New Password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange('password', e.target.value);
            }}
            helperText={helperText.password}
            value={password}
          />
        </div>
      </Popover>
      <div className="button-box">
        <Button
          text="Cancel"
          width="91px"
          type="default"
          onClick={() => {
            onCancel?.();
            setHelperText({
              email: '',
              password: ''
            });
            setPassword('');
          }}
        />
        <Button
          text="Update"
          width="91px"
          type="primary"
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </ConnectionWrapper>
  );
};

export default UpdatePassword;
