import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../../config/language-conversion';

import AuthFormWrapper from './auth-form-wrapper';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Loader from '../../../components/loader';

import { Signin } from '../../../redux/slices/auth';

import { AppDispatch } from '../../../redux/store';

import { AuthState } from '../../../redux/types/auth';

import {
  SignInDataState,
  SignInHelperTextState
} from '../../../utils/types/pages/auth';

import {
  ApplyValidation,
  EncryptCredentials
} from '../../../utils/helpers';

import {
  AUTH_FORMS_ERROR_MESSAGES,
  REGEX_PATTERNS
} from '../../../constants';

const SignIn: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const { t } = useTranslation();

  const [signInData, setSignInData] = useState<SignInDataState>({
    email: '',
    password: '',
    rememberMe: false
  });

  const [signInHelperText, setSignInHelperText] = useState<SignInHelperTextState>({
    email: '',
    password: ''
  });

  const { loading } = useSelector((state: { auth: AuthState }) => state.auth);

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'en' ? 'de' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const validateField = (
    fieldName: keyof SignInDataState,
    value: string
  ): Partial<SignInHelperTextState> => {
    const errors: Partial<SignInHelperTextState> = {};

    if (fieldName !== 'rememberMe' && value.trim() === '') {
      errors[fieldName] = AUTH_FORMS_ERROR_MESSAGES.REQUIRED(fieldName);
    } else if (fieldName === 'email' && !ApplyValidation(REGEX_PATTERNS.EMAIL_VALIDATION, value)) {
      errors[fieldName] = t(AUTH_FORMS_ERROR_MESSAGES.INVALID_EMAIL);
    } else if (fieldName !== 'rememberMe') {
      errors[fieldName] = '';
    }

    return errors;
  };

  const handleInputChange = (
    fieldName: keyof SignInDataState,
    value: string
  ) => {
    const errors = validateField(fieldName, value);

    setSignInData({
      ...signInData,
      [fieldName]: value
    });

    setSignInHelperText({
      ...signInHelperText,
      ...errors
    });
  };

  const handleSignIn = () => {
    const emailError = validateField('email', signInData.email).email;
    const passwordError = validateField('password', signInData.password).password;

    if (emailError || passwordError) {
      setSignInHelperText({
        email: emailError || '',
        password: passwordError || ''
      });
      return;
    }

    const encryptedData = {
      encryptedEmail: EncryptCredentials(signInData.email),
      encryptedPassword: EncryptCredentials(signInData.password)
    };

    const data = {
      email: encryptedData.encryptedEmail,
      password: encryptedData.encryptedPassword,
      rememberMe: signInData.rememberMe
    };

    dispatch(Signin(data as SignInDataState));
  };

  return (
    <>
      {loading && <Loader />}
      <AuthFormWrapper
        heading={t('sign_in')}
        subtext={t('sign_in_phrase')}
      >
        <Input
          label={t('email')}
          className="form-input"
          type="email"
          helperText={signInHelperText.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('email', e.target.value);
          }}
        />

        <Input
          label={t('password')}
          className="form-input"
          type="password"
          helperText={signInHelperText.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleInputChange('password', e.target.value);
          }}
        />

        <div className="form-footer">
          <Checkbox
            onChange={(e) => {
              setSignInData({
                ...signInData,
                rememberMe: e.target.checked
              });
            }}
          >
            {t('keep_me_logged_in')}
          </Checkbox>
          <Link to="/forgot-password" className="link-text">{t('forgot_password')}</Link>
        </div>
        <Button
          text={t('sign_in')}
          type="primary"
          width="100%"
          borderRadius="12px"
          disabled={false}
          className="custom-button"
          onClick={handleSignIn}
        />
        <div className="ba-text-align-center">
          <p>
            {t(('dont_have_an_account'))}
            <Link to="/sign-up">
              {t('sign_up')}
            </Link>
          </p>
          {/* <button type="button" onClick={toggleLanguage}>
            Toggle Language
          </button> */}
        </div>
      </AuthFormWrapper>
    </>
  );
};

export default SignIn;
