import { startCase } from 'lodash';

const ADD_ADDRESS_ERROR_MESSAGE = {
  INVALID_ADDRESS: 'invalid_address'
};

const ADD_CAMPAIGN_INITIAL_STATE = {
  name: '',
  templateId: '',
  fileKey: ''
};

const ADVERTISEMENT_FORMAT_VALUES = {
  DIN_A4: 'DIN A4',
  DIN_A5: 'DIN A5',
  Maxi: 'Maxi'
};

const CHOOSE_PLAN_VALUES = {
  BASIC: 'Basic',
  BRANDING: 'Branding',
  BIG: 'Big'
};

const PLAN_TYPES_DB: { [key: string]: string } = {
  Basic: 'Basic',
  Branding: 'Branding',
  Big: 'Maxi'
};

const CHOOSE_PLAN_VALUES_HEADERS = {
  BASIC: 'Basic',
  BRANDING: 'Branding',
  BIG: 'Maxi'
};

const ENVELOPE_COLOR_VALUES = {
  WHITE: 'White',
  MARBLE: 'Marble'
};

const ENVELOPE_FORMAT_VALUES = {
  DIN_C6: 'DIN C6',
  DIN_LANG: 'DIN Lang',
  DIN_A4_MAX: 'DIN A4 MAX',
  DIN_A5_MAX: 'DIN A5 MAX'
};

const LETTER_STYLE_VALUES = {
  BASIC: 'Basic',
  BRANDED: 'Branded'
};

const LETTER_TYPE_VALUES = {
  PRINTED: 'Printed',
  HANDWRITTEN: 'Handwritten'
};

const ADD_TEMPLATE_INITIAL_STATE = {
  name: '',
  advertisementFormat: '',
  letterStyle: '',
  letterType: '',
  envelopeColor: '',
  envelopeFormat: ''
};

const ADVERTISEMENT_FORMATS = [
  // { value: 'DIN A4', label: 'DIN A4' },
  { value: 'DIN A5', label: 'DIN A5' },
  // { value: 'Maxi', label: 'Maxi' }
  { value: 'Maxi', label: 'Gummy Bear + Letter' }
];

const AUTH_FORMS_ERROR_MESSAGES = {
  REQUIRED: (field: string) => `${startCase(field)} is required!`,
  INVALID_EMAIL: 'invalid_email_address',
  INVALID_PASSWORD: 'password_does_not_fulfill_requirements'
};

const BUCKET_NAMES: { [key: string]: string } = {
  logo: process.env.REACT_APP_LOGO_BUCKET || '',
  template_sheets: process.env.REACT_APP_TEMPLATE_SHEETS_BUCKET || '',
  campaign_files: process.env.REACT_APP_CAMPAIGN_FILES_BUCKET || '',
  files: process.env.REACT_APP_FILES_BUCKET || ''
};

const BUCKET_NAME_VALUES = {
  logo: 'logo',
  template_sheets: 'template_sheets',
  campaign_files: 'campaign_files'
};

const FILES_EXTENSION_LIST = ['csv', 'tsv', 'xlsx', 'xls'];

const CAMPAIGN_STATUS = {
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  PAUSED: 'Paused',
  STOPPED: 'Stopped',
  PENDING: 'Pending',
  CAN_BE_STARTED: 'Can be Started',
  DRAFT: 'Draft',
  BLOCKED: 'Blocked'
};

const CAMPAIGN_STATUS_FILTER = [
  { value: 'Active', label: 'Active' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Paused', label: 'Paused' },
  { value: 'Stopped', label: 'Stopped' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Can be Started', label: 'Can be Started' },
  { value: 'Draft', label: 'Draft' }
];

const COUNTRIES = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

const FILE_REPORT_STATUS = [
  { value: 'Printed', label: 'Printed' },
  { value: 'Not Printed', label: 'Not Printed' }
];

const FILE_ADVERTISEMENT_FORMAT = [
  { value: 'DIN A5', label: 'DIN A5' },
  { value: 'DIN A4', label: 'DIN A4' },
  { value: 'Maxi', label: 'Maxi' }
];

const FILE_ENVELOPE_FORMAT = [
  { value: 'DIN C6', label: 'DIN C6' },
  { value: 'DIN Lang', label: 'DIN Lang' },
  { value: 'DIN A4 MAX', label: 'DIN A4 MAX' },
  { value: 'DIN A5 MAX', label: 'DIN A5 MAX' }
];

const FILE_ENVELOPE_COLOR = [
  { value: 'White', label: 'White' },
  { value: 'Marble', label: 'Marble' }
];

const CAMPAIGN_TYPES = {
  ONE_TIME: 'One Time',
  AUTO: 'Auto'
};

const CAMPAIGN_TYPES_FILTER = [
  { value: 'One Time', label: 'One Time' },
  { value: 'Auto', label: 'Auto' }
];

const DOC_EXTENSION_LIST = ['docx', 'pdf'];

const EMAIL_ASTERISK = '******';

const EMAIL_FORMAT_WITH_PREFIX = /(.{3})(.*)(@.*)/;

const EMPLOYEE_STATUS = [
  { value: 'Active', label: 'Active' },
  { value: 'Archived', label: 'Archived' },
  { value: 'Invited', label: 'Invited' }
];

const ENVELOPE_COLORS = [
  { value: 'White', label: 'White' },
  { value: 'Marble', label: 'Marble' }
];

const ENVELOPE_FORMATS = [
  { value: 'DIN C6', label: 'DIN C6' },
  { value: 'DIN Lang', label: 'DIN Lang' },
  { value: 'DIN A4 MAX', label: 'DIN A4 MAX' },
  { value: 'DIN A5 MAX', label: 'DIN A5 MAX' }
];

// eslint-disable-next-line max-len
const EXTENSIONS_LIST = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'ico', 'psd', 'ai', 'eps', 'docm', 'docx', 'dot', 'csv', 'tsv', 'xlx', 'xlsm', 'xlsb', 'xltx', 'pdf', 'xltm', 'xls', 'xlt', 'xml', 'xlam', 'xla', 'xlw', 'xlr', 'txt', 'slk', 'dif', 'xps', 'rtf', 'dotx', 'dotm', 'docb', 'doc', 'xlsx', 'json'];

const LOGO_DEFAULT_SIZE = {
  width: 142,
  height: 33
};

const LOGO_EXTENSION_LIST = ['jpeg', 'jpg', 'png'];

const TEMPLATE_FORMATS = [
  { value: '65d71af0c35da3057d8755bf', label: 'Example 1' },
  { value: '65d71ae7c35da3057d8755bb', label: 'Example 2' },
  { value: '65d718bdc35da3057d8755a1', label: 'Example 3' }
];

const HAND_WRITTEN_INITIAL_OBJECT = {
  templateId: '',
  templateName: '',
  sheetType: LETTER_TYPE_VALUES.HANDWRITTEN,
  font: 'Briefadlerniklas-Dua',
  message: '',
  logoUrl: '',
  qrCode: '',
  fileKey: '',
  logoSize: LOGO_DEFAULT_SIZE,
  logoImage: '',
  variables: []
};

const LANGUAGES_OPTIONS = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'German' }
];

const LETTER_STYLES = [
  { value: 'Basic', label: 'Basic' },
  { value: 'Branded', label: 'Branded' }
];

const LETTER_TYPES = [
  { value: 'Handwritten', label: 'Handwritten' },
  { value: 'Printed', label: 'Printed' },
  { value: 'Handwritten & Printed', label: 'Handwritten & Printed' }
];

const MAXI_INSERTS_ERROR_MESSAGES = {
  INVALID_LENGTH: 'invalid_length',
  INVALID_WIDTH: 'invalid_width',
  INVALID_HEIGHT: 'invalid_height',
  INVALID_WEIGHT: 'invalid_weight',
  INVALID_DIMENSIONS: 'invalid_dimensions',
  INVALID_RECEIVED_QUANTITY: 'invalid_received_quantity'
};

const NICKNAMES_SAMPLE_SHEET_COLUMNS = ['Marketplace Id', 'Seller SKU', 'Nick Name'];

const PLAN_TYPES = {
  BASIC: 'Basic',
  BRANDING: 'Branding',
  MAXI: 'Maxi'
};

const PAGE_SIZES = [25, 50, 75, 100];

const PRINTED_INITIAL_OBJECT = {
  templateId: '',
  templateName: '',
  sheetType: LETTER_TYPE_VALUES.PRINTED,
  font: '',
  message: '',
  logoUrl: '',
  logoImage: '',
  qrCode: '',
  logoSize: LOGO_DEFAULT_SIZE,
  fileKey: '',
  variables: []
};

const REGION_MAPPING: { [key: string]: string } = {
  na: 'North America',
  eu: 'Europe',
  fe: 'Far East'
};

const REGEX_PATTERNS = {
  DIGIT_REGEX: /\d/, // At least one digit
  EMAIL_VALIDATION: /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
  EXTRACT_VARIABLES: /\{\{([^{}]+)\}\}/g,
  LENGTH_REGEX: /^.{8,}$/, // Minimum 8 characters
  LOWERCASE_REGEX: /[a-z]/, // At least one lowercase letter
  PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
  SPECIAL_CHAR_REGEX: /[!@#$ %^&* ()_.,.? ":{}|<>]/, // At least one special character
  UPPERCASE_REGEX: /[A-Z]/, // At least one uppercase letter
  // Only +ve integers between 1 and 35
  MAXI_LENGTH_REGEX: /^(?:(?:[1-9]|1\d|2[0-9]|3[0-4])(?:\.\d)?|35(?:\.0|\.1|\.2|\.3)?)$/,
  // Only positive integers between 1 and 25
  MAXI_WIDTH_REGEX: /^(?:[1-9](?:\.\d)?|1\d(?:\.\d)?|2[0-4](?:\.\d)?|25(?:\.0)?)$/,
  MAXI_HEIGHT_REGEX: /^(?:[1-4](\.\d+)?|5(\.0)?)$/, // Only positive integers between 1 and 5
  MAXI_WEIGHT_REGEX: /^([1-9]\d{0,2}(\.\d)?|1000(\.0)?)$/, // Only positive integers between 1 and 1000
  MAXI_RECEIVED_QUANTITY_REGEX: /^0*[1-9]\d*$/ // Only positive whole numbers
};

const SAMPLE_FILE_NAMES = {
  CAMPAIGN_SAMPLE_FILE_NAME: 'campaign_sample_file',
  NICKNAMES_SAMPLE_FILE_NAME: 'nicknames_sample_file'
};

const SHEET_COUNTS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 }
];

const SHEETS_MANDATORY_COLUMNS = ['Name Receiver', 'Street Receiver', 'Address Code Receiver', 'City Receiver'];

const TEMPLATE_FONTS = [
  { label: 'Classic', value: 'Briefadlerniklas-Regular' },
  { label: 'Dua', value: 'Briefadlerniklas-Dua' }
];

const TEMPLATE_SHEETS_INITIAL_STATE = [HAND_WRITTEN_INITIAL_OBJECT];

const USER_ROLES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  EMPLOYEE: 'employee'
};

const USER_STATUS = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  INVITED: 'Invited',
  BLOCKED: 'Blocked'
};

const WISH_NOT_LISTED_ERROR_MESSAGE = {
  LENGTH_RANGE: 'length_range'
};

export {
  ADD_ADDRESS_ERROR_MESSAGE,
  ADD_CAMPAIGN_INITIAL_STATE,
  ADD_TEMPLATE_INITIAL_STATE,
  ADVERTISEMENT_FORMATS,
  ADVERTISEMENT_FORMAT_VALUES,
  CHOOSE_PLAN_VALUES,
  CHOOSE_PLAN_VALUES_HEADERS,
  AUTH_FORMS_ERROR_MESSAGES,
  BUCKET_NAMES,
  BUCKET_NAME_VALUES,
  FILES_EXTENSION_LIST,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_FILTER,
  CAMPAIGN_TYPES,
  CAMPAIGN_TYPES_FILTER,
  COUNTRIES,
  DOC_EXTENSION_LIST,
  EMAIL_ASTERISK,
  EMAIL_FORMAT_WITH_PREFIX,
  EMPLOYEE_STATUS,
  ENVELOPE_COLORS,
  ENVELOPE_COLOR_VALUES,
  ENVELOPE_FORMATS,
  ENVELOPE_FORMAT_VALUES,
  EXTENSIONS_LIST,
  FILE_ADVERTISEMENT_FORMAT,
  FILE_ENVELOPE_COLOR,
  FILE_ENVELOPE_FORMAT,
  FILE_REPORT_STATUS,
  HAND_WRITTEN_INITIAL_OBJECT,
  LANGUAGES_OPTIONS,
  LETTER_STYLES,
  LETTER_STYLE_VALUES,
  LETTER_TYPES,
  LETTER_TYPE_VALUES,
  LOGO_DEFAULT_SIZE,
  LOGO_EXTENSION_LIST,
  MAXI_INSERTS_ERROR_MESSAGES,
  NICKNAMES_SAMPLE_SHEET_COLUMNS,
  PAGE_SIZES,
  PLAN_TYPES,
  PLAN_TYPES_DB,
  PRINTED_INITIAL_OBJECT,
  REGION_MAPPING,
  REGEX_PATTERNS,
  SAMPLE_FILE_NAMES,
  SHEET_COUNTS,
  SHEETS_MANDATORY_COLUMNS,
  TEMPLATE_FONTS,
  TEMPLATE_FORMATS,
  TEMPLATE_SHEETS_INITIAL_STATE,
  USER_ROLES,
  USER_STATUS,
  WISH_NOT_LISTED_ERROR_MESSAGE
};
