import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/loader';

import DashboardWrapper from './style';

import {
  ScanQRCode,
  SetDashboardState
} from '../../redux/slices/dashboard';

import { AppDispatch } from '../../redux/store';

import { DashboardState } from '../../redux/types/dashboard';

const Dashboard = () => {
  const dispatch: AppDispatch = useDispatch();

  const {
    loading,
    qrCodeScanned
  } = useSelector((state: { dashboard: DashboardState }) => state.dashboard);

  const params = new URLSearchParams(window.location.search);

  const originalLink = params.get('originalLink');
  const templateId = params.get('templateId');
  const campaignId = params.get('campaignId');
  const amazonOrderId = params.get('amazonOrderId');
  const parentAsin = params.get('parentAsin');
  const childAsin = params.get('childAsin');

  useEffect(() => {
    dispatch(ScanQRCode({
      campaignId,
      templateId,
      amazonOrderId,
      parentAsin,
      childAsin,
      originalLink
    }));
  }, []);

  useEffect(() => {
    if (qrCodeScanned) {
      SetDashboardState({
        field: 'qrCodeScanned',
        value: false
      });

      if (originalLink) window.open(originalLink, '_self');
    }
  }, [qrCodeScanned]);

  return (
    <>
      {loading && <Loader />}
      <DashboardWrapper>
        <h2 className="heading">Scanning QR Code ...</h2>
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
