import React, {
  ChangeEvent,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  isEmpty,
  startCase
} from 'lodash';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import Input from '../../input';
import Loader from '../../loader';
import ModalUpload from '../../modal';

import { EditNickName } from '../../../redux/slices/product';
import { ProductState } from '../../../redux/types/product';

import { AppDispatch } from '../../../redux/store';

interface HelperText {
  [key: string]: string | undefined;
}

interface ModalProps {
  onCancel: () => void;
  open?: boolean;
  title?: string;
}

const Modal: React.FC<ModalProps> = ({
  onCancel,
  open,
  title
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedProductIds, loading } = useSelector((state: { product: ProductState }) => state.product);

  const [newNickName, setNewNickName] = useState<string>('');
  const [addCampaignHelperText, setAddCampaignHelperText] = useState<HelperText>({});

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e?.target;
    setNewNickName(value);

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'nickName') errors[name] = `${startCase(name)} is required!`;
    else if (value.trim().length < 2) errors[name] = `${startCase(name)} should have atleast 2 characters!`;
    else if (value.trim().length > 25) errors[name] = `${startCase(name)} cannot exceed 25 characters!`;
    else if (!isEmpty(value) && name === 'nickName') {
      errors[name] = '';
    }
    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const handleSubmit = () => {
    const errors: Partial<Record<string, string>> = {};
    if (isEmpty(newNickName)) errors.nickName = `${startCase('nickName')} is required!`;
    else if (!isEmpty(newNickName)) {
      errors.nickName = '';

      dispatch(EditNickName({
        productIds: selectedProductIds.map((p: { _id: string }) => p._id),
        nickName: newNickName
      }));

      onCancel();
      setNewNickName('');
      setAddCampaignHelperText({});

      return;
    }

    setAddCampaignHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const resetValues = () => {
    onCancel();
    setNewNickName('');
    setAddCampaignHelperText({});
  };

  return (
    <ModalUpload
      footer
      cancelText={t('cancel')}
      onCancelClick={resetValues}
      onSubmitClick={handleSubmit}
      saveText={t('submit')}
      width={513}
      open={open}
      title={title}
    >
      {(loading) && <Loader />}
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            name="nickName"
            label={t('nick_name')}
            placeholder={t('enter')}
            className="form-input add-employee-input"
            value={newNickName}
            onChange={(e: any) => handleChange(e)}
            helperText={addCampaignHelperText.nickName}
            marginBottom="24px"
          />
        </Col>
      </Row>
    </ModalUpload>
  );
};

export default Modal;
