import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';
import { isEmpty } from 'lodash';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';

import Input from '../../../components/input';
import Modal from '../../../components/modal';

import {
  CloneTemplate,
  SetTemplateState
} from '../../../redux/slices/template';

import { AppDispatch } from '../../../redux/store';

import { TemplateState } from '../../../redux/types/template';

interface CloneModalProps {
  onCancel: () => void;
  open?: boolean;
  title?: string;
  cloneTemplateId: string;
}

interface HelperText {
  [key: string]: string | undefined;
}

const CloneModal: React.FC<CloneModalProps> = ({
  onCancel,
  open,
  title,
  cloneTemplateId
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const {
    cloneTemplateLoading,
    isTemplateCloned
  } = useSelector((state: { template: TemplateState }) => state.template);

  const [templateName, setTemplateName] = useState<string>('');
  const [templateNameHelperText, setTemplateNameHelperText] = useState<HelperText>({});

  const handleTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTemplateName(value);

    const errors: Partial<Record<string, string>> = {};

    if (isEmpty(value) && name === 'name') errors[name] = t('name_is_required');
    else if (!isEmpty(value) && name === 'name') {
      errors[name] = '';
    }
    setTemplateNameHelperText((prevHelperText: HelperText) => ({
      ...prevHelperText,
      ...errors
    }));
  };

  const cloneTemplate = () => {
    dispatch(CloneTemplate({
      templateId: cloneTemplateId,
      name: templateName
    }));
  };

  const handleSubmitClick = () => {
    if (isEmpty(templateName)) {
      setTemplateNameHelperText({ name: t('name_is_required') });
    } else {
      cloneTemplate();
    }
  };

  useEffect(() => {
    if (isTemplateCloned) {
      onCancel();
      setTemplateName('');
      setTemplateNameHelperText({});
      dispatch(SetTemplateState({
        field: 'templates',
        value: []
      }));
    }
  }, [isTemplateCloned]);

  return (
    <Modal
      footer
      cancelText={t('cancel')}
      onCancelClick={() => {
        onCancel();
        setTemplateName('');
        setTemplateNameHelperText({});
      }}
      onSubmitClick={handleSubmitClick}
      saveText={t('clone_button')}
      width={361}
      open={open}
      title={title}
      loading={cloneTemplateLoading}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            name="name"
            label={t('template_name')}
            marginBottom="16px"
            placeholder={t('enter')}
            className="form-input"
            value={templateName}
            onChange={handleTemplateNameChange}
            helperText={templateNameHelperText?.name}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CloneModal;
