/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Input,
  Checkbox,
  Button,
  ConfigProvider
} from 'antd';

import { SubmitFeedback } from '../../redux/slices/user';

import { UserState } from '../../redux/types/user';

import { AppDispatch } from '../../redux/store';

import { EncryptCredentials } from '../../utils/helpers';

const { TextArea } = Input;

const ContactForm: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { notifyMessage, success } = useSelector((state: { user: UserState }) => state.user);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telephoneNumber: '',
    news: ''
  });

  const onFinish = () => {
    const {
      name = '',
      email = '',
      telephoneNumber = '',
      news = ''
    } = formData || {};

    if (!name || !email || !telephoneNumber || !news) return '';

    const encryptedEmail = EncryptCredentials(email);
    dispatch(SubmitFeedback({
      name,
      email: encryptedEmail,
      telephoneNumber,
      news
    }));
  };

  const handleChangeFormData = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  useEffect(() => {
    if (success && notifyMessage) {
      form.resetFields();
      setFormData({
        name: '',
        email: '',
        telephoneNumber: '',
        news: ''
      });
    }
  }, [success, notifyMessage]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#D4B982'
        }
      }}
    >
      <div className="max-w-3xl mx-auto p-6">
        <div className="mb-8">
          <h3 style={{
            color: '#D4B982'
          }}
          >
            {t('still_questions')}
          </h3>
          <h1 className="text-4xl font-bold mb-4">
            {t('feel_free')}
          </h1>
          <p className="text-gray-600 text-lg">
            {t('please_send_message')}
          </p>
        </div>

        <Form form={form} layout="vertical" onFinish={onFinish} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Form.Item name="name" rules={[{ required: true, message: t('enter_name') }]}>
              <Input
                size="large"
                placeholder="Name"
                className="rounded-md"
                value={formData?.name || ''}
                onChange={(e) => handleChangeFormData('name', e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('enter_email') },
                { type: 'email', message: t('enter_valid_email') }
              ]}
            >
              <Input
                size="large"
                placeholder={t('email')}
                className="rounded-md"
                value={formData?.email || ''}
                onChange={(e) => handleChangeFormData('email', e.target.value)}
              />
            </Form.Item>
          </div>

          <Form.Item name="phone" rules={[{ required: true, message: t('enter_number') }]}>
            <Input
              size="large"
              placeholder={t('telephone_number')}
              className="rounded-md"
              value={formData?.telephoneNumber || ''}
              onChange={(e) => handleChangeFormData('telephoneNumber', e.target.value)}
            />
          </Form.Item>

          <Form.Item name="message" rules={[{ required: true, message: t('enter_your_message') }]}>
            <TextArea
              rows={6}
              placeholder={t('news')}
              className="rounded-md"
              value={formData?.news || ''}
              onChange={(e) => handleChangeFormData('news', e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="acceptTerms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) => (value
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('accept_privacy_policy'))))
              }
            ]}
          >
            <Checkbox>
              {t('hereby_accept')}
              {' '}
              <a href="#" className="text-black underline">
                {t('privacy_policy')}
              </a>
              {' '}
              {t('from_brief_adler')}
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-40 h-12 bg-[#D4B982] hover:bg-[#c5aa73] text-white font-semibold"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </ConfigProvider>
  );
};

export default ContactForm;
